import { useSetAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { ActiveStatusType } from 'API';

import {
  currentUserAccessDetailsAtom,
  currentUserDetailsAtom,
  currentUserOrganizationAtom,
  groupsByOrganizationAtom,
  isNoRolesUserAtom,
} from 'atoms/modals';

import {
  CUSTOMER,
  DISTRIBUTOR,
  LEAD_MANAGEMENT_PORTAL,
  MANAGEMENT,
  PARTNER,
  PRESALES,
  QA_TEAM,
  SALES_DIRECTOR,
  SALES_MANAGEMENT,
  SALES_TEAM,
  SUPPORT_ENGINEER,
  SUPPORT_LEAD,
} from 'consts/roles';

import getLeadManagementUserRole from 'helpers/utils/leadManagement/getLeadManagementUserRole';

const useGetCurrentUserAccessDetails = () => {
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);
  const currentUserOrganization = useAtomValue(currentUserOrganizationAtom);
  const groupsByOrganization = useAtomValue(groupsByOrganizationAtom);
  const setCurrentUserAccessDetails = useSetAtom(currentUserAccessDetailsAtom);
  const setIsNoRolesUser = useSetAtom(isNoRolesUserAtom);

  useEffect(() => {
    if (currentUserDetails) {
      const userGroups =
        currentUserDetails.groups?.items.filter(
          (group) => group?.groups.status === ActiveStatusType.ACTIVE,
        ) ?? [];

      const allApplicationRoles = [
        ...userGroups.flatMap(
          (userGroup) => userGroup?.groups.applicationRoles?.items ?? [],
        ),
        ...groupsByOrganization.flatMap(
          (userGroup) => userGroup.applicationRoles?.items ?? [],
        ),
      ];

      const productDocsAccessMap: Record<string, string[] | null | undefined> =
        {};

      const locationsAccessMap: Record<string, string[] | null | undefined> =
        {};

      // eslint-disable-next-line no-restricted-syntax
      for (const applicationRole of allApplicationRoles) {
        const productDocs = applicationRole?.productDocs ?? [];

        const roleId = applicationRole?.roleId;

        if (roleId) {
          // eslint-disable-next-line no-restricted-syntax
          for (const productDoc of productDocs) {
            const access = productDoc
              ? productDocsAccessMap[productDoc] ?? []
              : [];

            if (productDoc) {
              productDocsAccessMap[productDoc] = [...access, roleId];
            }
          }
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const applicationRole of allApplicationRoles) {
        const locations = applicationRole?.locations ?? [];

        const roleId = applicationRole?.roleId;

        if (roleId) {
          // eslint-disable-next-line no-restricted-syntax
          for (const location of locations) {
            const access = location ? locationsAccessMap[location] ?? [] : [];

            if (location) {
              locationsAccessMap[location] = [...access, roleId];
            }
          }
        }
      }

      const hasSomeDocumentPortalAccess = !!allApplicationRoles.some(
        (x) => x?.applicationId === 'DOCUMENTATION_PORTAL',
      );

      const hasSomeSupportPortalAccess = !!allApplicationRoles.some(
        (x) => x?.applicationId === 'SUPPORT_PORTAL',
      );

      const supportLeadProducts: string[] = [];

      let isSupportEngineer: boolean = false;

      if (hasSomeSupportPortalAccess) {
        Object.keys(productDocsAccessMap).forEach((key) => {
          const access = productDocsAccessMap[key] ?? [];

          if (access.includes('Support lead')) {
            supportLeadProducts.push(key);
          }
        });
      }

      if (hasSomeSupportPortalAccess) {
        Object.keys(productDocsAccessMap).forEach((key) => {
          const access = productDocsAccessMap[key] ?? [];

          if (access.includes('Support Engineer')) {
            isSupportEngineer = true;
          }
        });
      }

      const productDocsPermissions = new Set(
        Object.keys(productDocsAccessMap).flatMap(
          (k) => productDocsAccessMap[k],
        ),
      );

      const hasSomeApproverAccess = productDocsPermissions.has('approver');

      const hasSomeCreatorAccess = productDocsPermissions.has('creator');

      const hasSomeReaderAccess = productDocsPermissions.has('reader');

      const hasSomeDistributorAccess = productDocsPermissions.has(DISTRIBUTOR);

      const hasSomePartnerAccess = productDocsPermissions.has(PARTNER);

      const hasSomeCustomerAccess = productDocsPermissions.has(CUSTOMER);

      const hasSomePreSalesAccess = productDocsPermissions.has(PRESALES);

      const hasSomeManagementAccess = productDocsPermissions.has(MANAGEMENT);

      const hasSomeSupportLeadAccess = productDocsPermissions.has(SUPPORT_LEAD);

      const hasSomeSupportEngineerAccess =
        productDocsPermissions.has(SUPPORT_ENGINEER);

      const hasSomeQAAccess = productDocsPermissions.has(QA_TEAM);

      const hasOnlyDistributorAccess =
        !hasSomePartnerAccess &&
        !hasSomeCustomerAccess &&
        !hasSomePreSalesAccess &&
        !hasSomeManagementAccess &&
        !hasSomeSupportEngineerAccess &&
        !hasSomeSupportLeadAccess &&
        !hasSomeQAAccess &&
        hasSomeDistributorAccess;

      const hasOnlyPartnerAccess =
        !hasSomeDistributorAccess &&
        !hasSomeCustomerAccess &&
        !hasSomePreSalesAccess &&
        !hasSomeManagementAccess &&
        !hasSomeSupportEngineerAccess &&
        !hasSomeSupportLeadAccess &&
        !hasSomeQAAccess &&
        hasSomePartnerAccess;

      const hasOnlyCustomerAccess =
        !hasSomeDistributorAccess &&
        !hasSomePartnerAccess &&
        !hasSomePreSalesAccess &&
        !hasSomeManagementAccess &&
        !hasSomeSupportEngineerAccess &&
        !hasSomeSupportLeadAccess &&
        !hasSomeQAAccess &&
        hasSomeCustomerAccess;

      const leadManagementSalesTeamLocations: string[] = [];
      Object.keys(locationsAccessMap).forEach((key) => {
        const access = locationsAccessMap[key] ?? [];

        if (access.includes(SALES_TEAM)) {
          leadManagementSalesTeamLocations.push(key);
        }
      });

      const leadManagementSalesDirectorLocations: string[] = [];
      Object.keys(locationsAccessMap).forEach((key) => {
        const access = locationsAccessMap[key] ?? [];

        if (access.includes(SALES_DIRECTOR)) {
          leadManagementSalesDirectorLocations.push(key);
        }
      });

      const leadManagementSalesManagementLocations: string[] = [];
      Object.keys(locationsAccessMap).forEach((key) => {
        const access = locationsAccessMap[key] ?? [];

        if (access.includes(SALES_MANAGEMENT)) {
          leadManagementSalesManagementLocations.push(key);
        }
      });

      const locationsPermissions = new Set(
        Object.keys(locationsAccessMap).flatMap((k) => locationsAccessMap[k]),
      );

      const hasSomeLeadManagementPartnerAccess =
        locationsPermissions.has(PARTNER);

      const hasSomeLeadManagementDistributorAccess =
        locationsPermissions.has(DISTRIBUTOR);

      const hasSomeLeadManagementSalesTeamAccess =
        locationsPermissions.has(SALES_TEAM);

      const hasSomeLeadManagementSalesDirectorAccess =
        locationsPermissions.has(SALES_DIRECTOR);

      const hasSomeLeadManagementSalesManagementAccess =
        locationsPermissions.has(SALES_MANAGEMENT);

      const leadManagementUserRole = getLeadManagementUserRole({
        isSalesTeam: hasSomeLeadManagementSalesTeamAccess,
        isSalesDirector: hasSomeLeadManagementSalesDirectorAccess,
        isSalesManagement: hasSomeLeadManagementSalesManagementAccess,
        currentUserOrgId: currentUserOrganization,
      });

      const hasSomeLeadManagementPortalAccess = leadManagementUserRole
        ? [
            PARTNER,
            DISTRIBUTOR,
            SALES_DIRECTOR,
            MANAGEMENT,
            SALES_TEAM,
          ].includes(leadManagementUserRole)
        : false;

      const hasOnlyLeadManagementPartnerAccess =
        hasSomeLeadManagementPortalAccess &&
        hasSomeLeadManagementPartnerAccess &&
        !hasSomeLeadManagementDistributorAccess &&
        !hasSomeLeadManagementSalesTeamAccess &&
        !hasSomeLeadManagementSalesDirectorAccess &&
        !hasSomeLeadManagementSalesManagementAccess;

      const hasOnlyLeadManagementDistributorAccess =
        hasSomeLeadManagementPortalAccess &&
        hasSomeLeadManagementDistributorAccess &&
        !hasSomeLeadManagementPartnerAccess &&
        !hasSomeLeadManagementSalesTeamAccess &&
        !hasSomeLeadManagementSalesDirectorAccess &&
        !hasSomeLeadManagementSalesManagementAccess;

      const hasOnlyLeadManagementSalesTeamAccess =
        hasSomeLeadManagementPortalAccess &&
        hasSomeLeadManagementSalesTeamAccess &&
        !hasSomeLeadManagementPartnerAccess &&
        !hasSomeLeadManagementDistributorAccess &&
        !hasSomeLeadManagementSalesDirectorAccess &&
        !hasSomeLeadManagementSalesManagementAccess;

      const hasOnlyLeadManagementSalesDirectorAccess =
        hasSomeLeadManagementPortalAccess &&
        hasSomeLeadManagementSalesDirectorAccess &&
        !hasSomeLeadManagementPartnerAccess &&
        !hasSomeLeadManagementDistributorAccess &&
        !hasSomeLeadManagementSalesTeamAccess &&
        !hasSomeLeadManagementSalesManagementAccess;

      const hasOnlyLeadManagementSalesManagementAccess =
        hasSomeLeadManagementPortalAccess &&
        hasSomeLeadManagementSalesManagementAccess &&
        !hasSomeLeadManagementPartnerAccess &&
        !hasSomeLeadManagementDistributorAccess &&
        !hasSomeLeadManagementSalesTeamAccess &&
        !hasSomeLeadManagementSalesDirectorAccess;

      if (
        hasSomeReaderAccess ||
        hasSomeCreatorAccess ||
        hasSomeApproverAccess ||
        hasSomeSupportPortalAccess ||
        hasSomeLeadManagementPortalAccess
      ) {
        setIsNoRolesUser(false);
      }

      setCurrentUserAccessDetails({
        productDocsAccessMap,
        locationsAccessMap,
        currentUserDetails,

        hasSomeDocumentPortalAccess,
        hasSomeSupportPortalAccess,
        hasSomeLeadManagementPortalAccess,

        hasSomeReaderAccess,
        hasSomeCreatorAccess,
        hasSomeApproverAccess,

        hasSomeSupportLeadAccess,
        hasSomeSupportEngineerAccess,
        hasSomeDistributorAccess,
        hasSomePartnerAccess,
        hasSomeCustomerAccess,
        hasSomePreSalesAccess,
        hasSomeManagementAccess,
        hasSomeQAAccess,

        hasOnlyDistributorAccess,
        hasOnlyPartnerAccess,
        hasOnlyCustomerAccess,

        supportLeadProducts,
        isSupportEngineer,

        hasSomeLeadManagementPartnerAccess,
        hasSomeLeadManagementDistributorAccess,
        hasSomeLeadManagementSalesTeamAccess,
        hasSomeLeadManagementSalesDirectorAccess,
        hasSomeLeadManagementSalesManagementAccess,

        hasOnlyLeadManagementPartnerAccess,
        hasOnlyLeadManagementDistributorAccess,
        hasOnlyLeadManagementSalesTeamAccess,
        hasOnlyLeadManagementSalesDirectorAccess,
        hasOnlyLeadManagementSalesManagementAccess,

        leadManagementSalesTeamLocations,
        leadManagementSalesDirectorLocations,
        leadManagementSalesManagementLocations,
      });
    }
  }, [
    currentUserDetails,
    currentUserOrganization,
    groupsByOrganization,
    setCurrentUserAccessDetails,
    setIsNoRolesUser,
  ]);
};

export default useGetCurrentUserAccessDetails;
