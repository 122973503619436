const isPartnerOrDistributor = (id: any) => {
  if (!id) {
    return false;
  }

  if (id[3] === 'P' || id[3] === 'D') {
    return true;
  }

  return false;
};

export const isPartnerOrDistributorOrCustomer = (id: any) => {
  if (!id) {
    return false;
  }

  if (id[3] === 'P' || id[3] === 'D' || id[3] === 'C') {
    return true;
  }

  return false;
};

export default isPartnerOrDistributor;
