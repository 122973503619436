export const OPEN = 'Open';
export const CLOSED = 'Closed';
export const ASSIGNED = 'Assigned';
export const IN_PROGRESS = 'In Progress';
export const RESOLVED = 'Resolved';

export const OVERDUE = 'Overdue';
export const RESPONSE_DUE = 'Response Due';

export const SEVERITY_1 = 'Severity 1';
export const SEVERITY_2 = 'Severity 2';
export const SEVERITY_3 = 'Severity 3';
export const SEVERITY_4 = 'Severity 4';

export const ADD_TICKET = 'ADD_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const ASSIGN_TICKET = 'ASSIGN_TICKET';
export const CREATE_TICKET_RULE = 'CREATE_TICKET_RULE';
export const UPDATE_TICKET_RULE = 'UPDATE_TICKET_RULE';
export const DELETE_TICKET_RULE = 'DELETE_TICKET_RULE';

export const ADD_TASK = 'ADD_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';

export const STATISTICS_MAPPER: Record<string, string> = {
  Assigned: 'ASSIGNED',
  Overdue: 'OVERDUE',
  'S1, S2': 'S1_S2',

  'Ticket Raised': 'TICKETS_RAISED',
  Unassigned: 'UN_ASSIGNED',
  Recurring: 'RECURRING',
  Resolved: 'RESOLVED',
  'Total Open Tickets': 'TOTAL_OPEN_TICKETS',

  'My Tickets': 'MY_TICKETS',
  'My Org Tickets': 'MY_ORG_TICKETS',
  'New Customer Tickets': 'NEW_CUSTOMER_TICKETS',

  Updates: 'UPDATES',
  'My Org': 'MY_ORG',
  'In Progress': 'IN_PROGRESS',

  UN_ASSIGNED_S1_S2: 'UN_ASSIGNED_S1_S2',
  'Pending Action By': 'PENDING_ACTION_BY',
};

export const COLORS = [' #BA2216', ' #F76110', '#084E7E', '#1077B8'];
