import {
  Icon,
  Nav,
  TooltipHost,
  ActionButton,
  type INavLinkGroup,
  type INavStyles,
  type INavButtonProps,
  type IComponentAs,
  type INavLink,
} from '@fluentui/react';
import { useAtomValue } from 'jotai';
import { memo } from 'react';

import Flex from 'components/Flex';

import { currentUserDetailsAtom } from 'atoms/modals';

const navStyles: Partial<INavStyles> = {
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    outline: 'none',
    paddingLeft: '10px',
    color: '#323130',
  },
  linkText: {
    whiteSpace: 'normal',
    lineHeight: 'inherit',
    outline: 'none',
    textDecoration: 'none',
    outlineColor: '#fff',
    color: '#323130',
    fontSize: '14px',
    fontWeight: 400,
    padding: '0px',
    WebkitFontSmoothing: 'antialiased',
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    border: 'none',
    background: 'transparent',
    selectors: {
      '&:focus': {
        outline: 'none',
        color: '#323130',
        fontWeight: '100',
        outlineColor: 'transparent',
      },
      '&:hover': {
        outline: 'none',
        color: '#323130',
        outlineColor: 'transparent',
      },
    },
  },

  link: {
    whiteSpace: 'normal',
    alignItems: 'center',
    lineHeight: 'inherit',
    outline: 'none',
    outlineColor: '#fff',
    color: '#323130',
    fontFamily: 'Segoe UI,SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif',
    paddingLeft: '10px',
    selectors: {
      '&:focus': {
        outline: 'none',
        color: '#323130',
        backgroundColor: 'transparent',
        outlineColor: 'transparent',
      },
      '&:hover': {
        outline: 'none',
        color: '#323130',
        backgroundColor: 'transparent',
        outlineColor: 'transparent',
      },
      '::after': {
        borderLeftWidth: 4,
        height: '60%',
        marginTop: '13px',
      },
    },
  },
  chevronButton: {
    marginLeft: '12rem',
    color: '#323130',
    backgroundColor: 'transparent',
    selectors: {
      '&:focus': {
        outline: 'none',
        color: '#323130',
        backgroundColor: 'transparent',
      },
      '&:hover': {
        outline: 'none',
        backgroundColor: 'transparent',
        color: '#323130',
        outlineColor: 'transparent',
      },
      '::after': {
        content: 'none',
      },
    },
  },
};

const linkAs: IComponentAs<INavButtonProps> | undefined = (props) => {
  return (
    <TooltipHost
      placeholder="right"
      directionalHint={12}
      content={props.link?.name}
    >
      <ActionButton
        {...props}
        iconProps={{
          iconName: props.iconProps?.iconName,
          style: {
            color: '#767676',
            display: 'block',
          },
        }}
        title=""
      />
    </TooltipHost>
  );
};

const MainSideBar = memo(
  ({
    handleSidebarClose,
    onLinkClick,
    selectedKey,
    navLinkGroups1,
  }: {
    handleSidebarClose: () => void;
    onLinkClick: (_ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => void;
    selectedKey: string | undefined;
    navLinkGroups1: INavLinkGroup[] | null;
  }) => {
    const currentUserDetails = useAtomValue(currentUserDetailsAtom);

    return (
      <Flex
        height="h-auto"
        width="w-full"
        direction="col"
        alignItems="start"
        padding="px-0"
        space="space-y-5"
        backgroundColor="bg-gray-f1-23"
        className="sideBar fixed h-screen overflow-y-scroll no-scrollbar"
        style={{ zIndex: 1000 }}
      >
        {currentUserDetails && (
          <>
            <Icon
              iconName="GlobalNavButton"
              className="w-16 text-base text-gray-f1-25 text-center mt-5 cursor-pointer block"
              onClick={handleSidebarClose}
            />
            <Nav
              className="mb-36"
              onLinkClick={onLinkClick}
              selectedKey={selectedKey}
              ariaLabel="Nav example with wrapped link text"
              styles={navStyles}
              linkAs={linkAs}
              groups={navLinkGroups1}
            />
          </>
        )}
      </Flex>
    );
  },
);

export default MainSideBar;
