import { useAtomValue } from 'jotai';
import { lazy, Suspense, useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Routes, Route, Navigate } from 'react-router-dom';

import Chapter from 'pages/Document/Chapter';
import Content from 'pages/Document/Content';
import SingleDocument from 'pages/Document/Document';
import LeadManagementDashboard from 'pages/LeadManagementDashboard';

import AppLayout from 'components/Applayout';
import ErrorFallback from 'components/ErrorFallback';
import HomeLoader from 'components/Loader/HomeLoader';

import {
  currentUserAccessDetailsAtom,
  isAdminAtom,
  isNoRolesUserAtom,
  isTenantAdminAtom,
} from 'atoms/modals';

const ActiveTenants = lazy(() => import('pages/ActiveTenants'));
const ActiveTenantsSlidesv1 = lazy(() => import('pages/ActiveTenantsSlidesv1'));
const DeletedTenants = lazy(() => import('pages/DeletedTenants'));

const Dashboard = lazy(() => import('pages/Dashboard'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const Profile = lazy(() => import('pages/Profile'));

const ActiveUsers = lazy(() => import('pages/ActiveUsers'));
const DeletedUsers = lazy(() => import('pages/DeletedUsers'));
const ApplicationRoleAssignment = lazy(
  () => import('pages/ApplicationRoleAssignment'),
);
const ActiveGroups = lazy(() => import('pages/ActiveGroups'));
const DeletedGroups = lazy(() => import('pages/DeletedGroups'));

const SupportPortalDashboard = lazy(
  () => import('pages/SupportPortalDashBoard'),
);

const LeadManagement = lazy(() => import('pages/LeadManagement'));

const Ticket = lazy(() => import('pages/Ticket'));
const Contract = lazy(() => import('pages/Contract'));

const MyTask = lazy(() => import('pages/MyTask'));

const Products = lazy(() => import('pages/Products'));
const Documents = lazy(() => import('pages/Documents'));
const Document = lazy(() => import('pages/Document'));

const DocumentationPortalSearch = lazy(
  () => import('pages/DocumentationPortalSearch'),
);

const Currency = lazy(() => import('pages/Currency'));

const SetupCountry = lazy(() => import('pages/SetupCountry'));
const SetupProductVersion = lazy(() => import('pages/SetupProductVersion'));
const SetupSolution = lazy(() => import('pages/SetupSolution'));
const SetupIndustry = lazy(() => import('pages/SetupIndustry'));
const SetupRole = lazy(() => import('pages/SetupRole'));
const SetupIssueCategories = lazy(() => import('pages/SetupIssueCategories'));
const SetupReason = lazy(() => import('pages/SetupReason'));
const SetupSeverity = lazy(() => import('pages/SetupSeverity'));
const ContactManagement = lazy(() => import('pages/ContactManagement'));
const ContractManagement = lazy(() => import('pages/ContractManagement'));
const ContractLine = lazy(() => import('pages/ContractLine'));
const ProductSubCategory = lazy(() => import('pages/ProductSubCategory'));
const ProductManagement = lazy(() => import('pages/ProductManagement'));
const OrganizationManagement = lazy(
  () => import('pages/OrganizationManagement'),
);
const TicketRule = lazy(() => import('pages/TicketRule'));

const RequireAuth = ({
  children,
  shouldShowRoute,
  redirectTo = '/',
}: {
  children: JSX.Element;
  shouldShowRoute: boolean;
  redirectTo?: string;
}) => {
  return shouldShowRoute ? children : <Navigate to={redirectTo} />;
};

const AppRoutes = () => {
  const isSuperAdmin = useAtomValue(isAdminAtom);
  const isTenantAdmin = useAtomValue(isTenantAdminAtom);

  const isNoRolesUser = useAtomValue(isNoRolesUserAtom);

  const currentUserAccessDetails = useAtomValue(currentUserAccessDetailsAtom);

  const myErrorHandler = useCallback(
    (error: Error, info: { componentStack: string }) => {
      // Do something with the error
      // E.g. log to an error logging client here

      console.error(error, info.componentStack);
    },
    [],
  );

  const showSupportPortalRoute = useMemo(
    () =>
      isTenantAdmin ||
      (!isNoRolesUser &&
        !!currentUserAccessDetails?.hasSomeSupportPortalAccess),
    [
      currentUserAccessDetails?.hasSomeSupportPortalAccess,
      isNoRolesUser,
      isTenantAdmin,
    ],
  );

  const showLeadManagementPortalRoute = useMemo(
    () =>
      isTenantAdmin ||
      (!isNoRolesUser &&
        !!currentUserAccessDetails?.hasSomeLeadManagementPortalAccess),
    [
      currentUserAccessDetails?.hasSomeLeadManagementPortalAccess,
      isNoRolesUser,
      isTenantAdmin,
    ],
  );

  const showDocumentationPortalRoute = useMemo(
    () =>
      isTenantAdmin ||
      (!isNoRolesUser &&
        !!currentUserAccessDetails?.hasSomeDocumentPortalAccess),
    [
      currentUserAccessDetails?.hasSomeDocumentPortalAccess,
      isNoRolesUser,
      isTenantAdmin,
    ],
  );

  return (
    <AppLayout>
      <Routes>
        <Route
          path="/activetenants"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isSuperAdmin}>
                  <ActiveTenants />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/activetenantsv1"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isSuperAdmin}>
                  <ActiveTenantsSlidesv1 />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/deletedtenants"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isSuperAdmin}>
                  <DeletedTenants />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <Dashboard />
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/profile"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <Profile />
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/PrivacyPolicy"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <PrivacyPolicy />
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/activeusers"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <ActiveUsers />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/deletedusers"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <DeletedUsers />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/applicationroleassignment"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <ApplicationRoleAssignment />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/activegroups"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <ActiveGroups />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/deletedgroups"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <DeletedGroups />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/supportPortal"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showSupportPortalRoute}>
                  <SupportPortalDashboard />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/leadRegistration"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showLeadManagementPortalRoute}>
                  <LeadManagementDashboard />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/leads"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showLeadManagementPortalRoute}>
                  <LeadManagement />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            path=":leadId/*"
            element={
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={myErrorHandler}
              >
                <Suspense fallback={<HomeLoader />}>
                  <RequireAuth shouldShowRoute={showLeadManagementPortalRoute}>
                    <LeadManagement />
                  </RequireAuth>
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>

        <Route
          path="/ticketRule"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <TicketRule />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/ticket"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showSupportPortalRoute}>
                  <Ticket />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/contracts"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showSupportPortalRoute}>
                  <Contract />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/task"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth
                  shouldShowRoute={
                    isTenantAdmin ||
                    // for Documentation Portal
                    (!isNoRolesUser &&
                      (!!currentUserAccessDetails?.hasSomeCreatorAccess ||
                        !!currentUserAccessDetails?.hasSomeApproverAccess)) ||
                    // Support Portal
                    (!isNoRolesUser &&
                      (!!currentUserAccessDetails?.hasSomeSupportLeadAccess ||
                        !!currentUserAccessDetails?.hasSomeSupportEngineerAccess)) ||
                    // Lead Management Portal
                    (!isNoRolesUser &&
                      (!!currentUserAccessDetails?.hasSomeLeadManagementSalesTeamAccess ||
                        !!currentUserAccessDetails?.hasSomeLeadManagementSalesDirectorAccess))
                  }
                >
                  <MyTask />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/products"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showDocumentationPortalRoute}>
                  <Products />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/products/:id"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showDocumentationPortalRoute}>
                  <Documents />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/doc-portal-search"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showDocumentationPortalRoute}>
                  <DocumentationPortalSearch />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/products/documents/:documentId"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={showDocumentationPortalRoute}>
                  <Document />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            index
            element={
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={myErrorHandler}
              >
                <SingleDocument />
              </ErrorBoundary>
            }
          />
          <Route
            path="subChapter/:subChapterId"
            element={
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={myErrorHandler}
              >
                <Chapter />
              </ErrorBoundary>
            }
          />
          <Route
            path="content/:contentId"
            element={
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={myErrorHandler}
              >
                <Content />
              </ErrorBoundary>
            }
          />
        </Route>

        <Route
          path="/currency"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <Currency />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/issue-categories"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <SetupIssueCategories />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/case-severities"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <SetupSeverity />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/closure-reasons"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <SetupReason />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/solutions"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <SetupSolution />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/roles"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <SetupRole />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/industries"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <SetupIndustry />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/countries"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <SetupCountry />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/organization-management"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <OrganizationManagement />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/contract-line"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute>
                  <ContractLine />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/contract-management"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <ContractManagement />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/contact-management"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <ContactManagement />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/product-management"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute>
                  <ProductManagement />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/product-sub-categories"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute>
                  <ProductSubCategory />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/product-versions"
          element={
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Suspense fallback={<HomeLoader />}>
                <RequireAuth shouldShowRoute={isTenantAdmin}>
                  <SetupProductVersion />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="*"
          element={
            <div className="grid h-screen px-4 bg-white place-content-center">
              <h1 className="tracking-widest text-gray-500 uppercase">
                404 | Not Found
              </h1>
            </div>
          }
        />
      </Routes>
    </AppLayout>
  );
};

export default AppRoutes;
