import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { groupsByOrganizationAtom } from 'atoms/modals';

import { useInfiniteGroupsByOrganization } from 'hooks/useGroupsByOrganization';

import type { QueryEnabledType } from 'types';

const useSetGroupsByOrganization = ({
  isQueryEnabled = true,
  customQueryKeys = [],
  organizationId,
}: QueryEnabledType & {
  organizationId: string;
}) => {
  const setGroupsByOrganization = useSetAtom(groupsByOrganizationAtom);

  const { groupsByOrganizationSource } = useInfiniteGroupsByOrganization({
    organizationId,
    enabled: isQueryEnabled,
    queryKey: customQueryKeys,
    staleTime: 0,
  });

  useEffect(() => {
    setGroupsByOrganization(groupsByOrganizationSource);
  }, [groupsByOrganizationSource, setGroupsByOrganization]);
};

export default useSetGroupsByOrganization;
