import {
  EMPLOYEE,
  PARTNER,
  DISTRIBUTOR,
  ORGANIZATION_TYPES,
  SALES_TEAM,
  SALES_DIRECTOR,
  SALES_MANAGEMENT,
} from 'consts/roles';

const getLeadManagementUserRole = ({
  isSalesTeam,
  isSalesDirector,
  isSalesManagement,
  currentUserOrgId,
}: {
  isSalesTeam: boolean;
  isSalesDirector: boolean;
  isSalesManagement: boolean;
  currentUserOrgId: string | null;
}) => {
  const ROLE =
    currentUserOrgId && ORGANIZATION_TYPES[currentUserOrgId[3]]
      ? ORGANIZATION_TYPES[currentUserOrgId[3]]
      : null;

  if (ROLE === PARTNER) {
    return ROLE;
  }

  if (ROLE === DISTRIBUTOR) {
    return ROLE;
  }

  if (ROLE === EMPLOYEE) {
    if (isSalesDirector) {
      return SALES_DIRECTOR;
    }

    if (isSalesManagement) {
      return SALES_MANAGEMENT;
    }

    if (isSalesTeam) {
      return SALES_TEAM;
    }
  }

  return null;
};

export default getLeadManagementUserRole;
