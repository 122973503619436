import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Routes, Route } from 'react-router-dom';

// import AuthError from 'pages/AuthError';

import ErrorFallback from 'components/ErrorFallback';
import HomeLoader from 'components/Loader/HomeLoader';

const SignIn = lazy(() => import('pages/SignIn'));
const SignUp = lazy(() => import('pages/SignUp'));

const AuthRoutes = () => {
  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here

    console.error(error, info.componentStack);
  };

  return (
    <Routes>
      <Route
        path="/sign_in"
        element={
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={myErrorHandler}
          >
            <Suspense fallback={<HomeLoader />}>
              <SignIn />
            </Suspense>
          </ErrorBoundary>
        }
      />
      <Route
        path="/sign_up"
        element={
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={myErrorHandler}
          >
            <Suspense fallback={<HomeLoader />}>
              <SignUp />
            </Suspense>
          </ErrorBoundary>
        }
      />
      <Route
        path="/terms_and_condition"
        element={() => {
          if (global.window) {
            global.window.location.href = 'https://www.falainacloud.com/';
          }

          return null;
        }}
      />
    </Routes>
  );
};

export default AuthRoutes;
