import { useState, useEffect } from 'react';

const useToggleSidebar = (initialValue = true) => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(initialValue);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setSidebarOpen(false);
    }
  }, []);

  return [isSidebarOpen, setSidebarOpen] as const;
};

export default useToggleSidebar;
