import { Dropdown } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import {
  Legend,
  ResponsiveContainer,
  Cell,
  Pie,
  PieChart,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import Flex from 'components/Flex';
import Text from 'components/Text';

import renderCustomLegendForChart from 'helpers/renderCustomLegendForChart';

const productsColors = [
  '#074F7F',
  '#A33C23',
  '#601246',
  '#664147',
  '#39B556',
  '#0E613F',
  '#EF4338',
  '#A23E49',
  '#1B7FB8',
  '#382562',
  '#58C3B8',
  '#EE8832',
  '#F2C177',
];

const getCustomTooltipChartThree = ({
  active,
  payload,
  chartData,
}: TooltipProps<ValueType, NameType> & { chartData: any }) => {
  const sum: number = chartData?.reduce(
    (prev: any, curr: any) => prev + curr.value,
    0,
  );

  if (active && payload && payload.length > 0) {
    return (
      <div className="custom-tooltip bg-white border border-black p-2">
        <div className="label  text-xs">{`${payload[0]?.name} `}</div>
        <div className="label text-xs">
          {`value : ${payload[0]?.value},`}
          <span className="label text-xs font-bold">{` ${(
            ((payload[0]?.value as any) / sum) *
            100
          ).toFixed(2)}%`}</span>
        </div>
      </div>
    );
  }

  return null;
};

export const ChartThree = ({
  selectedSolution,
  setSelectedSolution,
  solutionList,
  pieChartDataOne,
}: {
  selectedSolution: {
    pieChartOneSolution: string;
    allSolution: string;
  };
  setSelectedSolution: React.Dispatch<
    React.SetStateAction<{
      pieChartOneSolution: string;
      allSolution: string;
    }>
  >;
  solutionList: any[];
  pieChartDataOne: any[];
}) => {
  const navigate = useNavigate();

  return (
    <Flex direction="col" className="  my-2">
      <Flex direction="row" height="h-8">
        <Text className="text-base flex-1 flex items-center pr-2" weight={600}>
          Leads by Solutions
        </Text>
        <Dropdown
          defaultSelectedKey="all"
          selectedKey={selectedSolution.pieChartOneSolution}
          className="text-sm w-1/2 flex-1"
          options={[
            {
              id: 'all',
              key: 'all',
              text: 'All',
            },

            ...solutionList,
          ]}
          onChange={(_event, value) => {
            if (value?.text === 'All') {
              setSelectedSolution({
                pieChartOneSolution: 'all',
                allSolution: 'all',
              });
            } else {
              setSelectedSolution({
                pieChartOneSolution: value?.id || '',
                allSolution: value?.id || '',
              });
            }
          }}
        />
      </Flex>
      <div className="border-2 w-full  mt-4 pt-2 recharts-by-solution">
        <ResponsiveContainer width="100%" aspect={1}>
          <PieChart width={400} height={400}>
            <Pie
              data={pieChartDataOne}
              cx="50%"
              cy="50%"
              // labelLine={false}
              // label={renderCustomizedLabel}
              outerRadius="80px"
              fill="#8884d8"
              dataKey="value"
              onClick={(e) => {
                navigate(`/leads?solution=${e.id}`);
              }}
            >
              {pieChartDataOne.map((entry, index) => (
                <Cell
                  key={`cell-${entry.name}`}
                  fill={productsColors[index % productsColors.length]}
                />
              ))}
            </Pie>
            <Tooltip
              content={(args) =>
                getCustomTooltipChartThree({
                  ...args,
                  chartData: pieChartDataOne,
                })
              }
            />
            <Legend content={renderCustomLegendForChart} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Flex>
  );
};

export default ChartThree;
