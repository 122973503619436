/* eslint-disable unicorn/better-regex */
export const AS_REQUIRED = { required: 'This information is required' };
export const AS_IMAGE_REQUIRED = { required: 'Please upload an image' };
export const AS_EMAIL = {
  ...AS_REQUIRED,
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Entered value does not match email format',
  },
};
export const AS_PASSWORD = {
  ...AS_REQUIRED,
  // pattern: {
  //   value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%&*?@])[\d!$%&*?@A-Za-z]{8,}$/,
  //   message:
  //     'Must have atleast 1 Upper(A-Z), lower case(a-z), atleast 1 digit(0-9) and atleast 1 special char.',
  // },
  minLength: {
    value: 8,
    message: 'Min length is 8',
  },
};

export const AS_ORGID = {
  ...AS_REQUIRED,
  pattern: {
    value: /^[A-Z0-9]*$/,
    message: ' ',
  },
  minLength: {
    value: 8,
    message: 'Min length is 8',
  },
};

export const AS_NAME = {
  ...AS_REQUIRED,
  minLength: {
    value: 3,
    message: 'Min length is 3',
  },
};

export const AS_FIRST_NAME = {
  ...AS_REQUIRED,
  minLength: {
    value: 1,
    message: 'Min length is 1',
  },
  maxLength: {
    value: 25,
    message: 'Max length is 25',
  },
};

export const AS_LAST_NAME = {
  ...AS_REQUIRED,
  minLength: {
    value: 1,
    message: 'Min length is 1',
  },
  maxLength: {
    value: 25,
    message: 'Max length is 25',
  },
};

export const AS_USER_NAME = {
  ...AS_REQUIRED,
  minLength: {
    value: 1,
    message: 'Min length is 1',
  },
};

export const AS_DESCRIPTION = {
  ...AS_REQUIRED,
  minLength: {
    value: 3,
    message: 'Min length is 3',
  },
};

export const AS_NUMBER = {
  ...AS_REQUIRED,
  min: {
    value: 1,
    message: 'Min value is 1',
  },
};

export const AS_NUMBER_NOT_REQUIRED = {
  min: {
    value: 1,
    message: 'Min value is 1',
  },
};

export const AS_PHONE_NOT_REQUIRED = {
  pattern: {
    value: /^[0-9]$/,
    message: '',
  },
};

export const AS_ADDRESS_NOT_REQUIRED = {
  maxLength: {
    value: 255,
    message: 'Max length is 255',
  },
};

export const AS_TEXT_NOT_REQUIRED = {
  minLength: {
    value: 3,
    message: 'Min length is 3',
  },
};
