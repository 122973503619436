import { useSetAtom, useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Doc, DocItem, StatusType } from 'API';

import { docItemEditAtom, refDoctItemsAtom } from 'atoms/modals';

import falainaTenantId from 'consts/tenant';

import useConstructStatus from 'hooks/useConstructStatus';
import useHistoryLocation from 'hooks/useHistoryLocation';
import useIsContentReader from 'hooks/useIsContentReader';
import useTransformDocItems from 'hooks/useTransformDocItems';

import { useGetDocVersion, useListDocVersions } from 'queries/content.query';

import getNonNullableList from 'helpers/utils/getNonNullableList';
import { useInfiniteDocs } from 'hooks/useDocs';

const useReferenceContent = ({ content }: { content: Partial<Doc> | null }) => {
  const { docsSource, isInitialLoading, hasNextPage } = useInfiniteDocs({
    queryKey: [`docs/reference-content/${content?.referenceDocId}`],
    enabled: !!content?.referenceDocId,
    filter: {
      status: { eq: StatusType.PUBLISHED },
      id: { eq: content?.referenceDocId },
      tenantId: { eq: falainaTenantId },
    },
  });

  const referenceDoc = useMemo(() => {
    return docsSource.find((x) => x.id === content?.referenceDocId) ?? null;
  }, [content, docsSource]);

  return {
    isInitialLoading,
    referenceDoc,
    hasNextPage,
  };
};

const useContentPage = () => {
  const [, location] = useHistoryLocation();

  const search = location.search;

  const { status } = useConstructStatus({ search });

  const { documentId, contentId } = useParams();

  const [isDropdown, setIsDropdown] = useState<boolean>(false);

  const [refDocItems, setRefDocItems] = useAtom(refDoctItemsAtom);

  const setDocItem = useSetAtom(docItemEditAtom);

  const { isContentReader } = useIsContentReader();

  const {
    data: docVersionsData,
    fetchNextPage: docVersionsFetchNextPage,
    hasNextPage: docVersionsHasNextPage,
    isInitialLoading: docVersionsIsLoading,
    isError: docVersionsIsError,
    isFetchingNextPage: docVersionsIsFetchingNextPage,
  } = useListDocVersions({
    id: contentId!,
    enabled: !!contentId,
  });

  useEffect(() => {
    if (docVersionsHasNextPage && !docVersionsIsFetchingNextPage) {
      docVersionsFetchNextPage();
    }
  }, [
    docVersionsFetchNextPage,
    docVersionsHasNextPage,
    docVersionsIsFetchingNextPage,
  ]);

  const allDocVersions = useMemo(() => {
    const pages = docVersionsData?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page?.data?.listDocs?.items),
    );

    return tempItems as Partial<Doc>[];
  }, [docVersionsData?.pages]);

  const publishedDocVersion =
    allDocVersions.find((c) => c.status === StatusType.PUBLISHED) ?? null;
  const pendingDocVersion =
    allDocVersions.find((c) => c.status === StatusType.PENDING) ?? null;

  const doc =
    status === StatusType.PUBLISHED
      ? publishedDocVersion
      : status === StatusType.PENDING || status === StatusType.PENDING_APPROVAL
        ? pendingDocVersion ?? publishedDocVersion
        : null;

  const initialDocItems = (doc?.docItems?.items ?? []).flatMap((x) =>
    x ? [x] : [],
  ) as Partial<DocItem>[];

  const initialDocItemNextToken = doc?.docItems?.nextToken ?? null;

  // Only runs if the previous query returns docItem.nextToken
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isInitialLoading,
    isError,
    isFetchingNextPage,
  } = useGetDocVersion({
    id: doc?.id ?? '',
    version: doc?.version ?? '',
    enabled: !!doc?.id && !!initialDocItemNextToken,
  });

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const nextDocItems = useMemo(() => {
    const pages = data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page?.data?.getDoc?.docItems?.items),
    );

    return tempItems as Partial<DocItem>[];
  }, [data?.pages]);

  const content = doc;
  const docItems = initialDocItemNextToken ? nextDocItems : initialDocItems;

  const { filteredDocItems, documentsMap } = useTransformDocItems({
    docItems: refDocItems ?? docItems,
  });

  const {
    referenceDoc,
    isInitialLoading: isReferenceDocsLoading,
    hasNextPage: hasNextPageReferenceDocs,
  } = useReferenceContent({ content });

  useEffect(() => {
    if (referenceDoc?.docItems?.items) {
      const items = referenceDoc.docItems.items.flatMap((item) =>
        item?.status === StatusType.PUBLISHED ? [item] : [],
      );
      setRefDocItems(items);
    } else if (!referenceDoc) {
      setRefDocItems(null);
    }
  }, [referenceDoc?.docItems?.items, setRefDocItems]);

  return {
    isInitialLoading: docVersionsIsLoading || isInitialLoading,

    isError: docVersionsIsError || isError,

    content,

    isReferenceDocsLoading,

    hasNextPageReferenceDocs,

    contentId,

    isContentReader,

    documentsMap,

    filteredDocItems,

    status,

    search,

    documentId,

    isDropdown,
    setIsDropdown,

    setDocItem,

    referenceDoc,
  };
};

export default useContentPage;
