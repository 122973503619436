import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ChildrenProps } from 'types';

const queryClient = new QueryClient();

const ReactQuerySetting = ({ children }: ChildrenProps) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQuerySetting;
