import ICONS from 'consts/icons';

import {
  Check,
  TrendUp,
  ChevronDown,
  ChevronRight,
  List,
  Dashboard,
  Bell,
  DotImage,
  Users,
  People,
  User,
  Sort,
  Close,
  Plus,
  ArrowLeft,
  AddCircle,
  ChevronLeft,
  BriefCase,
  ArrowRight,
  InfoButton,
  ClusterManagement,
  PolicyManager,
  Telemetry,
  TeamManager,
  LabelManager,
  ProvenanceGraph,
  S3BucketAndDB,
  Integration,
  Policies,
  Documentation,
  Scoop,
  Logout,
  Filter,
  Database,
  graph,
  Edit,
  Archive,
  S3Bucket,
  VisitLink,
  Eye,
  LinkedIn,
  Trash,
  Product,
  Like,
  UnLike,
  Image,
  Settings,
  Theme,
  QuestionIcon,
  MenuIcon,
  World,
  FacebookIcon,
  GooglePlusIcon,
  WindowsIcon,
  profileCamera,
  DotsHorizaontal,
  AppleIcon,
} from './Icons';

const components = {
  check: Check,
  'trend-up': TrendUp,
  'chevron-down': ChevronDown,
  'chevron-right': ChevronRight,
  list: List,
  dashboard: Dashboard,
  bell: Bell,
  dot: DotImage,
  users: Users,
  people: People,
  user: User,
  sort: Sort,
  close: Close,
  plus: Plus,
  arrowleft: ArrowLeft,
  'add-circled': AddCircle,
  'chevron-left': ChevronLeft,
  'brief-case': BriefCase,
  'arrow-right': ArrowRight,
  'info-button': InfoButton,
  'cluster-manager': ClusterManagement,
  'policies-manager': PolicyManager,
  telemetry: Telemetry,
  'team-manager': TeamManager,
  'label-manager': LabelManager,
  'provenance-graph': ProvenanceGraph,
  's3-bucket-db': S3BucketAndDB,
  integration: Integration,
  policies: Policies,
  documentation: Documentation,
  scoop: Scoop,
  logout: Logout,
  filter: Filter,
  database: Database,
  graph,
  edit: Edit,
  archive: Archive,
  's3-bucket': S3Bucket,
  'visit-link': VisitLink,
  eye: Eye,
  linkedIn: LinkedIn,
  trash: Trash,
  product: Product,
  like: Like,
  unlike: UnLike,
  'image-icon': Image,
  settings: Settings,
  theme: Theme,
  'question-icon': QuestionIcon,
  'menu-icon': MenuIcon,
  'world-icon': World,
  'facebook-icon': FacebookIcon,
  'google-plus-icon': GooglePlusIcon,
  'windows-icon': WindowsIcon,
  'dot-horizontal': DotsHorizaontal,
  'profile-camera': profileCamera,
  'apple-icon': AppleIcon,
};

export type IconNameType = keyof typeof components;

type Props = {
  name: IconNameType;
  className?: string;
};

export const Icon = ({ name, className }: Props) => {
  const IconComponent = components[name];
  return (
    <div data-testid="Icon">
      <IconComponent className={className} />
    </div>
  );
};

/* 
  When you need to add new icon, search in https://icomoon.io/app/#/select, find the d attr.
  If you’ve got multiple shapes in Adobe Illustrator, you can do Object > Compound Path > Make and 
  that will then export a single path rather than the discrete shapes.
*/

type IconKey = keyof typeof ICONS;

export type IconValue = (typeof ICONS)[IconKey];

type ClassNameProps = {
  className?: string;
  strokeWidth?: string;
  icon: IconValue;
};

export const NewIcon = ({ className, icon, strokeWidth }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    stroke="currentColor"
    strokeWidth={strokeWidth}
  >
    <path d={icon} />
  </svg>
);
