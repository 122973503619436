import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { StatusType } from 'API';

import { allUserAccessiblePublishedRootChaptersAtom } from 'atoms/modals';

import { useInfiniteDocs } from './useDocs';

const useUserAccessibleDocs = () => {
  const allUserAccessiblePublishedRootChapters = useAtomValue(
    allUserAccessiblePublishedRootChaptersAtom,
  );

  const docsResult = useInfiniteDocs({
    filter: {
      status: { eq: StatusType.PUBLISHED },
    },
  });

  const { docsSource } = docsResult;

  const allUserAccessibleDocs = useMemo(() => {
    const rootChapterIds = new Set(
      allUserAccessiblePublishedRootChapters.map((x) => x?.id),
    );

    const tempDocs = docsSource.filter(
      (x) => x && rootChapterIds.has(x.rootChapterId),
    );

    return tempDocs;
  }, [allUserAccessiblePublishedRootChapters, docsSource]);

  return { allUserAccessibleDocs, ...docsResult };
};

export default useUserAccessibleDocs;
