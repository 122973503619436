/* eslint-disable react/forbid-elements */
import {
  Panel,
  Separator,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import Flex from 'components/Flex';
import InputField from 'components/InputField';

import { Chapter, StatusType, UpdateChapterInput } from 'API';

import type { AddDocumentsParams } from 'types/Pages/Products';

import {
  QueryKeys,
  chapterEditAtom,
  initialChapterAtomProps,
  isDocumentIconDeletedAtom,
} from 'atoms/modals';

import { INITIAL_DOC_SETTINGS } from 'consts/docVersions';
import falainaTenantId from 'consts/tenant';
import { AS_NAME, AS_NUMBER } from 'consts/validations';

import useChapterMutations from 'hooks/useChapterMutations';
import useGetCurrentAuthenticatedUser, {
  isCurrentUserAuthenticated,
} from 'hooks/useGetCurrentAuthenticatedUser';
import useS3FileUrl from 'hooks/useS3FileUrl';
import useStatus from 'hooks/useStatus';

import getS3InputObject from 'helpers/transformFile';
import { generateSessionTimeoutToast } from 'helpers/utils/generateToast';

import DocumentIconWithDelete from './DocumentIconWithDelete';

type ChapterArgsType = {
  name: string;
  description: string;
  file?: string;
  orderFloat: string;
};

const buttonStyles = { root: { marginRight: 8 } };

const useParent = () => {
  const chapterAtomValue = useAtomValue(chapterEditAtom);
  const { chapter, chapterType } = chapterAtomValue;

  const parentProductDocId = chapter?.parentProductDocId;
  const rootChapterId = chapter?.rootChapterId;
  const parentChapterId = chapter?.parentChapterId;

  const parent = useMemo(() => {
    const noneValue = 'none';

    return {
      parentProductDocId:
        chapterType === QueryKeys.ROOT_CHAPTER
          ? parentProductDocId ?? noneValue
          : noneValue,
      rootChapterId:
        chapterType === QueryKeys.ROOT_CHAPTER
          ? noneValue
          : rootChapterId ?? noneValue,
      parentChapterId:
        chapterType === QueryKeys.ROOT_CHAPTER
          ? noneValue
          : parentChapterId ?? noneValue,
    };
  }, [chapterType, parentProductDocId, rootChapterId, parentChapterId]);

  return parent;
};

const useClosePanel = () => {
  const setChapterAtom = useSetAtom(chapterEditAtom);
  const setIsChapterIconDeleted = useSetAtom(isDocumentIconDeletedAtom);

  const closePanel = useCallback(() => {
    setChapterAtom(initialChapterAtomProps);
    setIsChapterIconDeleted(false);
  }, [setChapterAtom, setIsChapterIconDeleted]);

  return closePanel;
};

const useChapterForm = () => {
  const chapterAtomValue = useAtomValue(chapterEditAtom);
  const { chapter } = chapterAtomValue;

  const defaultValues: ChapterArgsType = useMemo(
    () => ({
      name: '',
      description: '',
      file: '',
      orderFloat: '',
    }),
    [],
  );

  const formProps = useForm<ChapterArgsType>({
    defaultValues,
  });

  const { reset } = formProps;

  useEffect(() => {
    if (chapter) {
      const defaults = {
        name: chapter.name ?? defaultValues.name,
        description: chapter.description ?? defaultValues.description,
        file: '',
        orderFloat: chapter.orderFloat ?? defaultValues.orderFloat,
      };

      reset(defaults);
    }
  }, [
    chapter,
    defaultValues.description,
    defaultValues.name,
    defaultValues.orderFloat,
    reset,
  ]);

  return formProps;
};

type IChapterSliderFormProps = {
  rootChapter?: Partial<Chapter | null>;
  allRootChapterVersions: Partial<Chapter | null>[];
};

const ChapterSliderForm = ({
  rootChapter,
  allRootChapterVersions = [],
}: IChapterSliderFormProps) => {
  const navigate = useNavigate();

  const { documentId } = useParams();

  const chapterAtomValue = useAtomValue(chapterEditAtom);

  const { isOpen, action, chapter, chapterType } = chapterAtomValue;

  const status = useStatus();

  const parentObj = useParent();

  const closePanel = useClosePanel();

  const currentAuthenticatedUser = useGetCurrentAuthenticatedUser();

  const currentAuthenticatedUserId = currentAuthenticatedUser?.sub ?? null;

  const tenantId = falainaTenantId;

  const isDocumenttIconDeleted = useAtomValue(isDocumentIconDeletedAtom);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
    control,
  } = useChapterForm();

  const {
    onCreateChapter,
    onUpdateChapter,
    isCreateLoading,
    isUpdateLoading,
    isCreateSuccess,
    isUpdateSuccess,
  } = useChapterMutations();

  const filevalue = getValues('file');
  const fileurl = useS3FileUrl({ file: filevalue });

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (fileurl) {
      setValue('file', '');
    }
  }, [fileurl, setValue]);

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      reset();
    }
  }, [isCreateSuccess, isUpdateSuccess, reset]);

  const transformArgs = useCallback(
    (args: ChapterArgsType, removeFile: boolean) => {
      const transformedArgs = {
        description: args.description,
        name: args.name,
        orderFloat: args.orderFloat,
        file: args.file ? getS3InputObject(args.file) : chapter?.file,
      };

      if (removeFile) {
        transformedArgs.file = getValues().file
          ? getS3InputObject(getValues().file)
          : null;
      }

      return transformedArgs;
    },
    [chapter?.file, getValues],
  );

  const handleCreateNewRootChapterVersion = useCallback(() => {
    const isPendingRootChapterVersionPresent = allRootChapterVersions.some(
      (x) => x?.status === StatusType.PENDING,
    );

    const shouldCreateNewRootChapterVersion =
      currentAuthenticatedUserId &&
      status === StatusType.PUBLISHED &&
      rootChapter?.status === StatusType.PUBLISHED &&
      !isPendingRootChapterVersionPresent &&
      chapterType === QueryKeys.SUB_CHAPTER;

    if (shouldCreateNewRootChapterVersion) {
      const final = {
        ...rootChapter,
        id: rootChapter.id,
        name: rootChapter.name ?? '',
        description: rootChapter.description ?? '',
        version: rootChapter.version
          ? String(Number(rootChapter.version) + 1)
          : '2',
        status: StatusType.PENDING,
        createdByUserId: currentAuthenticatedUserId,
        lastModifiedByUserId: currentAuthenticatedUserId,
        tenantId,
        rootChapterId: rootChapter.rootChapterId ?? '',
        file: rootChapter.file,
      };

      onCreateChapter({
        chapter: {
          ...final,
        },
        isParentProduct: true,
        callback: closePanel,
        showNotification: true,
      });
    }
  }, [
    allRootChapterVersions,
    chapterType,
    closePanel,
    currentAuthenticatedUserId,
    onCreateChapter,
    rootChapter,
    status,
    tenantId,
  ]);

  const addNewDocument = useCallback(
    (data: AddDocumentsParams) => {
      const transformedArgs = transformArgs(data, isDocumenttIconDeleted);
      if (currentAuthenticatedUserId) {
        const final = {
          ...transformedArgs,
          ...INITIAL_DOC_SETTINGS,
          ...parentObj,
          createdByUserId: currentAuthenticatedUserId,
          lastModifiedByUserId: currentAuthenticatedUserId,
          tenantId,
        };

        onCreateChapter({
          chapter: {
            ...final,
          },
          isParentProduct: chapterType === QueryKeys.ROOT_CHAPTER,
          callback: () => {
            handleCreateNewRootChapterVersion();
            closePanel();
          },

          showNotification: true,
        });
      }
    },
    [
      chapterType,
      closePanel,
      currentAuthenticatedUserId,
      handleCreateNewRootChapterVersion,
      isDocumenttIconDeleted,
      onCreateChapter,
      parentObj,
      tenantId,
      transformArgs,
    ],
  );

  const updateDocument = useCallback(
    (data: AddDocumentsParams) => {
      const transformedArgs = transformArgs(data, isDocumenttIconDeleted);

      if (currentAuthenticatedUserId) {
        if (
          status === StatusType.PUBLISHED &&
          chapter?.status === StatusType.PUBLISHED
        ) {
          const final = {
            ...transformedArgs,
            ...parentObj,
            id: chapter.id,
            version: chapter.version
              ? String(Number(chapter.version) + 1)
              : '2',
            status: StatusType.PENDING,
            lastModifiedByUserId: currentAuthenticatedUserId,
            createdByUserId: currentAuthenticatedUserId,
            tenantId,
          };

          onCreateChapter({
            chapter: {
              ...final,
            },
            isParentProduct: chapterType === QueryKeys.ROOT_CHAPTER,
            callback: () => {
              handleCreateNewRootChapterVersion();
              closePanel();
            },

            showNotification: true,
          });
        }

        if (status === StatusType.PENDING) {
          if (chapter && chapter.status === StatusType.PUBLISHED) {
            const newVersion = chapter.version
              ? String(Number(chapter.version) + 1)
              : '2';

            const final = {
              ...transformedArgs,
              ...parentObj,
              id: chapter.id,
              version: newVersion,
              status: StatusType.PENDING,
              lastModifiedByUserId: currentAuthenticatedUserId,
              createdByUserId: currentAuthenticatedUserId,
              tenantId,
            };

            onCreateChapter({
              chapter: {
                ...final,
              },
              isParentProduct: chapterType === QueryKeys.ROOT_CHAPTER,
              callback: () => {
                closePanel();

                if (chapterType === QueryKeys.SUB_CHAPTER) {
                  navigate(
                    `/products/documents/${documentId}/subChapter/${chapter.id}?status=PENDING`,
                  );
                }
              },
              showNotification: true,
            });
          }

          if (chapter?.status === StatusType.PENDING) {
            const final: UpdateChapterInput = {
              id: chapter.id ?? '',
              version: chapter.version ?? '',
              lastModifiedByUserId: currentAuthenticatedUserId,
              ...transformedArgs,
              ...parentObj,
            };

            onUpdateChapter({
              chapter: {
                ...final,
              },
              isParentProduct: chapterType === QueryKeys.ROOT_CHAPTER,
              callback: closePanel,
              showNotification: true,
            });
          }
        }
      }
    },
    [
      chapter,
      chapterType,
      closePanel,
      currentAuthenticatedUserId,
      documentId,
      handleCreateNewRootChapterVersion,
      isDocumenttIconDeleted,
      navigate,
      onCreateChapter,
      onUpdateChapter,
      parentObj,
      status,
      tenantId,
      transformArgs,
    ],
  );

  const submit = useCallback(
    async (data: AddDocumentsParams) => {
      const isAuthenticated = await isCurrentUserAuthenticated();

      if (isAuthenticated) {
        const isAddChapter = action === 'ADD';
        const isUpdateChapter = action === 'UPDATE';

        if (isAddChapter) {
          addNewDocument(data);
        }

        if (isUpdateChapter) {
          updateDocument(data);
        }
      } else {
        closePanel();
        generateSessionTimeoutToast();
      }
    },
    [action, addNewDocument, closePanel, updateDocument],
  );

  const isNameChanged = watch('name') !== chapter?.name;
  const isDescriptionChanged = watch('description') !== chapter?.description;
  const isOrderFloatChanged = watch('orderFloat') !== chapter?.orderFloat;
  const isFileChanged = !!watch('file');

  const isSaveButtonEnabled =
    isNameChanged ||
    isDescriptionChanged ||
    isOrderFloatChanged ||
    isFileChanged ||
    isDocumenttIconDeleted;

  const onRenderFooterContent = useCallback(
    () => (
      <div>
        <Separator />
        {isCreateLoading || isUpdateLoading || !isSaveButtonEnabled ? (
          <PrimaryButton disabled text="Save" styles={buttonStyles} />
        ) : (
          <PrimaryButton
            text="Save"
            type="submit"
            styles={buttonStyles}
            className="bg-blue-db-20 focus:outline-none"
            onClick={handleSubmit(submit)}
          />
        )}
        <DefaultButton
          text="Cancel"
          onClick={() => {
            closePanel();
          }}
        />
      </div>
    ),
    [
      isCreateLoading,
      isUpdateLoading,
      isSaveButtonEnabled,
      handleSubmit,
      submit,
      closePanel,
    ],
  );

  const title = chapterType === QueryKeys.SUB_CHAPTER ? 'Chapter' : 'Document';

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={() => {
        closePanel();
      }}
      type={7}
      styles={{ root: { marginTop: 50 } }}
      customWidth="448px"
      closeButtonAriaLabel="Close"
      isLightDismiss
      hasCloseButton
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
    >
      <Flex width="w-full" direction="col" space="space-y-6">
        <InputField
          required
          type="text"
          name="name"
          control={control}
          label={`${title} Title`}
          error={errors.name}
          placeholder={`${title} Title`}
          rules={AS_NAME}
          width="w-full"
        />
        <InputField
          type="text"
          multiline
          rows={6}
          name="description"
          control={control}
          label="Description"
          error={errors.description}
          placeholder="Quick description ..."
          width="w-full"
        />
        <InputField
          required
          type="number"
          name="orderFloat"
          control={control}
          label="Order"
          error={errors.orderFloat}
          placeholder="Order"
          width="w-full"
          rules={AS_NUMBER}
        />

        {chapterType === QueryKeys.ROOT_CHAPTER &&
          (!getValues('file') || filevalue) && (
            <input
              id="file"
              className="mt-8"
              type="file"
              placeholder="Icon"
              accept="image/*"
              {...register('file')}
            />
          )}

        {fileurl && (
          <img
            src={fileurl}
            alt="fetchedPic"
            className="w-160 object-contain"
          />
        )}
        {chapter?.file && (
          <DocumentIconWithDelete
            key={chapter.id}
            file={chapter.file}
            iconId={chapter.id}
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
          />
        )}
      </Flex>
    </Panel>
  );
};

export default ChapterSliderForm;
