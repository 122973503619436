/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/forbid-elements */
import { TextField } from '@fluentui/react';
import classNames from 'classnames';
import { FormEvent } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';

import Flex from 'components/Flex';
import Text from 'components/Text';

import styles from 'styles/input.module.css';

type VariantType = 'primary' | 'secondary' | 'tertiary' | 'quaternary';
type ErrorPlacementType = 'top' | 'bottom';
type InputFieldProps = {
  // id: string;
  name: string | `${string}.${string}` | `${string}.${number}`;
  placeholder?: string;
  type?: string;
  // error?: { message?: string; type: unknown } | null;
  error?: any;
  label?: string;
  onChange?: (
    e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => void;
  variant?: VariantType;
  width?: string;
  control?: any;
  accept?: string;
  register?: any;
  rules?: UseControllerProps['rules'];
  borderless?: boolean;
  underlined?: boolean;
  multiline?: boolean;
  rows?: number;
  errorPlacement?: ErrorPlacementType;
  required?: boolean;
};

const InputField = ({
  register,
  placeholder = '',
  name,
  type = 'text',
  error,
  rules,
  variant = 'primary',
  width = 'w-96',
  control,
  onChange,
  label = '',
  accept,
  borderless = false,
  underlined = false,
  multiline = false,
  rows = 1,
  errorPlacement = 'top',
  required = false,
}: InputFieldProps) => {
  console.log(register, variant, onChange);
  const inputClassNames = classNames(
    error ? styles['input-has-error'] : styles['input-field'],
    width,
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={rules}
      render={({
        field: { onBlur, name: fieldName, value, onChange },
        fieldState: { error },
      }) =>
        errorPlacement === 'top' ? (
          <Flex direction="col" justify="between" margin="mb-1" width="w-full">
            <Flex className="h-1/2 mt-2" width="w-full">
              <Text className="text-red-700">{error?.message}</Text>
            </Flex>
            <Flex className="h-1/2" direction="col">
              <TextField
                required={required}
                label={label}
                type={type}
                borderless={borderless}
                underlined={underlined}
                multiline={multiline}
                rows={multiline ? rows : 1}
                placeholder={placeholder}
                className={inputClassNames}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                name={fieldName}
                defaultValue={undefined}
                accept={accept}
                errorMessage={error?.message && ' '}
              />
            </Flex>
          </Flex>
        ) : (
          <TextField
            required={required}
            label={label}
            type={type}
            borderless={borderless}
            underlined={underlined}
            multiline={multiline}
            rows={multiline ? rows : 1}
            placeholder={placeholder}
            className="w-full"
            inputClassName="w-full"
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            name={fieldName}
            defaultValue={undefined}
            accept={accept}
            errorMessage={error?.message}
          />
        )
      }
    />
  );
};

export default InputField;
