/* eslint-disable react/forbid-dom-props */
import { Auth } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import Flex from 'components/Flex';
import { NavBarProfilePictureCard } from 'components/ProfileCard';
import Text from 'components/Text';

import { currentUserDetailsAtom } from 'atoms/modals';

import { SIGN_OUT } from 'consts/labels';

const ProfileCard = ({
  setIsShowProfileCard,
}: {
  setIsShowProfileCard: (bool: boolean) => void;
}) => {
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);

  const deleteAllCookies = () => {
    const redirectUri = `${window.location.origin}/`;
    const clientId = import.meta.env.VITE_APP_aws_user_pools_web_client_id;

    window.location.replace(
      `https://${
        import.meta.env.VITE_APP_aws_domain
      }/logout?client_id=${clientId}&logout_uri=${redirectUri}`,
    );
  };

  return (
    <Flex
      direction="col"
      width="w-96"
      backgroundColor="bg-white"
      className="absolute right-84 top-5 shadow pl-4 pb-4"
    >
      <Flex justify="between">
        {currentUserDetails && (
          <Text className="mx-5 my-2" variant="medium">
            {currentUserDetails.organization?.name ?? ''}
          </Text>
        )}
        <Button
          className="hover:bg-gray-200 h-10 text-blue-400 font-normal"
          variant="default"
          onClick={async () => {
            localStorage.clear();
            sessionStorage.clear(); // IMPORTANT: Clearing session storage is extremely important for microsoft oauth.

            deleteAllCookies();

            try {
              await Auth.signOut();
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {SIGN_OUT}
        </Button>
      </Flex>
      <Flex alignItems="center" justify="between">
        <div
          className="w-22 h-22 mx-4 my-3 rounded-full"
          style={{
            border: '1px solid #777777',
          }}
        >
          {currentUserDetails && (
            <NavBarProfilePictureCard
              isShowProfileCard={() => {}}
              setIsShowProfileCard={() => {}}
              userDetails={currentUserDetails}
            />
          )}
        </div>
        <Flex direction="col" width="w-2/3">
          {currentUserDetails && (
            <Text
              className="mt-2 text-black font-semibold text-base lg:text-xl"
              textCase="capitalize"
              position="left"
            >
              {currentUserDetails.firstName && currentUserDetails.lastName
                ? `${currentUserDetails.firstName} ${currentUserDetails.lastName}`
                : ''}
            </Text>
          )}
          {currentUserDetails && (
            <Text variant="medium" className="text-gray-500 mb-2">
              {currentUserDetails.email ?? ''}
            </Text>
          )}

          <Flex justify="between">
            <Link
              onClick={() => setIsShowProfileCard(false)}
              className="text-blue-400 capitalize"
              to="/profile"
            >
              My Profile
            </Link>
            <Text className="mr-4 text-xl text-gray-400">...</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProfileCard;
