import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { isTenantAdminAtom } from 'atoms/modals';

import useGetCurrentUserAccess from 'hooks/useGetCurrentUserAccess';

const useIsContentReader = () => {
  const isTenantAdmin = useAtomValue(isTenantAdminAtom);

  const { hasSomeReaderAccess, hasSomeCreatorAccess, hasSomeApproverAccess } =
    useGetCurrentUserAccess({});

  const res = useMemo(() => {
    if (
      hasSomeReaderAccess &&
      !(hasSomeCreatorAccess || hasSomeApproverAccess || isTenantAdmin)
    ) {
      return { isContentReader: true };
    }

    return { isContentReader: false };
  }, [
    hasSomeApproverAccess,
    hasSomeCreatorAccess,
    hasSomeReaderAccess,
    isTenantAdmin,
  ]);

  return res;
};

export default useIsContentReader;
