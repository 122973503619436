import { useMutation } from '@tanstack/react-query';
import gql from 'graphql-tag';

import {
  CreateTaskInput,
  CustomCreateTaskMutation,
  CustomUpdateTaskMutation,
  UpdateTaskInput,
} from 'API';

import { customCreateTask, customUpdateTask } from 'graphql/custom-mutations';

import client from 'helpers/utils/client';

export const createTaskRequest = (payload: CreateTaskInput) => {
  const input: CreateTaskInput = {
    name: payload.name,
    description: payload.description,
    status: payload.status,
    requestedByUserId: payload.requestedByUserId,
    rootChapterId: payload.rootChapterId,
    rootChapterVersion: payload.rootChapterVersion,
    productDocId: payload.productDocId,
    type: payload.type,
    issueCategory: payload.issueCategory,
    subCategory: payload.subCategory,
    productVersion: payload.productVersion,
    severity: payload.severity,
    severityUpdatedAt: payload.severityUpdatedAt,
    organizationId: payload.organizationId,
    ticketRef: payload.ticketRef,
    usersToNotify: payload.usersToNotify,
    linkedTickets: payload.linkedTickets,
    hasSensitiveInfo: payload.hasSensitiveInfo,
    isSupportEngineerIdentityHidden: payload.isSupportEngineerIdentityHidden,
    tenantId: payload.tenantId,
  };

  if (payload.id) {
    input.id = payload.id;
  }

  if (payload.isRejected !== null) {
    input.isRejected = payload.isRejected;
  }

  return client.mutate<CustomCreateTaskMutation>({
    mutation: gql`
      ${customCreateTask}
    `,
    variables: {
      input,
    },
  });
};

export const updateTaskRequest = (payload: UpdateTaskInput) => {
  const input: UpdateTaskInput = {
    id: payload.id,
    name: payload.name,
    description: payload.description,
    status: payload.status,
    requestedByUserId: payload.requestedByUserId,
    rootChapterId: payload.rootChapterId,
    rootChapterVersion: payload.rootChapterVersion,
    type: payload.type,
    issueCategory: payload.issueCategory,
    subCategory: payload.subCategory,
    productVersion: payload.productVersion,
    severity: payload.severity,
    severityUpdatedAt: payload.severityUpdatedAt,
    organizationId: payload.organizationId,
    ticketRef: payload.ticketRef,
    closureReason: payload.closureReason,
    linkedTickets: payload.linkedTickets,
    hasSensitiveInfo: payload.hasSensitiveInfo,
    requestForInformation: payload.requestForInformation,
    isSupportEngineerIdentityHidden: payload.isSupportEngineerIdentityHidden,
    issueType: payload.issueType,
  };

  if (payload.isRejected !== null) {
    input.isRejected = payload.isRejected;
  }

  return client.mutate<CustomUpdateTaskMutation>({
    mutation: gql`
      ${customUpdateTask}
    `,
    variables: {
      input,
    },
  });
};

export const useCreateTask = () =>
  useMutation({
    mutationFn: createTaskRequest,
  });

export const useUpdateTask = () =>
  useMutation({ mutationFn: updateTaskRequest });
