import { useMemo } from 'react';

import { Lead, LeadOverallStatusType } from 'API';

export const useGetStatistics = ({ leads }: { leads: Partial<Lead>[] }) => {
  const { statistics } = useMemo(() => {
    const defaultLeadStatistics = {
      lead: 0,
      qualified: 0,
      approved: 0,
    };

    const leadStatistics = leads.reduce((acc, curr) => {
      if (curr.overallStatus === null) {
        const newAcc = {
          ...acc,
          lead: acc.lead + 1,
        };

        return newAcc;
      }

      if (curr.overallStatus === LeadOverallStatusType.QUALIFIED) {
        const newAcc = {
          ...acc,
          qualified: acc.qualified + 1,
        };

        return newAcc;
      }

      if (curr.overallStatus === LeadOverallStatusType.APPROVED) {
        const newAcc = {
          ...acc,
          approved: acc.approved + 1,
        };

        return newAcc;
      }

      return { ...acc };
    }, defaultLeadStatistics);

    return { statistics: leadStatistics };
  }, [leads]);

  return { statistics };
};

export default useGetStatistics;
