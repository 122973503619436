import { useMemo } from 'react';

import type { ProductDoc } from 'API';

import useProductDoc from 'queries/documents.query';

import useTransformRootChapters from './useTransformRootChapters';
import { useInfiniteChapters } from './useChapters';

export type IUseRootChaptersPageProps = {
  productDocId?: string | null;
};

const useRootChaptersPage = ({ productDocId }: IUseRootChaptersPageProps) => {
  const result = useProductDoc({
    productDocId: productDocId ?? '',
    enabled: !!productDocId,
  });

  const productDoc = useMemo(
    () => result.data?.data?.getProductDoc,
    [result.data?.data?.getProductDoc],
  ) as Partial<ProductDoc> | null;

  const { chaptersSource } = useInfiniteChapters({
    filter: {
      parentProductDocId: {
        eq: productDocId ?? '',
      },
    },
    enabled: !!productDocId,
  });

  const { filteredRootChapters, rootChaptersMap } = useTransformRootChapters({
    rootChapters: chaptersSource,
  });

  return {
    ...result,
    productDoc,
    rootChapters: chaptersSource,
    filteredRootChapters,
    rootChaptersMap,
  };
};

export default useRootChaptersPage;
