import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './styles/index.css';

const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_APP_azure_client_id,
    authority: 'https://login.microsoftonline.com/common/',
    redirectUri: `${window.location.origin}/`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

const renderApp = () =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <FluentProvider theme={webLightTheme}>
          <App />
        </FluentProvider>
      </MsalProvider>
    </React.StrictMode>,
    document.querySelector('#root'),
  );

renderApp();
