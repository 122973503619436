import { useEffect } from 'react';

const useAutoUnCollapseNestedSideBar = ({
  breadCrumbString,
  setActiveMenus,
  contentId,
  subChapterId,
}: {
  breadCrumbString: string;
  setActiveMenus: React.Dispatch<React.SetStateAction<string[]>>;
  contentId: string;
  subChapterId: string;
}) => {
  useEffect(() => {
    if (breadCrumbString !== '' && (subChapterId !== '' || contentId !== '')) {
      /*
        Notes:
        
        1. "breadCrumbString" is a string in which the substrings (chapters) are separated by [#!~*^&NESTED----CHAP&^*~!#]
          - The substrings (chapters) themselves are of the format `chapterName[#!NAME-ID!#]chapterId (or) docTitle[#!NAME-ID!#]docId.
          - The part before [#!NAME-ID!#] is the chapter "Name". The part after [#!NAME-ID!#] is the chapter "id".
        
        2. The part of the string before the [#!~*^&NESTED----CHAP&^*~!#] is the parentChapter of the part of the string that is after the [#!~*^&NESTED----CHAP&^*~!#]
        
        3. This pattern repeats until we get the current subChapter (whose id === subChapterId) or the current doc (whose id ==== contentId)
          
          - Examples 
            - `chapter1Name[#!NAME-ID!#]Chapter1Id[#!~*^&NESTED----CHAP&^*~!#]chapter2Name[#!NAME-ID!#]chapter2Id[#!~*^&NESTED----CHAP&^*~!#]chapter3Name[#!NAME-ID!#]Chapter3Id
            - `chapter1Name[#!NAME-ID!#]Chapter1Id[#!~*^&NESTED----CHAP&^*~!#]chapter2Name[#!NAME-ID!#]chapter2Id[#!~*^&NESTED----CHAP&^*~!#]docTitle[#!NAME-ID!#]docId
  
            - Here:
            - In example 1, chapter 3 (let' say, whose id === subChapterId) is the subChapter of chapter2 which is itself a subChapter of chapter1 and so on.
            - In example 2, doc (let' say whose id ==== contentId) exists under chapter2 which is a subChapter of chapter 1 and so on.
        
        4. So, When we split the "breadCrumbString" by [#!~*^&NESTED----CHAP&^*~!#], it will give us in order, all the top level parent chapters of the current subChapter or the current doc.
        
        5. Array.slice(2, -1) is done:
          - To not add the parentProduct of the rootChapter to the "activeMenus. So, we ignore Array[0] which will be the parentProduct of the rootChapter."
          - To not add the rootChapter id to the "activeMenus" We ignore Array[1] which will be the rootChapter.
          - Finally, To not add the current subChapter (subChapterId) itself or the current doc (contentId) itself to the "activeMenus". We ignore Array[Array.length - 1] which will be the required subChapter / doc.
        
        6. The "activeMenus" only needs the parentChapters of the current subChapter / doc.
        
        7. This is so we can un-collapse the nested sidebar automatically to reveal the current subChapter (subChapterId) or the current doc (contentId).
      */

      const crumbs = breadCrumbString
        .split('[#!~*^&NESTED----CHAP&^*~!#]')
        .slice(2, -1);

      setActiveMenus((oldActiveMenus: string[]) => [
        ...oldActiveMenus,
        ...crumbs
          .map((c) => c.split('[#!NAME-ID!#]')[2]) // The format is chapterName[#!NAME-ID!#]ChapterId (or) docName[#!NAME-ID!#]docId. So when doing split('[#!NAME-ID!#]'), item 1 in resulting array is the chapter "Name", item 2 is the chapter "id".
          .filter((f) => !oldActiveMenus.includes(f)), // Filtering is important here, so we don't add the "ids" if they already exist in the "activeMenus".
      ]);
    }
  }, [breadCrumbString, contentId, subChapterId, setActiveMenus]);
};

export default useAutoUnCollapseNestedSideBar;
