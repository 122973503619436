import type { UnknownRecord } from 'type-fest';

export const sortList = <T>(list: T[]) => {
  const sortedList = [...list].sort((left, right) => {
    const leftCreatedAt = (left as UnknownRecord).createdAt;
    const rightCreatedAt = (right as UnknownRecord).createdAt;

    return (
      (rightCreatedAt ? +new Date(rightCreatedAt as string) : 0) -
      (leftCreatedAt ? +new Date(leftCreatedAt as string) : 0)
    );
  });

  return sortedList;
};

export const sortListReverse = <T>(list: T[]) => {
  const sortedList = [...list].sort((left, right) => {
    const leftCreatedAt = (left as UnknownRecord).createdAt;
    const rightCreatedAt = (right as UnknownRecord).createdAt;

    return (
      (leftCreatedAt ? +new Date(leftCreatedAt as string) : 0) -
      (rightCreatedAt ? +new Date(rightCreatedAt as string) : 0)
    );
  });

  return sortedList;
};

export const sortListByStatus = <T>(list: T[], status: Record<any, any>) => {
  const sortedList = [...list].sort((left, right) => {
    const leftStatus = (left as UnknownRecord).status;
    const rightStatus = (right as UnknownRecord).status;

    return (
      (leftStatus ? status[leftStatus as string] : 0) -
      (rightStatus ? status[rightStatus as string] : 0)
    );
  });

  return sortedList;
};
