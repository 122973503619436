import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { v5 as uuidv5 } from 'uuid';

import type { UserType } from 'types/user';

import { DEFAULT_NAMESPACE_USER_ID } from 'consts/values';

const useGetCurrentAuthenticatedUser = () => {
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result) => {
        const {
          signInUserSession: {
            idToken: { payload },
          },
        } = result;
        const userAttributes = result?.attributes;
        const userCustomEmail = userAttributes['custom:email'] || '';
        const EMAIL_UUID = uuidv5(userCustomEmail, DEFAULT_NAMESPACE_USER_ID);
        const payloadwithId = {
          ...payload,
          sub: EMAIL_UUID,
          email: userAttributes?.email,
          customEmail: userCustomEmail,
        };
        const attributes = { ...result?.attributes, sub: EMAIL_UUID };
        setUser({ ...payloadwithId, attributes });
      })
      .catch((error) => {
        console.error('error', error);

        setUser(null);
      });
  }, []);

  return user;
};

export const isCurrentUserAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();

    // throw new Error('Test'); // To test Auth.currentSession();

    return true;
  } catch {
    try {
      // This method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented
      await Auth.currentSession();

      return true;
    } catch (error) {
      console.error('isCurrentUserAuthenticated - currentSession error', error);

      return false;
    }
  }
};

export default useGetCurrentAuthenticatedUser;
