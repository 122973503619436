import { StatusType } from 'API';

export const INITIAL_DOC_SETTINGS: {
  version: string;
  status: StatusType;
} = {
  version: '1',
  status: StatusType.PENDING,
};

export default INITIAL_DOC_SETTINGS;
