import MDEditor from '@uiw/react-md-editor';
import markdownit from 'markdown-it';
import { useEffect, useState } from 'react';

import { DocItem, StatusType } from 'API';

import { DocItemPageType } from 'types/documents';

import { DIFF_COLOR } from 'consts/diffConstants';

import useIsSectionVisible from 'hooks/useDocumentPage/useIsSectionVisible';
import useIsContentReader from 'hooks/useIsContentReader';

import markdownitIns from 'markdown-it-ins';

import DocItemModifier from './DocItemModifier';
import './markdown.css';
import { Panel, PanelType } from '@fluentui/react';

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
}).use(markdownitIns);

const Markdown = ({
  doc,
  docItem,
  documentsMap,
  rootChapter,
  allRootChapterVersions = [],
  isAnyAncestorDeleted,
  setMessage,
}: DocItemPageType) => {
  const [isHoveringDocItem, setIsHoveringDocItem] = useState(false);
  const { isContentReader } = useIsContentReader();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [clickedImageSrc, setClickedImageSrc] = useState('');

  const { sectionRef } = useIsSectionVisible();

  let contentStyle: React.CSSProperties = {
    color: '#171717',
  };

  if (
    !isContentReader &&
    (docItem.status === StatusType.PENDING || isAnyAncestorDeleted)
  ) {
    contentStyle =
      isAnyAncestorDeleted || docItem.shouldBeDeleted === true
        ? {
            ...contentStyle,
            color: DIFF_COLOR,
            textDecoration: 'line-through',
          }
        : {
            ...contentStyle,
            color: DIFF_COLOR,
          };
  }

  const showSideBySideDiffView =
    !isContentReader &&
    docItem.status === StatusType.PENDING &&
    !isAnyAncestorDeleted &&
    !docItem.shouldBeDeleted;

  const publishedDocItemVersion = (documentsMap[docItem.id]?.find(
    (x) => x.status === StatusType.PUBLISHED,
  ) ?? null) as DocItem | null;

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks
  useEffect(() => {
    const olElements = document.querySelectorAll('.markdown-reader ol');
    olElements.forEach((ol) => {
      const startValue = ol.getAttribute('start');
      if (startValue) {
        // disable ESLint for the next line only
        // eslint-disable-next-line no-param-reassign
        (ol as HTMLElement).style.counterReset = `item ${(
          +startValue - 1
        ).toString()}`;
      }
    });
  }, []);
  useEffect(() => {
    const container = document.getElementById(docItem.id); // You are using id={docItem.id} for the container div
    if (container) {
      const images = container.getElementsByTagName('img');
      const onImageClick = (e: any) => {
        setClickedImageSrc(e.target.src);
        setIsPanelOpen(true);
      };
      for (const img of images) {
        img.addEventListener('click', onImageClick);
      }
      // Cleanup
      return () => {
        for (const img of images) {
          img.removeEventListener('click', onImageClick);
        }
      };
    }
  }, [docItem.id]);

  return (
    <div
      id={docItem.id}
      ref={sectionRef}
      onMouseEnter={() => {
        setIsHoveringDocItem(true);
      }}
      onMouseLeave={() => {
        setIsHoveringDocItem(false);
      }}
      className="flex relative w-full  overflow-x-auto"
    >
      <div
        className="lg:prose xl:prose-3xl w-full min-w-full max-w-none not-prose"
        // eslint-disable-next-line react/forbid-dom-props
      >
        {showSideBySideDiffView && publishedDocItemVersion && (
          <div>
            <MDEditor.Markdown
              style={{ color: 'rgba(239, 68, 68)' }}
              className="markdown-reader font-microsoft-docs text-gray-microsoft-docs leading-6.25 antialiased break-words tracking-normal my-4"
              source={md.render(publishedDocItemVersion.description ?? '')}
            />
          </div>
        )}

        <MDEditor.Markdown
          style={contentStyle}
          className="markdown-reader font-microsoft-docs text-gray-microsoft-docs leading-6.25 antialiased break-words tracking-normal my-4"
          source={md.render(docItem.description)}
          // source={docItem.description}
        />
      </div>

      <div className="flex justify-center absolute top-4 right-10">
        {isHoveringDocItem && rootChapter?.status !== StatusType.ARCHIVED && (
          <DocItemModifier
            doc={doc}
            docItem={docItem}
            documentsMap={documentsMap}
            rootChapter={rootChapter}
            allRootChapterVersions={allRootChapterVersions}
            isAnyAncestorDeleted={isAnyAncestorDeleted}
            setMessage={setMessage}
          />
        )}
      </div>

      <Panel
        isOpen={isPanelOpen}
        onDismiss={() => setIsPanelOpen(false)}
        type={PanelType.custom}
        customWidth="100vw"
        styles={(props) => ({
          main: {
            height: '100vh',
          },
          content: {
            height: '100%',
          },
          scrollableContent: {
            height: '100%',
            overflowY: 'hidden',
          },
        })}
      >
        <img
          src={clickedImageSrc}
          alt="Clicked"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            display: 'block',
          }}
        />
      </Panel>
    </div>
  );
};

export default Markdown;
