export const customCreateSFAccount = /* GraphQL */ `
  mutation CustomCreateSFAccount(
    $input: CreateSFAccountInput!
    $condition: ModelSFAccountConditionInput
  ) {
    createSFAccount(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFAccount = /* GraphQL */ `
  mutation CustomUpdateSFAccount(
    $input: UpdateSFAccountInput!
    $condition: ModelSFAccountConditionInput
  ) {
    updateSFAccount(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFAccount = /* GraphQL */ `
  mutation CustomDeleteSFAccount(
    $input: DeleteSFAccountInput!
    $condition: ModelSFAccountConditionInput
  ) {
    deleteSFAccount(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFContract = /* GraphQL */ `
  mutation CustomCreateSFContract(
    $input: CreateSFContractInput!
    $condition: ModelSFContractConditionInput
  ) {
    createSFContract(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFContract = /* GraphQL */ `
  mutation CustomUpdateSFContract(
    $input: UpdateSFContractInput!
    $condition: ModelSFContractConditionInput
  ) {
    updateSFContract(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFContract = /* GraphQL */ `
  mutation CustomDeleteSFContract(
    $input: DeleteSFContractInput!
    $condition: ModelSFContractConditionInput
  ) {
    deleteSFContract(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFContractLineItem = /* GraphQL */ `
  mutation CustomCreateSFContractLineItem(
    $input: CreateSFContractLineItemInput!
    $condition: ModelSFContractLineItemConditionInput
  ) {
    createSFContractLineItem(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFContractLineItem = /* GraphQL */ `
  mutation CustomUpdateSFContractLineItem(
    $input: UpdateSFContractLineItemInput!
    $condition: ModelSFContractLineItemConditionInput
  ) {
    updateSFContractLineItem(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFContractLineItem = /* GraphQL */ `
  mutation CustomDeleteSFContractLineItem(
    $input: DeleteSFContractLineItemInput!
    $condition: ModelSFContractLineItemConditionInput
  ) {
    deleteSFContractLineItem(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFContact = /* GraphQL */ `
  mutation CustomCreateSFContact(
    $input: CreateSFContactInput!
    $condition: ModelSFContactConditionInput
  ) {
    createSFContact(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFContact = /* GraphQL */ `
  mutation CustomUpdateSFContact(
    $input: UpdateSFContactInput!
    $condition: ModelSFContactConditionInput
  ) {
    updateSFContact(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFContact = /* GraphQL */ `
  mutation CustomDeleteSFContact(
    $input: DeleteSFContactInput!
    $condition: ModelSFContactConditionInput
  ) {
    deleteSFContact(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFProduct = /* GraphQL */ `
  mutation CustomCreateSFProduct(
    $input: CreateSFProductInput!
    $condition: ModelSFProductConditionInput
  ) {
    createSFProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFProduct = /* GraphQL */ `
  mutation CustomUpdateSFProduct(
    $input: UpdateSFProductInput!
    $condition: ModelSFProductConditionInput
  ) {
    updateSFProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFProduct = /* GraphQL */ `
  mutation CustomDeleteSFProduct(
    $input: DeleteSFProductInput!
    $condition: ModelSFProductConditionInput
  ) {
    deleteSFProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFProductSubCategory = /* GraphQL */ `
  mutation CustomCreateSFProductSubCategory(
    $input: CreateSFProductSubCategoryInput!
    $condition: ModelSFProductSubCategoryConditionInput
  ) {
    createSFProductSubCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFProductSubCategory = /* GraphQL */ `
  mutation CustomUpdateSFProductSubCategory(
    $input: UpdateSFProductSubCategoryInput!
    $condition: ModelSFProductSubCategoryConditionInput
  ) {
    updateSFProductSubCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFProductSubCategory = /* GraphQL */ `
  mutation CustomDeleteSFProductSubCategory(
    $input: DeleteSFProductSubCategoryInput!
    $condition: ModelSFProductSubCategoryConditionInput
  ) {
    deleteSFProductSubCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFProductVersion = /* GraphQL */ `
  mutation CustomCreateSFProductVersion(
    $input: CreateSFProductVersionInput!
    $condition: ModelSFProductVersionConditionInput
  ) {
    createSFProductVersion(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFProductVersion = /* GraphQL */ `
  mutation CustomUpdateSFProductVersion(
    $input: UpdateSFProductVersionInput!
    $condition: ModelSFProductVersionConditionInput
  ) {
    updateSFProductVersion(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFProductVersion = /* GraphQL */ `
  mutation CustomDeleteSFProductVersion(
    $input: DeleteSFProductVersionInput!
    $condition: ModelSFProductVersionConditionInput
  ) {
    deleteSFProductVersion(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFIndustry = /* GraphQL */ `
  mutation CustomCreateSFIndustry(
    $input: CreateSFIndustryInput!
    $condition: ModelSFIndustryConditionInput
  ) {
    createSFIndustry(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFIndustry = /* GraphQL */ `
  mutation CustomUpdateSFIndustry(
    $input: UpdateSFIndustryInput!
    $condition: ModelSFIndustryConditionInput
  ) {
    updateSFIndustry(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFIndustry = /* GraphQL */ `
  mutation CustomDeleteSFIndustry(
    $input: DeleteSFIndustryInput!
    $condition: ModelSFIndustryConditionInput
  ) {
    deleteSFIndustry(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFIssueCategory = /* GraphQL */ `
  mutation CustomCreateSFIssueCategory(
    $input: CreateSFIssueCategoryInput!
    $condition: ModelSFIssueCategoryConditionInput
  ) {
    createSFIssueCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFIssueCategory = /* GraphQL */ `
  mutation CustomUpdateSFIssueCategory(
    $input: UpdateSFIssueCategoryInput!
    $condition: ModelSFIssueCategoryConditionInput
  ) {
    updateSFIssueCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFIssueCategory = /* GraphQL */ `
  mutation CustomDeleteSFIssueCategory(
    $input: DeleteSFIssueCategoryInput!
    $condition: ModelSFIssueCategoryConditionInput
  ) {
    deleteSFIssueCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFCaseSeverity = /* GraphQL */ `
  mutation CustomCreateSFCaseSeverity(
    $input: CreateSFCaseSeverityInput!
    $condition: ModelSFCaseSeverityConditionInput
  ) {
    createSFCaseSeverity(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFCaseSeverity = /* GraphQL */ `
  mutation CustomUpdateSFCaseSeverity(
    $input: UpdateSFCaseSeverityInput!
    $condition: ModelSFCaseSeverityConditionInput
  ) {
    updateSFCaseSeverity(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFCaseSeverity = /* GraphQL */ `
  mutation CustomDeleteSFCaseSeverity(
    $input: DeleteSFCaseSeverityInput!
    $condition: ModelSFCaseSeverityConditionInput
  ) {
    deleteSFCaseSeverity(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFCountry = /* GraphQL */ `
  mutation CustomCreateSFCountry(
    $input: CreateSFCountryInput!
    $condition: ModelSFCountryConditionInput
  ) {
    createSFCountry(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFCountry = /* GraphQL */ `
  mutation CustomUpdateSFCountry(
    $input: UpdateSFCountryInput!
    $condition: ModelSFCountryConditionInput
  ) {
    updateSFCountry(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFCountry = /* GraphQL */ `
  mutation CustomDeleteSFCountry(
    $input: DeleteSFCountryInput!
    $condition: ModelSFCountryConditionInput
  ) {
    deleteSFCountry(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFRole = /* GraphQL */ `
  mutation CustomCreateSFRole(
    $input: CreateSFRoleInput!
    $condition: ModelSFRoleConditionInput
  ) {
    createSFRole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFRole = /* GraphQL */ `
  mutation CustomUpdateSFRole(
    $input: UpdateSFRoleInput!
    $condition: ModelSFRoleConditionInput
  ) {
    updateSFRole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFRole = /* GraphQL */ `
  mutation CustomDeleteSFRole(
    $input: DeleteSFRoleInput!
    $condition: ModelSFRoleConditionInput
  ) {
    deleteSFRole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFSolution = /* GraphQL */ `
  mutation CustomCreateSFSolution(
    $input: CreateSFSolutionInput!
    $condition: ModelSFSolutionConditionInput
  ) {
    createSFSolution(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFSolution = /* GraphQL */ `
  mutation CustomUpdateSFSolution(
    $input: UpdateSFSolutionInput!
    $condition: ModelSFSolutionConditionInput
  ) {
    updateSFSolution(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFSolution = /* GraphQL */ `
  mutation CustomDeleteSFSolution(
    $input: DeleteSFSolutionInput!
    $condition: ModelSFSolutionConditionInput
  ) {
    deleteSFSolution(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateSFClosureReason = /* GraphQL */ `
  mutation CustomCreateSFClosureReason(
    $input: CreateSFClosureReasonInput!
    $condition: ModelSFClosureReasonConditionInput
  ) {
    createSFClosureReason(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateSFClosureReason = /* GraphQL */ `
  mutation CustomUpdateSFClosureReason(
    $input: UpdateSFClosureReasonInput!
    $condition: ModelSFClosureReasonConditionInput
  ) {
    updateSFClosureReason(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteSFClosureReason = /* GraphQL */ `
  mutation CustomDeleteSFClosureReason(
    $input: DeleteSFClosureReasonInput!
    $condition: ModelSFClosureReasonConditionInput
  ) {
    deleteSFClosureReason(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateApplication = /* GraphQL */ `
  mutation CustomCreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateApplication = /* GraphQL */ `
  mutation CustomUpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteApplication = /* GraphQL */ `
  mutation CustomDeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateApplicationRole = /* GraphQL */ `
  mutation CustomCreateApplicationRole(
    $input: CreateApplicationRoleInput!
    $condition: ModelApplicationRoleConditionInput
  ) {
    createApplicationRole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateApplicationRole = /* GraphQL */ `
  mutation CustomUpdateApplicationRole(
    $input: UpdateApplicationRoleInput!
    $condition: ModelApplicationRoleConditionInput
  ) {
    updateApplicationRole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteApplicationRole = /* GraphQL */ `
  mutation CustomDeleteApplicationRole(
    $input: DeleteApplicationRoleInput!
    $condition: ModelApplicationRoleConditionInput
  ) {
    deleteApplicationRole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateFileList = /* GraphQL */ `
  mutation CustomCreateFileList(
    $input: CreateFileListInput!
    $condition: ModelFileListConditionInput
  ) {
    createFileList(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateFileList = /* GraphQL */ `
  mutation CustomUpdateFileList(
    $input: UpdateFileListInput!
    $condition: ModelFileListConditionInput
  ) {
    updateFileList(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteFileList = /* GraphQL */ `
  mutation CustomDeleteFileList(
    $input: DeleteFileListInput!
    $condition: ModelFileListConditionInput
  ) {
    deleteFileList(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateGroup = /* GraphQL */ `
  mutation CustomCreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateGroup = /* GraphQL */ `
  mutation CustomUpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteGroup = /* GraphQL */ `
  mutation CustomDeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateUserGroup = /* GraphQL */ `
  mutation CustomCreateUserGroup(
    $input: CreateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    createUserGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateUserGroup = /* GraphQL */ `
  mutation CustomUpdateUserGroup(
    $input: UpdateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    updateUserGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteUserGroup = /* GraphQL */ `
  mutation CustomDeleteUserGroup(
    $input: DeleteUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    deleteUserGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateWatcherTask = /* GraphQL */ `
  mutation CustomCreateWatcherTask(
    $input: CreateWatcherTaskInput!
    $condition: ModelWatcherTaskConditionInput
  ) {
    createWatcherTask(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateWatcherTask = /* GraphQL */ `
  mutation CustomUpdateWatcherTask(
    $input: UpdateWatcherTaskInput!
    $condition: ModelWatcherTaskConditionInput
  ) {
    updateWatcherTask(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteWatcherTask = /* GraphQL */ `
  mutation CustomDeleteWatcherTask(
    $input: DeleteWatcherTaskInput!
    $condition: ModelWatcherTaskConditionInput
  ) {
    deleteWatcherTask(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateTask = /* GraphQL */ `
  mutation CustomCreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      name
      description
      salesForceTicketId
      requestedByUserId
      requestedByUser {
        id
        userType
        firstName
        lastName
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      reviewedByUserId
      reviewedByUser {
        id
        userType
        firstName
        lastName
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      status
      comments {
        items {
          id
          comment
          commentedByUserId
          commentedByUser {
            id
            userType
            firstName
            lastName
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            status
            createdAt
            updatedAt
          }
          parentCommentId
          parentComment {
            id
            comment
            commentedByUserId
            parentCommentId
            taskId
            createdAt
            updatedAt
          }
          replies {
            nextToken
          }
          taskId
          task {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            productDocId
            createdAt
            updatedAt
            organizationId
            severity
            issueCategory
            subCategory
            type
            ticketRef
            closureReason
            linkedTickets
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rootChapterId
      organizationId
      ticketRef
      severity
      severityUpdatedAt
      issueCategory
      subCategory
      productVersion
      closureReason
      type
      file {
        items {
          id
          file {
            bucket
            region
            key
          }
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      rootChapterVersion
      productDocId
      isRejected
      usersToNotify
      ticketWatchers {
        items {
          id
          userId
          taskId
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketAssignedUsers {
        items {
          id
          userId
          taskId
          status
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketAssignedGroups {
        items {
          id
          groupId
          taskId
          status
          groups {
            id
            name
            description
            status
            organizationId
            createdAt
            updatedAt
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      linkedTickets
      createdAt
      updatedAt
      hasSensitiveInfo
      isSupportEngineerIdentityHidden
    }
  }
`;

export const customUpdateTask = /* GraphQL */ `
  mutation CustomUpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      name
      description
      salesForceTicketId
      requestForInformation
      requestedByUserId
      issueType
      requestedByUser {
        id
        userType
        firstName
        lastName
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      reviewedByUserId
      reviewedByUser {
        id
        userType
        firstName
        lastName
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      status
      comments {
        items {
          id
          comment
          requestForInformation
          commentedByUserId
          commentedByUser {
            id
            userType
            firstName
            lastName
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            status
            createdAt
            updatedAt
          }
          parentCommentId
          parentComment {
            id
            comment
            commentedByUserId
            parentCommentId
            taskId
            createdAt
            updatedAt
          }
          replies {
            nextToken
          }
          taskId
          task {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            productDocId
            createdAt
            updatedAt
            organizationId
            severity
            issueCategory
            subCategory
            type
            ticketRef
            closureReason
            linkedTickets
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rootChapterId
      organizationId
      ticketRef
      severity
      severityUpdatedAt
      issueCategory
      subCategory
      productVersion
      closureReason
      type
      file {
        items {
          id
          file {
            bucket
            region
            key
          }
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      rootChapterVersion
      productDocId
      isRejected
      usersToNotify
      ticketWatchers {
        items {
          id
          userId
          taskId
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketAssignedUsers {
        items {
          id
          userId
          taskId
          status
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketAssignedGroups {
        items {
          id
          groupId
          taskId
          status
          groups {
            id
            name
            description
            status
            organizationId
            createdAt
            updatedAt
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      linkedTickets
      createdAt
      updatedAt
      hasSensitiveInfo
      isSupportEngineerIdentityHidden
    }
  }
`;

export const customDeleteTask = /* GraphQL */ `
  mutation CustomDeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateChapter = /* GraphQL */ `
  mutation CustomCreateChapter(
    $input: CreateChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    createChapter(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customUpdateChapter = /* GraphQL */ `
  mutation CustomUpdateChapter(
    $input: UpdateChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    updateChapter(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customDeleteChapter = /* GraphQL */ `
  mutation CustomDeleteChapter(
    $input: DeleteChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    deleteChapter(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customCreateDoc = /* GraphQL */ `
  mutation CustomCreateDoc(
    $input: CreateDocInput!
    $condition: ModelDocConditionInput
  ) {
    createDoc(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customUpdateDoc = /* GraphQL */ `
  mutation CustomUpdateDoc(
    $input: UpdateDocInput!
    $condition: ModelDocConditionInput
  ) {
    updateDoc(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customDeleteDoc = /* GraphQL */ `
  mutation CustomDeleteDoc(
    $input: DeleteDocInput!
    $condition: ModelDocConditionInput
  ) {
    deleteDoc(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customCreateDocItem = /* GraphQL */ `
  mutation CustomCreateDocItem(
    $input: CreateDocItemInput!
    $condition: ModelDocItemConditionInput
  ) {
    createDocItem(input: $input, condition: $condition) {
      id
      version
      status
      title
      description
      orderFloat
      createdByUserId
      tenantId
      lastModifiedByUserId
      approvedByUserId
      rootChapterId
      createdAt
      docId
      itemType
      shouldBeDeleted
    }
    
  }
`;

export const customUpdateDocItem = /* GraphQL */ `
  mutation CustomUpdateDocItem(
    $input: UpdateDocItemInput!
    $condition: ModelDocItemConditionInput
  ) {
    updateDocItem(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customDeleteDocItem = /* GraphQL */ `
  mutation CustomDeleteDocItem(
    $input: DeleteDocItemInput!
    $condition: ModelDocItemConditionInput
  ) {
    deleteDocItem(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customCreateProductDoc = /* GraphQL */ `
  mutation CustomCreateProductDoc(
    $input: CreateProductDocInput!
    $condition: ModelProductDocConditionInput
  ) {
    createProductDoc(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customUpdateProductDoc = /* GraphQL */ `
  mutation CustomUpdateProductDoc(
    $input: UpdateProductDocInput!
    $condition: ModelProductDocConditionInput
  ) {
    updateProductDoc(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customDeleteProductDoc = /* GraphQL */ `
  mutation CustomDeleteProductDoc(
    $input: DeleteProductDocInput!
    $condition: ModelProductDocConditionInput
  ) {
    deleteProductDoc(input: $input, condition: $condition) {
      id
      version
    }
  }
`;

export const customCreateComment = /* GraphQL */ `
  mutation CustomCreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateComment = /* GraphQL */ `
  mutation CustomUpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteComment = /* GraphQL */ `
  mutation CustomDeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateUser = /* GraphQL */ `
  mutation CustomCreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateUser = /* GraphQL */ `
  mutation CustomUpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteUser = /* GraphQL */ `
  mutation CustomDeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
    }
  }
`;


export const customCreateLead = /* GraphQL */ `
  mutation CustomCreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      leadReferenceNumber
      firstName
      lastName
      email
      phoneNumber
      role
      jobTitle
      companyName
      companyAddress
      industry
      companyUrl
      leadName
      description
      timeline
      estimatedBudget
      estimatedNumberOfUsers
      solution
      incumbentProduct
      competitors
      submissionDate
      remarks
      createdByUserId
      lastModifiedByUserId
      qualifiedByUserId
      disqualifiedByUserId
      approvedByUserId
      rejectedByUserId
      usersToNotify
      overallStatus
      qualifiedStatus
      approvedStatus
      qualifiedDate
      approvedDate
      qualifiedRemarks
      approvedRemarks
      salesForceLeadId
      createdAt
      updatedAt
      createdByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      qualifiedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      disqualifiedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      approvedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      rejectedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      comments {
        items {
          id
          comment
          commentedByUserId
          parentCommentId
          taskId
          leadId
          file {
            bucket
            region
            key
          }
          attachments {
            bucket
            region
            key
          }
          hasSensitiveInfo
          type
          createdAt
          updatedAt
          commentedByUser {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
        }
        nextToken
      }
      attchments {
        items {
          id
          file {
            bucket
            region
            key
          }
          taskId
          leadId
          createdAt
          updatedAt
        }
        nextToken
      }
      leadWatchers {
        items {
          id
          userId
          leadId
          createdAt
          updatedAt
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          leads {
            id
            leadReferenceNumber
            firstName
            lastName
            email
            phoneNumber
            role
            jobTitle
            companyName
            companyAddress
            industry
            companyUrl
            leadName
            description
            timeline
            estimatedBudget
            estimatedNumberOfUsers
            solution
            incumbentProduct
            competitors
            submissionDate
            remarks
            createdByUserId
            lastModifiedByUserId
            qualifiedByUserId
            disqualifiedByUserId
            approvedByUserId
            rejectedByUserId
            usersToNotify
            overallStatus
            qualifiedStatus
            approvedStatus
            qualifiedDate
            approvedDate
            qualifiedRemarks
            approvedRemarks
            salesForceLeadId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const customUpdateLead = /* GraphQL */ `
  mutation CustomUpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      leadReferenceNumber
      firstName
      lastName
      email
      phoneNumber
      role
      jobTitle
      companyName
      companyAddress
      industry
      companyUrl
      leadName
      description
      timeline
      estimatedBudget
      estimatedNumberOfUsers
      solution
      incumbentProduct
      competitors
      submissionDate
      remarks
      createdByUserId
      lastModifiedByUserId
      qualifiedByUserId
      disqualifiedByUserId
      approvedByUserId
      rejectedByUserId
      usersToNotify
      overallStatus
      qualifiedStatus
      approvedStatus
      qualifiedDate
      approvedDate
      qualifiedRemarks
      approvedRemarks
      salesForceLeadId
      createdAt
      updatedAt
      createdByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      qualifiedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      disqualifiedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      approvedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      rejectedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      comments {
        items {
          id
          comment
          commentedByUserId
          parentCommentId
          taskId
          leadId
          file {
            bucket
            region
            key
          }
          attachments {
            bucket
            region
            key
          }
          hasSensitiveInfo
          type
          createdAt
          updatedAt
          commentedByUser {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
        }
        nextToken
      }
      attchments {
        items {
          id
          file {
            bucket
            region
            key
          }
          taskId
          leadId
          createdAt
          updatedAt
        }
        nextToken
      }
      leadWatchers {
        items {
          id
          userId
          leadId
          createdAt
          updatedAt
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          leads {
            id
            leadReferenceNumber
            firstName
            lastName
            email
            phoneNumber
            role
            jobTitle
            companyName
            companyAddress
            industry
            companyUrl
            leadName
            description
            timeline
            estimatedBudget
            estimatedNumberOfUsers
            solution
            incumbentProduct
            competitors
            submissionDate
            remarks
            createdByUserId
            lastModifiedByUserId
            qualifiedByUserId
            disqualifiedByUserId
            approvedByUserId
            rejectedByUserId
            usersToNotify
            overallStatus
            qualifiedStatus
            approvedStatus
            qualifiedDate
            approvedDate
            qualifiedRemarks
            approvedRemarks
            salesForceLeadId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const customDeleteLead = /* GraphQL */ `
  mutation CustomDeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateWatcherLead = /* GraphQL */ `
  mutation CustomCreateWatcherLead(
    $input: CreateWatcherLeadInput!
    $condition: ModelWatcherLeadConditionInput
  ) {
    createWatcherLead(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateWatcherLead = /* GraphQL */ `
  mutation CustomUpdateWatcherLead(
    $input: UpdateWatcherLeadInput!
    $condition: ModelWatcherLeadConditionInput
  ) {
    updateWatcherLead(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteWatcherLead = /* GraphQL */ `
  mutation CustomDeleteWatcherLead(
    $input: DeleteWatcherLeadInput!
    $condition: ModelWatcherLeadConditionInput
  ) {
    deleteWatcherLead(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateCurrency = /* GraphQL */ `
  mutation CustomCreateCurrency(
    $input: CreateCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    createCurrency(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateCurrency = /* GraphQL */ `
  mutation CustomUpdateCurrency(
    $input: UpdateCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    updateCurrency(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteCurrency = /* GraphQL */ `
  mutation CustomDeleteCurrency(
    $input: DeleteCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    deleteCurrency(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateTenant = /* GraphQL */ `
  mutation CustomCreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateTenant = /* GraphQL */ `
  mutation CustomUpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteTenant = /* GraphQL */ `
  mutation CustomDeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customAssignTicket = /* GraphQL */ `
  mutation CustomAssignTicket($input: AssignTicketInput) {
    assignTicket(input: $input) {
      data {
        statusCode
        message
        __typename
      }
      error
      __typename
    }
  }
`
export const customCreateTicketRule = /* GraphQL */ `
  mutation CustomCreateTicketRule(
    $input: CreateTicketRuleInput!
    $condition: ModelTicketRuleConditionInput
  ) {
    createTicketRule(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customUpdateTicketRule = /* GraphQL */ `
  mutation CustomUpdateTicketRule(
    $input: UpdateTicketRuleInput!
    $condition: ModelTicketRuleConditionInput
  ) {
    updateTicketRule(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customDeleteTicketRule = /* GraphQL */ `
  mutation CustomDeleteTicketRule(
    $input: DeleteTicketRuleInput!
    $condition: ModelTicketRuleConditionInput
  ) {
    deleteTicketRule(input: $input, condition: $condition) {
      id
    }
  }
`;

export const customCreateTicketAssignmentRule = /* GraphQL */ `
  mutation CustomCreateTicketAssignmentRule($input: CreateTicketAssignmentRuleInput) {
    createTicketAssignmentRule(input: $input) {
      data {
        statusCode
        message
        __typename
      }
      error
      __typename
    }
  }
`

export const customAssignUsersToGroup = /* GraphQL */ `
  mutation CustomAssignUsersToGroup($input: AssignUsersToGroupInput) {
    assignUsersToGroup(input: $input) {
      data {
        statusCode
        message
        __typename
      }
      error
      __typename
    }
  }
`

export const customAssignGroupsToUser = /* GraphQL */ `
  mutation CustomAssignGroupsToUser($input: AssignGroupsToUserInput) {
    assignGroupsToUser(input: $input) {
      data {
        statusCode
        message
        __typename
      }
      error
      __typename
    }
  }
`

export const customApproveDocuments = /* GraphQL */ `
  mutation CustomApproveDocuments($input: ApproveDocumentsInput) {
    approveDocuments(input: $input) {
      data {
        statusCode
        message
        __typename
      }
      error
      __typename
    }
  }
`
export const customDiscardDocuments = /* GraphQL */ `
  mutation CustomDiscardDocuments($input: DiscardDocumentsInput) {
    discardDocuments(input: $input) {
      data {
        statusCode
        message
        __typename
      }
      error
      __typename
    }
  }
`

export const customVerifyCompanyDetails = /* GraphQL */ `
  mutation CustomVerifyCompanyDetails($input: CompanyVerificationInput!) {
    verifyCompanyDetails(input: $input) {
      statusCode
      message
      __typename
    }
  }
`

export const customImportRootChapterFromExportedFile = /* GraphQL */ `
  mutation CustomImportRootChapterFromExportedFile($input: ImportDocumentInput!) {
    importRootChapterFromExportedFile(input: $input) {
      data {
        statusCode
        message
        __typename
      }
      error
      __typename
    }
  }
`

export const customSendMailNotification = /* GraphQL */ `
  mutation CustomSendMailNotification($input: SendMailNotificationInput) {
    sendMailNotification(input: $input) {
      data {
        statusCode
        message
        __typename
      }
      error
      __typename
    }
  }
`