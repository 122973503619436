import { QueryKey } from '@tanstack/react-query';
import isString from 'lodash/isString';

import { QueryKeys } from 'atoms/modals';

const getProductDocQueryKey = ({ id }: { id?: string | null }): QueryKey => {
  const defaultQueryKey = [QueryKeys.PRODUCT_DOC];

  if (isString(id)) {
    return [...defaultQueryKey, { id }, { version: '1' }];
  }

  return [...defaultQueryKey];
};

export default getProductDocQueryKey;
