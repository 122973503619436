import {
  Panel,
  Separator,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Flex from 'components/Flex';
import InputField from 'components/InputField';

import {
  CreateTaskInput,
  StatusType,
  Task,
  TaskStatusType,
  UpdateTaskInput,
} from 'API';

import type { AddDocumentsParams } from 'types/Pages/Products';

import {
  currentUserDetailsAtom,
  initialTaskAtomProps,
  taskEditAtom,
} from 'atoms/modals';

import falainaTenantId from 'consts/tenant';
import { ADD_TASK, UPDATE_TASK } from 'consts/ticket';
import { AS_NAME } from 'consts/validations';

import { useUpdateChapterMutation } from 'hooks/useChapterMutations';
import { isCurrentUserAuthenticated } from 'hooks/useGetCurrentAuthenticatedUser';
import {
  useCreateTaskMutation,
  useUpdateTaskMutation,
} from 'hooks/useTaskMutations';

import generateToast, {
  generateSessionTimeoutToast,
} from 'helpers/utils/generateToast';

import { Nullable } from 'types';

type TaskArgsType = {
  name: string;
  description: string;
};

type SubmitForApprovalFormType = {
  productDocId?: string | null;
};

const buttonStyles = { root: { marginRight: 8 } };

const useCloseTaskPanel = () => {
  const setTaskAtomValue = useSetAtom(taskEditAtom);

  const closePanel = useCallback(() => {
    setTaskAtomValue(initialTaskAtomProps);
  }, [setTaskAtomValue]);

  return closePanel;
};

const SubmitForApprovalForm = ({ productDocId }: SubmitForApprovalFormType) => {
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);
  const taskAtomValue = useAtomValue(taskEditAtom);

  const { action, task } = taskAtomValue;

  const rootChapterId = task?.rootChapterId;
  const rootChapterVersion = task?.rootChapterVersion;

  const closeSlider = useCloseTaskPanel();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const createTaskMutation = useCreateTaskMutation();
  const updateTaskMutation = useUpdateTaskMutation();

  const updateChapterMutation = useUpdateChapterMutation({
    onSettledCallback: () => {
      void queryClient.invalidateQueries([
        'rootChapter',
        { id: rootChapterId },
      ]);
      void queryClient.invalidateQueries([`subChapters/${rootChapterId}`]);
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<AddDocumentsParams>({
    defaultValues: {
      name: task?.name ?? '',
      description: task?.description ?? '',
    },
  });

  useEffect(() => {
    if (createTaskMutation.isSuccess || updateTaskMutation.isSuccess) {
      reset();
    }
  }, [createTaskMutation.isSuccess, reset, updateTaskMutation.isSuccess]);

  const addTask = useCallback(
    async (data: TaskArgsType) => {
      if (
        currentUserDetails &&
        currentUserDetails.id &&
        rootChapterId &&
        rootChapterVersion
      ) {
        const final: CreateTaskInput = {
          name: data.name,
          description: data.description,
          status: TaskStatusType.PENDING,
          rootChapterId,
          rootChapterVersion,
          requestedByUserId: currentUserDetails.id,
          productDocId,
          tenantId: falainaTenantId,
        };

        try {
          const createTaskMutationResult =
            await createTaskMutation.mutateAsync(final);

          const createdTask = (createTaskMutationResult.data?.createTask ??
            null) as Nullable<Partial<Task>>;

          if (createdTask && rootChapterId && rootChapterVersion) {
            await updateChapterMutation.mutateAsync({
              id: rootChapterId,
              version: rootChapterVersion,
              status: StatusType.PENDING_APPROVAL,
              currentTaskId: createdTask.id,
            });

            closeSlider();

            generateToast({
              type: 'success',
              toastContent: 'Successfully submitted for approval.',
            });

            navigate(`/products?status=${StatusType.PENDING_APPROVAL}`);
          }
        } catch {
          generateToast({
            type: 'error',
            toastContent: 'Failed to submit for approval. Please try again!!',
          });
        }
      }
    },
    [
      currentUserDetails,
      rootChapterId,
      rootChapterVersion,
      productDocId,
      createTaskMutation,
      updateChapterMutation,
      closeSlider,
      navigate,
    ],
  );

  const updateTask = useCallback(
    async (data: TaskArgsType) => {
      const isAuthenticated = await isCurrentUserAuthenticated();

      if (task?.id && isAuthenticated) {
        const final: UpdateTaskInput = {
          id: task.id,
          name: data.name,
          description: data.description,
          status: TaskStatusType.PENDING,
        };

        try {
          await updateTaskMutation.mutateAsync(final);

          if (rootChapterId && rootChapterVersion) {
            await updateChapterMutation.mutateAsync({
              id: rootChapterId,
              version: rootChapterVersion,
              status: StatusType.PENDING_APPROVAL,
            });

            closeSlider();

            generateToast({
              type: 'success',
              toastContent: 'Successfully re-submitted for approval',
            });

            navigate(`/products?status=${StatusType.PENDING_APPROVAL}`);
          }
        } catch {
          generateToast({
            type: 'error',
            toastContent:
              'Failed to re-submit for approval. Please try again!!',
          });
        }
      }
    },
    [
      closeSlider,
      isCurrentUserAuthenticated,
      navigate,
      rootChapterId,
      rootChapterVersion,
      task?.id,
      updateChapterMutation,
      updateTaskMutation,
    ],
  );

  const submit = useCallback(
    async (data: TaskArgsType) => {
      const isAuthenticated = await isCurrentUserAuthenticated();

      if (isAuthenticated) {
        if (action === ADD_TASK) {
          await addTask(data);
        }

        if (action === UPDATE_TASK) {
          await updateTask(data);
        }
      } else {
        generateSessionTimeoutToast();
      }
    },
    [action, addTask, updateTask],
  );

  const onRenderFooterContent = useCallback(
    () => (
      <div>
        <Separator />
        {createTaskMutation.isLoading || updateTaskMutation.isLoading ? (
          <PrimaryButton disabled text="Save" styles={buttonStyles} />
        ) : (
          <PrimaryButton
            text="Save"
            type="submit"
            styles={buttonStyles}
            className="bg-blue-db-20 focus:outline-none"
            onClick={handleSubmit(submit)}
          />
        )}
        <DefaultButton text="Cancel" onClick={closeSlider} />
      </div>
    ),
    [
      createTaskMutation.isLoading,
      updateTaskMutation.isLoading,
      handleSubmit,
      submit,
      closeSlider,
    ],
  );

  return (
    <Panel
      isOpen
      onDismiss={closeSlider}
      type={7}
      styles={{ root: { marginTop: 50 } }}
      customWidth="448px"
      closeButtonAriaLabel="Close"
      isLightDismiss
      hasCloseButton
      isFooterAtBottom
      onRenderFooterContent={onRenderFooterContent}
    >
      <Flex width="w-full" direction="col" space="space-y-6">
        <InputField
          type="text"
          name="name"
          control={control}
          label="Task Title"
          error={errors.name}
          placeholder="Task name"
          rules={AS_NAME}
        />
        <InputField
          type="text"
          multiline
          rows={6}
          name="description"
          control={control}
          label="Description"
          error={errors.description}
          placeholder="Quick description ..."
          rules={AS_NAME}
        />
      </Flex>
    </Panel>
  );
};

export default SubmitForApprovalForm;
