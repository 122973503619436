import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { isAdminAtom } from 'atoms/modals';

import falainaTenantId from 'consts/tenant';

import falainaLogoImage from 'assets/images/falaina.png';
import triquetraLogoImage from 'assets/images/triquetra.svg';

const getLogo = (logoFor: string | undefined) => {
  switch (logoFor) {
    case '678eb28c-8029-4415-a7dc-a035c0751e3a':
      return falainaLogoImage;
    default:
      return '';
  }
};

const useTenant = () => {
  const isSuperAdmin = useAtomValue(isAdminAtom);
  const [tenantLogoURL, setTenantLogoURL] = useState<string>(getLogo(''));

  useEffect(() => {
    if (isSuperAdmin) {
      setTenantLogoURL(triquetraLogoImage);
      return;
    }

    setTenantLogoURL(getLogo(falainaTenantId));
  }, [isSuperAdmin]);

  return {
    tenantLogoURL,
  };
};

export default useTenant;
