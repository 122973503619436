import type { GraphQLQuery } from '@aws-amplify/api';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';

import type {
  ModelChapterFilterInput,
  Chapter,
  AllChaptersUnderRootChapterForImportExportQuery,
} from 'API';

import { tenantAtom } from 'atoms/modals';

import { allChaptersUnderRootChapterForImportExport } from 'graphql/custom-queries';

import getNonNullableList from 'helpers/utils/getNonNullableList';
import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseChaptersUnderRootChapterType =
  | ({
      rootChapterId: string;
      filter?: ModelChapterFilterInput | null;
    } & UseInfiniteQueryOptions)
  | null
  | void;

const useChaptersUnderRootChapterSource = (
  props: IUseChaptersUnderRootChapterType,
) => {
  const filter = props?.filter ?? null;

  const queryKey = props?.queryKey ?? [];

  const rootChapterId = props?.rootChapterId;

  const enabled = (props?.enabled ?? true) && !!rootChapterId;

  const staleTime =
    props?.staleTime ?? toMilliseconds({ hours: 0, minutes: 0, seconds: 20 }); // 20secs

  return useInfiniteQuery({
    queryKey: [
      `chaptersUnderRootChapter`,
      { rootChapterId },
      filter,
      ...queryKey,
    ],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<
        GraphQLQuery<AllChaptersUnderRootChapterForImportExportQuery>
      >({
        query: allChaptersUnderRootChapterForImportExport,
        variables: filter
          ? { rootChapterId, filter, limit: 1_000_000, nextToken: pageParam }
          : { rootChapterId, limit: 1_000_000, nextToken: pageParam },
      });
    },
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.allChaptersUnderRootChapter?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
    enabled,
    staleTime,
  });
};

const useChaptersUnderRootChapter = (
  props: IUseChaptersUnderRootChapterType,
) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const filter = props?.filter ?? null;

  const rootChapterId = props?.rootChapterId ?? '';

  const enabled = (props?.enabled ?? true) && !!tenantId && !!rootChapterId;

  let sourceFilter: ModelChapterFilterInput | null = null;

  if (tenantId) {
    const defaultFilter: ModelChapterFilterInput | null = {
      tenantId: {
        eq: tenantId,
      },
    };

    sourceFilter = filter
      ? {
          ...filter,
          ...defaultFilter,
        }
      : { ...defaultFilter };
  }

  const result = useChaptersUnderRootChapterSource({
    ...props,
    rootChapterId,
    filter: sourceFilter,
    enabled,
  });

  const chaptersUnderRootChapterSource = useMemo(() => {
    const pages = result.data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.allChaptersUnderRootChapter?.items),
    );

    return tempItems as Chapter[];
  }, [result.data?.pages]);

  return {
    chaptersUnderRootChapterSource,
    ...result,
  };
};

export const useInfiniteChaptersUnderRootChapter = (
  props: IUseChaptersUnderRootChapterType,
) => {
  const result = useChaptersUnderRootChapter(props);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = result;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      try {
        void fetchNextPage();
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return result;
};

export default useChaptersUnderRootChapter;
