import { Icon } from '@fluentui/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from '@fluentui/react-components';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useState } from 'react';

import { S3Object } from 'API';

import { isDocumentIconDeletedAtom } from 'atoms/modals';

import useS3FileUrl from 'hooks/useS3FileUrl';

interface OverlayHookReturnType {
  isShowOverlay: boolean;
  handleInteraction: () => void;
  handleMouseLeave: () => void;
}
const useOverlay = (): OverlayHookReturnType => {
  const [isShowOverlay, setIsShowOverlay] = useState(false);
  const handleInteraction = useCallback(() => setIsShowOverlay(true), []);
  const handleMouseLeave = useCallback(() => setIsShowOverlay(false), []);
  return { isShowOverlay, handleInteraction, handleMouseLeave };
};

interface UseDeleteParams {
  iconId: string | undefined | null;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const useDelete = ({ iconId, setIsDialogOpen }: UseDeleteParams) => {
  const setIsChapterIconDeleted = useSetAtom(isDocumentIconDeletedAtom);
  const handleDeleteConfirm = useCallback(() => {
    if (iconId) {
      setIsChapterIconDeleted(true);
      setIsDialogOpen(false);
    }
  }, [iconId, setIsChapterIconDeleted, setIsDialogOpen]);
  return handleDeleteConfirm;
};

interface ProductIconWithDeleteProps {
  file: Partial<S3Object> | null;
  iconId: string | undefined | null;
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DocumentIconWithDelete: React.FC<ProductIconWithDeleteProps> = ({
  file,
  iconId,
  isDialogOpen,
  setIsDialogOpen,
}) => {
  const isChapterIconDeleted = useAtomValue(isDocumentIconDeletedAtom);
  const { isShowOverlay, handleInteraction, handleMouseLeave } = useOverlay();
  const fileUrl = useS3FileUrl({ file });
  const handleDeleteClick = useCallback(
    () => setIsDialogOpen(true),
    [setIsDialogOpen],
  );
  const handleDeleteConfirm = useDelete({ iconId, setIsDialogOpen });
  const handleCloseDialog = useCallback(
    () => setIsDialogOpen(false),
    [setIsDialogOpen],
  );
  return (
    <div>
      {fileUrl && !isChapterIconDeleted && (
        <div
          className="relative w-max mr-4 cursor-pointer"
          onMouseOver={handleInteraction}
          onFocus={handleInteraction}
          onMouseLeave={handleMouseLeave}
        >
          {isShowOverlay && (
            <div className="absolute top-0 right-0 bg-white border flex flex-row">
              <Icon
                className="w-6 h-6 flex justify-center items-center cursor-pointer"
                iconName="Delete"
                onClick={handleDeleteClick}
              />
            </div>
          )}
          <img
            src={fileUrl}
            alt="Product Icon"
            className="object-contain my-2 p-2 overflow-y-hidden shadow-microsoft"
            style={{ width: '120px', height: '150px' }}
          />
        </div>
      )}
      <Dialog
        open={isDialogOpen}
        onOpenChange={(event, data) => setIsDialogOpen(data.open)}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Remove icon</DialogTitle>
            <DialogContent>
              Are you sure you want to remove this icon?
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} appearance="secondary">
                Cancel
              </Button>
              <Button onClick={handleDeleteConfirm} appearance="primary">
                Remove
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};
export default DocumentIconWithDelete;
