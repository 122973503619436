import carouselFalaina1Img from 'assets/images/car2.jpg';
import carouselFalaina2Img from 'assets/images/car3.jpg';
import carouselFalaina3Img from 'assets/images/car4.jpg';
import defaultSignIn1Img from 'assets/images/defaultsignin.jpg';
import falainaLogoImage from 'assets/images/falainaLogo.png';
import defaultTriquetraLogo from 'assets/images/triquetra.svg';

const getTenantSettings = (id: string | null) => {
  switch (id) {
    case '678eb28c-8029-4415-a7dc-a035c0751e3a':
      return {
        name: 'Fálaina Community Cloud',
        logoURL: falainaLogoImage,
        color: '#305884',
        signInImages: [
          carouselFalaina1Img,
          carouselFalaina2Img,
          carouselFalaina3Img,
        ],
      };
    default:
      return {
        name: 'Not a Tenant',
        logoURL: defaultTriquetraLogo,
        color: '#000000',
        signInImages: [defaultSignIn1Img],
      };
  }
};

export default getTenantSettings;
