import { Link } from 'react-router-dom';

import Flex from 'components/Flex';
import Text from 'components/Text';

import { Chapter } from 'API';

import { CustomChapters, CustomDocs, CustomStatusType } from 'types/documents';
import { DocumentsMapType } from 'types/Pages/Products';

import getDiffClassName from 'helpers/utils/getDiffClassName';
import sortDocuments from 'helpers/utils/sortDocuments';

const constructFinalRootChapterElement = ({
  isContentReader,
  rootChapter,
  filteredSubChapters,
  filteredDocs,
  documentsMap,
  status,
  search,
  documentId,
}: {
  isContentReader: boolean;
  rootChapter: Partial<Chapter | null>;
  filteredSubChapters: CustomChapters;
  filteredDocs: CustomDocs;
  documentsMap: DocumentsMapType;
  status: CustomStatusType;
  search: string;
  documentId: string;
}) => {
  const diffClassNameForRootChapter = getDiffClassName({
    isContentReader,
    defaultClassName:
      'font-microsoft-docs text-gray-microsoft-docs font-semibold leading-10',
    status,
    diffFor: 'ROOTCHAPTER',
    isDeleted: rootChapter?.shouldBeDeleted === true,
    rootChapter,
  });

  const newDocument = sortDocuments({
    documents: [...filteredSubChapters, ...filteredDocs],
    documentsMap,
  });

  const allDocument =
    newDocument.length > 0
      ? newDocument.map((doc: any) => {
          if ('docItems' in doc) {
            const diffClassNameForDoc = getDiffClassName({
              isContentReader,
              defaultClassName: 'text-blue-db-16',
              status,
              diffFor: 'DOC',
              isDeleted:
                rootChapter?.shouldBeDeleted === true ||
                doc?.shouldBeDeleted === true,
              doc,
            });

            return {
              orderFloat: doc.orderFloat ? doc.orderFloat : '100000',
              comp: (
                <Flex
                  padding="p-0.5"
                  key={doc.id && doc.version && doc.id + doc.version}
                >
                  <Link
                    to={`/products/documents/${documentId}/content/${
                      doc.id
                    }?status=${status}${
                      search.split('view=')[1] ? '&view=read' : ''
                    }`}
                  >
                    <Text className={diffClassNameForDoc} variant="medium">
                      {doc.title}
                    </Text>
                  </Link>
                </Flex>
              ),
            };
          }
          const diffClassNameForSubChapter = getDiffClassName({
            isContentReader,
            defaultClassName: 'text-blue-db-16',
            status,
            diffFor: 'CHAPTER',
            isDeleted:
              rootChapter?.shouldBeDeleted === true ||
              doc?.shouldBeDeleted === true,
            chapter: doc,
          });

          return {
            orderFloat: doc.orderFloat ? doc.orderFloat : '100000',
            comp: (
              <Flex
                padding="p-0.5"
                key={doc.id && doc.version && doc.id + doc.version}
              >
                <Link
                  to={`/products/documents/${documentId}/subChapter/${
                    doc.id
                  }?status=${status}${
                    search.split('view=')[1] ? '&view=read' : ''
                  }`}
                >
                  <Text className={diffClassNameForSubChapter} variant="medium">
                    {doc.name}
                  </Text>
                </Link>
              </Flex>
            ),
          };
        })
      : [];

  const returnElements = allDocument.sort(
    (a: any, b: any) =>
      Number.parseFloat(a?.orderFloat ? a?.orderFloat : '') -
      Number.parseFloat(b?.orderFloat ? b?.orderFloat : ''),
  );

  const finalReturnElements = returnElements.map(
    (element: any) => element?.comp,
  );

  return { diffClassNameForRootChapter, finalReturnElements };
};

export default constructFinalRootChapterElement;
