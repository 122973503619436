/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/forbid-elements */
import { Panel, Text as MsText } from '@fluentui/react';
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Text,
} from '@fluentui/react-components';
import {
  AddCircle28Filled,
  MoreVertical20Regular,
} from '@fluentui/react-icons';
import classNames from 'classnames';
import { useSetAtom, useAtom } from 'jotai';
import { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Flex from 'components/Flex';

import { Chapter, StatusType } from 'API';

import { CustomDocs } from 'types/documents';
import { DocumentsMapType } from 'types/Pages/Products';

import {
  chapterEditAtom,
  docEditAtom,
  QueryKeys,
  showNestedSidebarPanelAtom,
} from 'atoms/modals';

import useConstructStatus from 'hooks/useConstructStatus';
import { isCurrentUserAuthenticated } from 'hooks/useGetCurrentAuthenticatedUser';
import useIsContentReader from 'hooks/useIsContentReader';
import useRootChaptersPage from 'hooks/useRootChaptersPage';

import generateToast, {
  generateSessionTimeoutToast,
} from 'helpers/utils/generateToast';
import getDiffClassName from 'helpers/utils/getDiffClassName';

import type { SetToastMessageType } from 'types';

import MultiLevelSideBar from './MultiLevelSideBar';

type CustomChapter = Partial<Chapter | null>;

export type NestedSideBarType = {
  rootChapter: CustomChapter;
  filteredSubChapters: any[];
  filteredDocs: CustomDocs;
  flattenedDocuments: any[];
  documentsMap: DocumentsMapType;
  allRootChapterVersions: CustomChapter[];
  hasCreateProductAccess: boolean;
  setMessage: SetToastMessageType;
};

const NestedSideBar = ({
  rootChapter,
  filteredSubChapters,
  filteredDocs,
  flattenedDocuments,
  documentsMap,
  allRootChapterVersions = [],
  hasCreateProductAccess,
  setMessage,
}: NestedSideBarType) => {
  const [isHovering, setIsHovering] = useState(false);

  const setChapterAtomValue = useSetAtom(chapterEditAtom);
  const setDocAtomValue = useSetAtom(docEditAtom);
  const [showNestedSidebarPanelView, setShowNestedSidebarPanelView] = useAtom(
    showNestedSidebarPanelAtom,
  );

  const productDocId = rootChapter?.parentProductDocId;

  const { productDoc } = useRootChaptersPage({
    productDocId,
  });

  const search = useLocation().search;
  const pathname = useLocation().pathname;
  const { documentId } = useParams();

  const { isContentReader } = useIsContentReader();

  const { status } = useConstructStatus({ search });

  const toUrl = search.split('view=')[1]
    ? `/products/documents/${documentId}?status=${status}&view=read`
    : `/products/documents/${documentId}?status=${status}`;

  const showActionButtons = hasCreateProductAccess
    ? rootChapter?.status === StatusType.PENDING ||
      rootChapter?.status === StatusType.PUBLISHED
    : false;

  if (rootChapter) {
    const isSomeRootChapVersionSubmittedForApproval =
      allRootChapterVersions.some(
        (x) => x?.status === StatusType.PENDING_APPROVAL,
      );

    const isRootChapterSelected =
      pathname.includes(`/products/documents/${documentId}`) &&
      !(
        pathname.includes(`/products/documents/${documentId}/content`) ||
        pathname.includes(`/products/documents/${documentId}/subChapter`)
      );

    const diffClassName = getDiffClassName({
      isContentReader,
      defaultClassName: 'text-gray-microsoft-docs',
      status,
      diffFor: 'ROOTCHAPTER',
      isDeleted: rootChapter.shouldBeDeleted === true,
      rootChapter,
    });

    const rootChapterComp = (
      <li>
        <div
          onMouseEnter={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
          style={{
            display: 'flex',
            padding: '2px',
            alignItems: 'center',
          }}
          className="sm: border-b md:border-b lg:border-b-0 border-gray-fl-20 xl:relative"
        >
          <Link
            to={toUrl}
            className="group w-full flex items-center pr-2 font-medium p-0.5 text-gray-600 rounded-md hover:text-gray-900"
            onClick={() => {
              setShowNestedSidebarPanelView(false);
            }}
          >
            <MsText
              variant="medium"
              className={classNames(
                'font-microsoft-docs  pl-0.5 leading-7 hover:underline',
                {
                  'font-semibold bg-gray-fl-21 w-full': isRootChapterSelected,
                },
                diffClassName,
              )}
            >
              {rootChapter.name}
            </MsText>
          </Link>
          {showActionButtons && !search.split('view=')[1] && isHovering && (
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button
                  className="font-bold rounded-full "
                  appearance="transparent"
                  icon={<MoreVertical20Regular className="" />}
                  onClick={async () => {
                    const isAuthenticated = await isCurrentUserAuthenticated();
                    if (!isAuthenticated) {
                      generateSessionTimeoutToast();
                    }
                  }}
                />
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  <MenuItem
                    // className="font-bold rounded-full w-full flex items-center justify-center"
                    icon={<AddCircle28Filled className="text-blue-900" />}
                    onClick={() => {
                      setShowNestedSidebarPanelView(false);
                      setIsHovering(false);

                      if (rootChapter.shouldBeDeleted === true) {
                        generateToast({
                          type: 'error',
                          toastContent:
                            'Cannot Add Chapter as this root document was deleted.',
                        });
                      } else if (isSomeRootChapVersionSubmittedForApproval) {
                        generateToast({
                          type: 'error',
                          toastContent:
                            'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
                        });
                      } else {
                        setChapterAtomValue({
                          chapter: {
                            id: uuidv4(),
                            name: '',
                            version: '',
                            status: StatusType.PENDING,
                            description: '',
                            parentChapterId: documentId!,
                            rootChapterId: documentId!,
                          },
                          isOpen: true,
                          action: 'ADD',
                          chapterType: QueryKeys.SUB_CHAPTER,
                        });
                      }
                    }}
                  >
                    <Text className="text-black-db-1" size={300}>
                      Add Chapter
                    </Text>
                  </MenuItem>
                  <MenuItem
                    icon={<AddCircle28Filled className="text-green-900" />}
                    onClick={() => {
                      setShowNestedSidebarPanelView(false);
                      setIsHovering(false);

                      if (rootChapter.shouldBeDeleted === true) {
                        generateToast({
                          type: 'error',
                          toastContent:
                            'Cannot Add Content as this root document was deleted.',
                        });
                      } else if (isSomeRootChapVersionSubmittedForApproval) {
                        generateToast({
                          type: 'error',
                          toastContent:
                            'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
                        });
                      } else {
                        setDocAtomValue({
                          doc: {
                            id: uuidv4(),
                            title: '',
                            version: '',
                            status: StatusType.PENDING,
                            description: '',
                            chapterId: documentId!,
                            rootChapterId: documentId!,
                            referenceDocId: null,
                          },
                          isOpen: true,
                          action: 'ADD',
                        });
                      }
                    }}
                  >
                    <Text className="text-black-db-1" size={300}>
                      Add Content
                    </Text>
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          )}
        </div>
      </li>
    );

    return (
      <div className="flex flex-col min-w-80 flex-grow xl:w-4/12 md:w-6/12 lg:pl-2 md:pl-0 sm: pl-0 lg:pt-1 sm: pt-0 lg:pb-0 md:pb-0 sm: pb-2 sticky top-0 h-80vh z-40">
        <div className="flex-grow flex flex-col pb-4 mt-6 pt-8 overflow-y-scroll">
          <nav className="flex-1 lg:px-3 md:px-0 sm: px-0" aria-label="Sidebar">
            <ul className="hidden md:block">
              {rootChapterComp}
              <MultiLevelSideBar
                productDoc={productDoc}
                rootChapter={rootChapter}
                allRootChapterVersions={allRootChapterVersions}
                menus={filteredSubChapters}
                docs={filteredDocs}
                flattenedDocuments={flattenedDocuments}
                documentsMap={documentsMap}
                hasCreateProductAccess={hasCreateProductAccess}
                setMessage={setMessage}
              />
            </ul>
          </nav>
          <Panel
            isOpen={showNestedSidebarPanelView}
            onDismiss={() => {
              setShowNestedSidebarPanelView(false);
            }}
            type={8}
            customWidth="448px"
            closeButtonAriaLabel="Close"
            isLightDismiss
            hasCloseButton
            headerText="Table of contents"
            headerClassName="text-sm"
            styles={{
              root: {
                marginTop: 50,
              },
              subComponentStyles: {
                closeButton: {
                  icon: {
                    fontWeight: 900,
                    color: 'black',
                  },
                },
              },
              navigation: {
                borderBottom: '0.5px solid',
                borderColor: 'rgb(230, 230, 230)',
              },
              commands: {
                marginTop: '0px',
                paddingTop: '0px',
              },
              header: {
                alignSelf: 'center',
              },
              content: {
                padding: '0px',
              },
            }}
          >
            <Flex width="w-full" direction="col">
              <ul>
                {rootChapterComp}
                <MultiLevelSideBar
                  productDoc={productDoc}
                  rootChapter={rootChapter}
                  allRootChapterVersions={allRootChapterVersions}
                  menus={filteredSubChapters}
                  docs={filteredDocs}
                  flattenedDocuments={flattenedDocuments}
                  documentsMap={documentsMap}
                  hasCreateProductAccess={hasCreateProductAccess}
                  setMessage={setMessage}
                />
              </ul>
            </Flex>
          </Panel>
        </div>
      </div>
    );
  }

  return null;
};

export default NestedSideBar;
