import { useMemo } from 'react';

import { StatusType } from 'API';

import useGetCurrentUserAccess from './useGetCurrentUserAccess';

const useConstructStatus = ({ search }: { search: string }) => {
  const statusQueryParam = useMemo(
    () => new URLSearchParams(search).get('status'),
    [search],
  );

  const { hasSomeCreatorAccess, hasSomeApproverAccess } =
    useGetCurrentUserAccess({});

  const res = useMemo(() => {
    let status: StatusType | null = null;

    if (hasSomeCreatorAccess || hasSomeApproverAccess) {
      if (statusQueryParam) {
        if (
          ['PUBLISHED', 'PENDING', 'PENDING_APPROVAL'].includes(
            statusQueryParam,
          )
        ) {
          if (statusQueryParam === StatusType.PUBLISHED) {
            status = StatusType.PUBLISHED;
          }

          if (statusQueryParam === StatusType.PENDING) {
            status = StatusType.PENDING;
          }

          if (statusQueryParam === StatusType.PENDING_APPROVAL) {
            status = StatusType.PENDING_APPROVAL;
          }
        } else {
          status = StatusType.PUBLISHED;
        }
      }
    } else {
      status = StatusType.PUBLISHED;
    }

    return { status };
  }, [hasSomeApproverAccess, hasSomeCreatorAccess, statusQueryParam]);

  return res;
};

export default useConstructStatus;
