import { useLocation } from 'react-router-dom';

import useConstructStatus from 'hooks/useConstructStatus';

const useStatus = () => {
  const { search } = useLocation();

  const { status } = useConstructStatus({ search });

  return status;
};

export default useStatus;
