import { Chapter, DocItem, StatusType } from 'API';

import { CustomStatusType } from 'types/documents';

import {
  DELETED_DIFF_CLASSNAME,
  NORMAL_DIFF_CLASSNAME,
} from 'consts/diffConstants';

import getNonNullableList from './getNonNullableList';

const getSideBarDiffClassName = ({
  status,
  rootChapter,
  data,
  defaultClassName = '',
  isContentReader,
}: {
  status: CustomStatusType;
  rootChapter: Partial<Chapter | null>;
  data: any;
  defaultClassName?: string;
  isContentReader: boolean;
}) => {
  if (isContentReader) {
    return defaultClassName;
  }

  if (
    status &&
    [StatusType.PENDING, StatusType.PENDING_APPROVAL].includes(status)
  ) {
    if (rootChapter?.shouldBeDeleted === true) {
      return DELETED_DIFF_CLASSNAME;
    }

    if ([StatusType.PUBLISHED].includes(data.status)) {
      if (data.docItems) {
        const docItems: Partial<DocItem>[] = getNonNullableList(
          data.docItems.items,
        );

        const isSomePendingDocItemVersionPresentInDoc = docItems.some(
          (x) => x.status === StatusType.PENDING,
        );

        if (isSomePendingDocItemVersionPresentInDoc) {
          return NORMAL_DIFF_CLASSNAME;
        }
      }

      return defaultClassName;
    }

    if (
      [StatusType.PENDING, StatusType.PENDING_APPROVAL].includes(data.status)
    ) {
      return data.shouldBeDeleted === true
        ? DELETED_DIFF_CLASSNAME
        : NORMAL_DIFF_CLASSNAME;
    }
  }

  return defaultClassName;
};

export default getSideBarDiffClassName;
