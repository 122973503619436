import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { Lead, User } from 'API';

import {
  currentUserAccessDetailsAtom,
  currentUserDetailsAtom,
  currentUserOrganizationAtom,
  isTenantAdminAtom,
} from 'atoms/modals';

import {
  PARTNER,
  DISTRIBUTOR,
  SALES_TEAM,
  SALES_DIRECTOR,
  SALES_MANAGEMENT,
} from 'consts/roles';

import { sortList } from 'helpers/sortList';
import getLeadManagementUserRole from 'helpers/utils/leadManagement/getLeadManagementUserRole';

const isLeadCreatedByCurrentUser = ({
  currentLead,
  currentUserDetails,
}: {
  currentLead: Partial<Lead> | null;
  currentUserDetails: Partial<User> | null;
}) => currentLead?.createdByUserId === currentUserDetails?.id;

const isLeadCreatedByUserFromCurrentUsersOrg = ({
  currentLead,
  currentUserOrganization,
}: {
  currentLead: Partial<Lead> | null;
  currentUserOrganization: string | null;
}) => currentLead?.createdByUser?.organizationId === currentUserOrganization;

const useGetUsersLeads = ({ allLeads }: { allLeads: Partial<Lead>[] }) => {
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);
  const currentUserAccessDetails = useAtomValue(currentUserAccessDetailsAtom);
  const currentUserOrganization = useAtomValue(currentUserOrganizationAtom);
  const isTenantAdmin = useAtomValue(isTenantAdminAtom);

  const hasSomeLeadManagementSalesTeamAccess =
    !!currentUserAccessDetails?.hasSomeLeadManagementSalesTeamAccess;
  const hasSomeLeadManagementSalesDirectorAccess =
    !!currentUserAccessDetails?.hasSomeLeadManagementSalesDirectorAccess;
  const hasSomeLeadManagementSalesManagementAccess =
    !!currentUserAccessDetails?.hasSomeLeadManagementSalesManagementAccess;
  const leadManagementSalesTeamLocations =
    currentUserAccessDetails?.leadManagementSalesTeamLocations;
  const leadManagementSalesDirectorLocations =
    currentUserAccessDetails?.leadManagementSalesDirectorLocations;
  const leadManagementSalesManagementLocations =
    currentUserAccessDetails?.leadManagementSalesManagementLocations;

  const leadManagementUserRole = useMemo(
    () =>
      getLeadManagementUserRole({
        isSalesTeam: hasSomeLeadManagementSalesTeamAccess,
        isSalesDirector: hasSomeLeadManagementSalesDirectorAccess,
        isSalesManagement: hasSomeLeadManagementSalesManagementAccess,
        currentUserOrgId: currentUserOrganization,
      }),
    [
      currentUserOrganization,
      hasSomeLeadManagementSalesDirectorAccess,
      hasSomeLeadManagementSalesManagementAccess,
      hasSomeLeadManagementSalesTeamAccess,
    ],
  );

  const accessibleLeads: Partial<Lead>[] = useMemo(() => {
    if (isTenantAdmin) {
      return allLeads;
    }

    if (
      leadManagementUserRole === PARTNER ||
      leadManagementUserRole === DISTRIBUTOR
    ) {
      return allLeads.filter(
        (l) =>
          isLeadCreatedByCurrentUser({ currentLead: l, currentUserDetails }) ||
          isLeadCreatedByUserFromCurrentUsersOrg({
            currentLead: l,
            currentUserOrganization,
          }),
      );
    }

    if (
      leadManagementUserRole === SALES_TEAM ||
      leadManagementUserRole === SALES_DIRECTOR ||
      leadManagementUserRole === SALES_MANAGEMENT
    ) {
      return allLeads.filter((l) => {
        const locationsMap = {
          [SALES_TEAM]: leadManagementSalesTeamLocations,
          [SALES_DIRECTOR]: leadManagementSalesDirectorLocations,
          [SALES_MANAGEMENT]: leadManagementSalesManagementLocations,
        };

        const leadCreatorLocation = l.createdByUser?.organizationId?.slice(
          0,
          3,
        );

        return (
          isLeadCreatedByCurrentUser({ currentLead: l, currentUserDetails }) ||
          isLeadCreatedByUserFromCurrentUsersOrg({
            currentLead: l,
            currentUserOrganization,
          }) ||
          locationsMap[leadManagementUserRole]?.includes(leadCreatorLocation!)
        );
      });
    }

    return [];
  }, [
    isTenantAdmin,
    leadManagementUserRole,
    allLeads,
    currentUserDetails,
    currentUserOrganization,
    leadManagementSalesTeamLocations,
    leadManagementSalesDirectorLocations,
    leadManagementSalesManagementLocations,
  ]);

  const sortedAccessibleLeads = useMemo(
    () => sortList(accessibleLeads),
    [accessibleLeads],
  );

  return {
    currentUsersAccessibleLeads: sortedAccessibleLeads,
  };
};

export default useGetUsersLeads;
