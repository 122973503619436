import { toast, ToastContent, ToastOptions } from 'react-toastify';

import { AUTH_SESSION_TIMED_OUT } from 'consts/alertMessages';

const generateToast = ({
  type,
  toastContent,
  toastOptions = {},
}: {
  type: 'info' | 'success' | 'warning' | 'error';
  toastContent: ToastContent;
  toastOptions?: ToastOptions;
}) => {
  const defaultToastOptions: ToastOptions = {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    onClose: () => {},
  };

  return toast[type](toastContent, { ...defaultToastOptions, ...toastOptions });
};

export const generateSessionTimeoutToast = () =>
  generateToast({
    type: 'error',
    toastContent: AUTH_SESSION_TIMED_OUT,
    toastOptions: {
      position: 'top-left',
      autoClose: 5000,
      onClose: () => {
        sessionStorage.clear(); // IMPORTANT: Clearing session storage is extremely important for microsoft oauth.

        window.location.reload();
      },
      closeButton: false,
      icon: false,
      style: { width: '100vw' },
    },
  });

export default generateToast;
