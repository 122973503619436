import { useParams } from 'react-router-dom';

import useConstructStatus from 'hooks/useConstructStatus';
import useHistoryLocation from 'hooks/useHistoryLocation';
import useIsContentReader from 'hooks/useIsContentReader';
import useTransformDocuments from 'hooks/useTransformDocuments';
import { Chapter, Doc } from 'API';
import constructFinalRootChapterElement from 'helpers/utils/rootChapterPage';

type UseRootChapterPageType = {
  rootChapter: Partial<Chapter | null>;
};

const useRootChapterPage = ({ rootChapter }: UseRootChapterPageType) => {
  const [, location] = useHistoryLocation();

  const search = location.search;
  const { status } = useConstructStatus({ search });

  const { documentId } = useParams();

  const { isContentReader } = useIsContentReader();

  const subChapters: Partial<Chapter | null>[] =
    rootChapter?.subChapters?.items || [];
  const docs: Partial<Doc | null>[] = rootChapter?.docs?.items || [];

  const { filteredSubChapters, filteredDocs, documentsMap } =
    useTransformDocuments({ subChapters, docs });

  const { diffClassNameForRootChapter, finalReturnElements } =
    constructFinalRootChapterElement({
      isContentReader,
      rootChapter,
      filteredSubChapters,
      filteredDocs,
      documentsMap,
      status,
      search,
      documentId: documentId as string,
    });

  return {
    diffClassNameForRootChapter,

    finalReturnElements,
  };
};

export default useRootChapterPage;
