import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './navbar.module.css';
import NavLink from './NavLink';
// Variants for different bg for navbars
type VariantType = 'primary' | 'secondary' | 'tertiary';

type NavbarProps = {
  children: React.ReactNode;
  variant?: VariantType;
};
const Navbar = ({ children, variant = 'primary' }: NavbarProps) => {
  return (
    <nav
      className={classNames(
        styles[`nav-${variant}`],
        styles['navbar-wrapper'],
        'fixed top-0',
      )}
      style={{ zIndex: 2000 }}
    >
      {children}
    </nav>
  );
};

// Wrapper for navmenu items
Navbar.Items = ({ children }: { children: React.ReactNode }) => (
  <ul className={styles['navmenu-ul']}>{children}</ul>
);
// Individual navitems
Navbar.Item = ({
  children,
  url,
}: {
  children: React.ReactNode;
  url: string;
}) => (
  <li className={styles['navmenu-li']}>
    <NavLink>
      <Link to={`/${url}`}>{children}</Link>
    </NavLink>
  </li>
);

Navbar.Title = ({ children }: { children: React.ReactNode }) => <>{children}</>;

export default Navbar;
