import {
  Panel,
  Separator,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Flex from 'components/Flex';
import InputField from 'components/InputField';

import { Chapter, CreateDocInput, StatusType, UpdateDocInput } from 'API';

import { CustomDocType } from 'types/documents';
import type { AddDocParams } from 'types/Pages/Products';

import { docEditAtom, initialDocAtomProps, noneValue } from 'atoms/modals';

import { INITIAL_DOC_SETTINGS } from 'consts/docVersions';
import falainaTenantId from 'consts/tenant';
import { AS_NAME, AS_NUMBER } from 'consts/validations';

import useChapterMutations from 'hooks/useChapterMutations';
import useDocMutations from 'hooks/useDocMutations';
import useGetCurrentAuthenticatedUser, {
  isCurrentUserAuthenticated,
} from 'hooks/useGetCurrentAuthenticatedUser';
import useStatus from 'hooks/useStatus';

import { generateSessionTimeoutToast } from 'helpers/utils/generateToast';

import { WithRequiredProperty } from 'types';

type CustomCreateDocInput = WithRequiredProperty<
  Omit<CreateDocInput, 'description'> & Pick<CustomDocType, 'description'>,
  'tenantId'
>;

type CustomUpdateDocInput = Partial<Omit<UpdateDocInput, 'id'>> &
  Pick<UpdateDocInput, 'id'>;

type SetToastMessageType = (messageObject: {
  message: string;
  type: string;
}) => void;

type IDocSliderFormProps = {
  rootChapter?: Partial<Chapter | null>;
  allRootChapterVersions: Partial<Chapter | null>[];
  setMessage: SetToastMessageType;
};

const buttonStyles = { root: { marginRight: 8 } };

const useParent = () => {
  const docAtomValue = useAtomValue(docEditAtom);
  const { doc } = docAtomValue;

  const chapterId = doc?.chapterId ?? noneValue;
  const rootChapterId = doc?.rootChapterId ?? noneValue;

  const parent = useMemo(
    () => ({
      chapterId,
      rootChapterId,
    }),
    [chapterId, rootChapterId],
  );

  return parent;
};

const useClosePanel = () => {
  const setDocAtomValue = useSetAtom(docEditAtom);

  const closePanel = useCallback(() => {
    setDocAtomValue(initialDocAtomProps);
  }, [setDocAtomValue]);

  return closePanel;
};

const useDocForm = () => {
  const docAtomValue = useAtomValue(docEditAtom);

  const { doc } = docAtomValue;

  const defaultValues: AddDocParams = useMemo(
    () => ({
      title: '',
      description: '',
      orderFloat: '',
    }),
    [],
  );

  const formProps = useForm<AddDocParams>({
    defaultValues,
  });

  const { reset } = formProps;

  useEffect(() => {
    if (doc) {
      const defaults = {
        title: doc.title ?? defaultValues.title,
        description: doc.description ?? defaultValues.description,
        orderFloat: doc.orderFloat ?? defaultValues.orderFloat,
      };

      reset(defaults);
    }
  }, [
    defaultValues.description,
    defaultValues.orderFloat,
    defaultValues.title,
    doc,
    reset,
  ]);

  return formProps;
};

const DocSliderForm = ({
  rootChapter,
  allRootChapterVersions = [],
  setMessage,
}: IDocSliderFormProps) => {
  const navigate = useNavigate();

  const { documentId } = useParams();

  const docAtomValue = useAtomValue(docEditAtom);

  const { isOpen, action, doc } = docAtomValue;

  const status = useStatus();

  const parentObj = useParent();

  const closePanel = useClosePanel();

  const currentAuthenticatedUser = useGetCurrentAuthenticatedUser();

  const currentAuthenticatedUserId = currentAuthenticatedUser?.sub ?? null;

  const tenantId = falainaTenantId;

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
  } = useDocForm();

  const {
    onCreateDoc,
    onUpdateDoc,
    isCreateLoading,
    isUpdateLoading,
    isCreateSuccess,
    isUpdateSuccess,
  } = useDocMutations();

  const { onCreateChapter } = useChapterMutations();

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      reset();
    }
  }, [isCreateSuccess, isUpdateSuccess, reset]);

  const handleCreateNewRootChapterVersion = useCallback(() => {
    const isPendingRootChapterVersionPresent = allRootChapterVersions.some(
      (x) => x?.status === StatusType.PENDING,
    );

    const shouldCreateNewRootChapterVersion =
      currentAuthenticatedUserId &&
      status === StatusType.PUBLISHED &&
      rootChapter?.status === StatusType.PUBLISHED &&
      !isPendingRootChapterVersionPresent;

    if (shouldCreateNewRootChapterVersion) {
      const final = {
        ...rootChapter,
        id: rootChapter.id,
        name: rootChapter.name ?? '',
        description: rootChapter.description ?? '',
        version: rootChapter.version
          ? String(Number(rootChapter.version) + 1)
          : '2',
        status: StatusType.PENDING,
        createdByUserId: currentAuthenticatedUserId,
        lastModifiedByUserId: currentAuthenticatedUserId,
        tenantId,
        rootChapterId: rootChapter.rootChapterId ?? '',
        file: rootChapter.file,
      };

      onCreateChapter({
        chapter: {
          ...final,
        },
        isParentProduct: true,
        callback: closePanel,
        showNotification: true,
      });
    }
  }, [
    allRootChapterVersions,
    closePanel,
    currentAuthenticatedUserId,
    onCreateChapter,
    rootChapter,
    status,
    tenantId,
  ]);

  const addNewDocument = useCallback(
    (data: AddDocParams) => {
      if (currentAuthenticatedUserId) {
        const final: CustomCreateDocInput = {
          ...data,
          ...INITIAL_DOC_SETTINGS,
          ...parentObj,
          createdByUserId: currentAuthenticatedUserId,
          lastModifiedByUserId: currentAuthenticatedUserId,
          tenantId,
        };

        const isPendingRootChapterVersionPresent = allRootChapterVersions.some(
          (x) => x?.status === StatusType.PENDING,
        );

        onCreateDoc(
          {
            ...final,
          },
          () => {
            handleCreateNewRootChapterVersion();
            closePanel();
          },
          !!isPendingRootChapterVersionPresent,
          setMessage,
        );
      }
    },
    [
      allRootChapterVersions,
      closePanel,
      currentAuthenticatedUserId,
      handleCreateNewRootChapterVersion,
      onCreateDoc,
      parentObj,
      setMessage,
      tenantId,
    ],
  );

  const updateDocument = useCallback(
    (data: AddDocParams) => {
      if (currentAuthenticatedUserId) {
        if (
          status === StatusType.PUBLISHED &&
          doc?.status === StatusType.PUBLISHED
        ) {
          const final: CustomCreateDocInput = {
            ...data,
            ...parentObj,
            id: doc.id,
            version: doc.version ? String(Number(doc.version) + 1) : '2',
            status: StatusType.PENDING,
            lastModifiedByUserId: currentAuthenticatedUserId,
            createdByUserId: currentAuthenticatedUserId,
            tenantId,
            referenceDocId: doc.referenceDocId,
          };

          const isPendingRootChapterVersionPresent =
            allRootChapterVersions.some(
              (x) => x?.status === StatusType.PENDING,
            );

          onCreateDoc(
            {
              ...final,
            },
            () => {
              handleCreateNewRootChapterVersion();
              closePanel();
            },
            !!isPendingRootChapterVersionPresent,
            setMessage,
          );
        }

        if (status === StatusType.PENDING) {
          if (doc?.status === StatusType.PUBLISHED) {
            const newVersion = doc.version
              ? String(Number(doc.version) + 1)
              : '2';

            const final: CustomCreateDocInput = {
              ...data,
              ...parentObj,
              id: doc.id,
              version: newVersion,
              status: StatusType.PENDING,
              lastModifiedByUserId: currentAuthenticatedUserId,
              createdByUserId: currentAuthenticatedUserId,
              tenantId,
              referenceDocId: doc.referenceDocId,
            };

            onCreateDoc(
              {
                ...final,
              },
              () => {
                closePanel();

                navigate(
                  `/products/documents/${documentId}/content/${doc.id}?status=PENDING`,
                );
              },
              true,
              setMessage,
            );
          }

          if (doc?.status === StatusType.PENDING) {
            const final: CustomUpdateDocInput = {
              id: doc.id ?? '',
              version: doc.version,
              lastModifiedByUserId: currentAuthenticatedUserId,
              ...data,
              ...parentObj,
            };

            onUpdateDoc({ ...final }, closePanel, setMessage);
          }
        }
      }
    },
    [
      currentAuthenticatedUserId,
      status,
      doc,
      parentObj,
      tenantId,
      allRootChapterVersions,
      onCreateDoc,
      setMessage,
      closePanel,
      handleCreateNewRootChapterVersion,
      navigate,
      documentId,
      onUpdateDoc,
    ],
  );

  const isTitleChanged = watch('title') !== doc?.title;
  const isDescriptionChanged = watch('description') !== doc?.description;
  const isOrderFloatChanged = watch('orderFloat') !== doc?.orderFloat;

  const isSaveButtonEnabled =
    isTitleChanged || isDescriptionChanged || isOrderFloatChanged;

  const submit = useCallback(
    async (data: AddDocParams) => {
      const isAdd = action === 'ADD';
      const isUpdate = action === 'UPDATE';

      const isAuthenticated = await isCurrentUserAuthenticated();

      if (isAuthenticated) {
        if (isAdd) {
          addNewDocument(data);
        }

        if (isUpdate) {
          updateDocument(data);
        }
      } else {
        closePanel();
        generateSessionTimeoutToast();
      }
    },
    [action, addNewDocument, closePanel, updateDocument],
  );

  const onRenderFooterContent = useCallback(
    () => (
      <div>
        <Separator />
        {isCreateLoading || isUpdateLoading || !isSaveButtonEnabled ? (
          <PrimaryButton disabled text="Save" styles={buttonStyles} />
        ) : (
          <PrimaryButton
            text="Save"
            type="submit"
            styles={buttonStyles}
            className="bg-blue-db-20 focus:outline-none"
            onClick={handleSubmit(submit)}
          />
        )}
        <DefaultButton
          text="Cancel"
          onClick={() => {
            closePanel();
          }}
        />
      </div>
    ),
    [
      isCreateLoading,
      isUpdateLoading,
      isSaveButtonEnabled,
      handleSubmit,
      submit,
      closePanel,
    ],
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={() => {
        closePanel();
      }}
      type={7}
      styles={{ root: { marginTop: 50 } }}
      customWidth="448px"
      closeButtonAriaLabel="Close"
      isLightDismiss
      hasCloseButton
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
    >
      <Flex width="w-full" direction="col" space="space-y-6">
        <InputField
          required
          type="text"
          name="title"
          control={control}
          label="Content Title"
          error={errors.title}
          placeholder="Content Title"
          rules={AS_NAME}
          width="w-full"
        />

        <InputField
          type="text"
          multiline
          rows={6}
          name="description"
          control={control}
          label="Description"
          error={errors.description}
          placeholder="Quick description ..."
          width="w-full"
        />

        <InputField
          required
          type="number"
          name="orderFloat"
          control={control}
          label="Order"
          error={errors.orderFloat}
          placeholder="Order"
          width="w-full"
          rules={AS_NUMBER}
        />
      </Flex>
    </Panel>
  );
};

export default DocSliderForm;
