/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Icon as MsIcon, Text as MsText } from '@fluentui/react';
import {
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Button,
} from '@fluentui/react-components';
import { MoreVertical20Regular } from '@fluentui/react-icons';
import classNames from 'classnames';
import { useSetAtom, useAtom, useAtomValue } from 'jotai';
import flatMapDeep from 'lodash/flatMapDeep';
import { useState, useRef, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';

import { StatusType } from 'API';

import {
  ListMenuType,
  MultiLevelSideBarType,
  SubMenuType,
} from 'types/nestedSidebar';

import {
  isTenantAdminAtom,
  nestedBreadCrumbAtom,
  showNestedSidebarPanelAtom,
} from 'atoms/modals';

import {
  DELETED_DIFF_CLASSNAME,
  NORMAL_DIFF_CLASSNAME,
} from 'consts/diffConstants';

import useAutoUnCollapseNestedSideBar from 'hooks/useAutoUnCollapseNestedSideBar';
import useConstructStatus from 'hooks/useConstructStatus';
import useGenerateBreadCrumbString from 'hooks/useGenerateBreadCrumbString';
import { isCurrentUserAuthenticated } from 'hooks/useGetCurrentAuthenticatedUser';
import useIsContentReader from 'hooks/useIsContentReader';
import useGetCurrentUserAccess from 'hooks/useGetCurrentUserAccess';

import { generateSessionTimeoutToast } from 'helpers/utils/generateToast';
import getSideBarDiffClassName from 'helpers/utils/getSideBarDiffClassName';
import getSubChapterOrContentId from 'helpers/utils/getSubChapterOrContentId';
import sortDocuments from 'helpers/utils/sortDocuments';

import './sidebar.css';
import { SetToastMessageType } from 'types';

import {
  AddChapterButton,
  AddContentButton,
  ClearCacheAndExportChapterButton,
  ClearCacheAndExportDocButton,
  DeleteOrDiscardChapterButton,
  DeleteOrDiscardContentButton,
  EditChapterButton,
  EditContentButton,
  ExportChapterButton,
  ExportDocButton,
} from './ActionButtons';
import ArrowIcon from './ArrowIcon';

const ListMenu = ({
  type,
  dept,
  data,
  hasSubMenu,
  menuId,
  toUrl,
  handleArrowClick,
  activeMenus,
  subChapterId,
  contentId,
  flattenedDocuments,
  documentsMap,
  showActionButtons,
  productDoc,
  rootChapter,
  allRootChapterVersions = [],
  isParentDeleted,
  isContentReader,
  setMessage,
  hasCreateProductAccess,
}: ListMenuType & {
  setMessage: SetToastMessageType;
}) => {
  const isTenantAdmin = useAtomValue(isTenantAdminAtom);

  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isHoveringDoc, setIsHoveringDoc] = useState<boolean>(false);

  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);

  const search = useLocation().search;
  const { documentId } = useParams();

  const { status } = useConstructStatus({ search });

  const flatten = (item: any): any => [
    item,
    item.docs.items,
    item.docs.items.map((x: any) => x.docItems.items),
    flatMapDeep(item?.subChapters?.items || [], flatten),
  ];

  const flattenedChildren =
    !isContentReader && type === 'subChapterSubMenu'
      ? [
          ...flatMapDeep(data?.subChapters?.items || [], flatten),
          ...data.docs.items,
          ...data.docs.items.flatMap((x: any) => x.docItems.items),
        ]
      : [];

  const isSomeChildrenEdited = flattenedChildren.some(
    (x: any) => x.status === StatusType.PENDING,
  );

  const diffClassName = isParentDeleted
    ? DELETED_DIFF_CLASSNAME
    : getSideBarDiffClassName({
        status,
        rootChapter,
        data,
        isContentReader,
        defaultClassName: 'text-gray-microsoft-docs',
      });

  const comp = (
    <Link
      key={data.id}
      to={`${toUrl}?status=${status}${
        search.split('view=')[1] ? '&view=read' : ''
      }`}
      className="group w-full flex items-center pr-2 font-medium px-0.5 rounded-md "
      onClick={() => {
        setShowNestedSidebarPanelView(false);
      }}
    >
      <MsText
        variant="medium"
        className={classNames(
          'font-microsoft-docs  pl-2 leading-7 hover:underline',
          {
            'font-semibold bg-gray-fl-21 w-full':
              data.id === subChapterId || data.id === contentId,
          },
          { 'ml-3': data?.docItems },
          diffClassName,
        )}
      >
        {type === 'subChapterSubMenu' ? data.name : data.title}
      </MsText>
    </Link>
  );
  const subChapData = data.subChapters?.items || [];
  const docData = data.docs?.items || [];
  const finalDocument = sortDocuments({
    documents: [...subChapData, ...docData],
    documentsMap,
  });

  const allDocument =
    hasSubMenu ?? data.docs?.items?.length > 0
      ? SubMenu({
          type: 'subChapterSubMenu',
          dept,
          data: finalDocument || [],
          toggle: activeMenus.includes(menuId),
          parentUrl: `/products/documents/${documentId}/subChapter`,
          handleArrowClick,
          activeMenus,
          subChapterId,
          contentId,
          flattenedDocuments,
          documentsMap,
          showActionButtons,
          productDoc,
          rootChapter,
          allRootChapterVersions,
          isParentDeleted: isParentDeleted || data.shouldBeDeleted === true,
          isContentReader,
          status,
          setMessage,
          hasCreateProductAccess,
        })
      : [];

  const returnElements = allDocument?.sort(
    (a, b) =>
      Number.parseFloat(a.orderFloat ? a.orderFloat : '') -
      Number.parseFloat(b.orderFloat ? b.orderFloat : ''),
  );
  const finalReturnElement = returnElements?.map((element) => element.comp);

  const isSomeRootChapVersionSubmittedForApproval = allRootChapterVersions.some(
    (x) => x?.status === StatusType.PENDING_APPROVAL,
  );

  const selectedSubChapterRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (data.id === subChapterId || data.id === contentId) {
      selectedSubChapterRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [contentId, data, subChapterId]);

  return (
    <li ref={selectedSubChapterRef}>
      <div
        className={`${type} xl:relative`}
        style={{
          display: 'flex',
          padding: '1px 18px',
          paddingLeft: `${dept === 1 ? dept * 12 : dept * 18}px`,
          alignItems: 'center',
        }}
        onMouseEnter={() => {
          if (type === 'subChapterSubMenu') {
            setIsHovering(true);
          }
          if (type === 'contentSubMenu') {
            setIsHoveringDoc(true);
          }
        }}
        onMouseLeave={() => {
          if (type === 'subChapterSubMenu') {
            setIsHovering(false);
          }
          if (type === 'contentSubMenu') {
            setIsHoveringDoc(false);
          }
        }}
      >
        {type === 'subChapterSubMenu' ? (
          hasSubMenu?.length || data.docs?.items?.length ? (
            <ArrowIcon
              onClick={() => {
                setIsHovering(true);
                handleArrowClick(menuId);
              }}
              toggle={activeMenus.includes(menuId)}
              className="text-0.55 text-gray-fl-17 font-extrabold text-sm leading-6"
            />
          ) : (
            <ArrowIcon
              toggle={false}
              className="text-0.55 text-gray-fl-17 font-extrabold text-sm leading-6"
            />
          )
        ) : (
          <div className="h-1 text-gray-500 mr-1.25" />
        )}
        {comp}
        {!rootChapter?.shouldBeDeleted &&
          !data.shouldBeDeleted &&
          !isHovering &&
          isSomeChildrenEdited &&
          !search.split('view=')[1] && (
            <MsIcon
              iconName="LocationFill"
              className={`${NORMAL_DIFF_CLASSNAME} mr-2`}
            />
          )}
        {isHovering && (
          <>
            {!rootChapter?.shouldBeDeleted &&
              !data.shouldBeDeleted &&
              isSomeChildrenEdited &&
              !search.split('view=')[1] && (
                <MsIcon
                  iconName="LocationFill"
                  className={`${NORMAL_DIFF_CLASSNAME} mr-2`}
                />
              )}

            {showActionButtons && (
              <Menu>
                <MenuTrigger disableButtonEnhancement key={data?.id}>
                  <Button
                    className="font-bold rounded-full "
                    appearance="transparent"
                    icon={<MoreVertical20Regular className="" />}
                    onClick={async () => {
                      const isAuthenticated =
                        await isCurrentUserAuthenticated();
                      if (!isAuthenticated) {
                        generateSessionTimeoutToast();
                      }
                    }}
                  />
                </MenuTrigger>

                <MenuPopover className="">
                  <MenuList>
                    {hasCreateProductAccess && (
                      <>
                        {!data.shouldBeDeleted && !search.split('view=')[1] && (
                          <AddChapterButton
                            isParentDeleted={isParentDeleted}
                            isSomeRootChapVersionSubmittedForApproval={
                              isSomeRootChapVersionSubmittedForApproval
                            }
                            documentId={documentId!}
                            chapter={data}
                            setIsHovering={setIsHovering}
                          />
                        )}
                        {!data.shouldBeDeleted && !search.split('view=')[1] && (
                          <EditChapterButton
                            isParentDeleted={isParentDeleted}
                            isSomeRootChapVersionSubmittedForApproval={
                              isSomeRootChapVersionSubmittedForApproval
                            }
                            status={status}
                            documentsMap={documentsMap}
                            chapter={data}
                            setIsHovering={setIsHovering}
                          />
                        )}
                        {!data.shouldBeDeleted && !search.split('view=')[1] && (
                          <AddContentButton
                            isParentDeleted={isParentDeleted}
                            isSomeRootChapVersionSubmittedForApproval={
                              isSomeRootChapVersionSubmittedForApproval
                            }
                            documentId={documentId!}
                            chapter={data}
                            setIsHovering={setIsHovering}
                          />
                        )}

                        {!search.split('view=')[1] && (
                          <DeleteOrDiscardChapterButton
                            isParentDeleted={isParentDeleted}
                            isSomeRootChapVersionSubmittedForApproval={
                              isSomeRootChapVersionSubmittedForApproval
                            }
                            status={status}
                            documentsMap={documentsMap}
                            chapter={data}
                            setIsHovering={setIsHovering}
                          />
                        )}
                      </>
                    )}
                    {data.status === StatusType.PUBLISHED &&
                      status === StatusType.PUBLISHED && (
                        <ExportChapterButton
                          rootChapter={rootChapter}
                          chapter={data}
                          setIsHovering={setIsHovering}
                        />
                      )}
                    {data.status === StatusType.PUBLISHED &&
                      status === StatusType.PUBLISHED &&
                      isTenantAdmin && (
                        <ClearCacheAndExportChapterButton
                          rootChapter={rootChapter}
                          chapter={data}
                          setIsHovering={setIsHovering}
                        />
                      )}
                  </MenuList>
                </MenuPopover>
              </Menu>
            )}
          </>
        )}
        {isHoveringDoc && (
          <>
            {!rootChapter?.shouldBeDeleted &&
              !data.shouldBeDeleted &&
              isSomeChildrenEdited &&
              !search.split('view=')[1] && (
                <MsIcon
                  iconName="LocationFill"
                  className={`${NORMAL_DIFF_CLASSNAME} mr-10`}
                />
              )}

            {showActionButtons && (
              <Menu>
                <MenuTrigger>
                  <Button
                    className="font-bold rounded-full "
                    appearance="transparent"
                    icon={<MoreVertical20Regular className="" />}
                    onClick={async () => {
                      const isAuthenticated =
                        await isCurrentUserAuthenticated();
                      if (!isAuthenticated) {
                        generateSessionTimeoutToast();
                      }
                    }}
                  />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    {hasCreateProductAccess && (
                      <>
                        {!data.shouldBeDeleted && !search.split('view=')[1] && (
                          <EditContentButton
                            isParentDeleted={isParentDeleted}
                            isSomeRootChapVersionSubmittedForApproval={
                              isSomeRootChapVersionSubmittedForApproval
                            }
                            status={status}
                            documentsMap={documentsMap}
                            doc={data}
                            setIsHoveringDoc={setIsHoveringDoc}
                          />
                        )}

                        {!search.split('view=')[1] && (
                          <DeleteOrDiscardContentButton
                            isParentDeleted={isParentDeleted}
                            isSomeRootChapVersionSubmittedForApproval={
                              isSomeRootChapVersionSubmittedForApproval
                            }
                            status={status}
                            documentsMap={documentsMap}
                            doc={data}
                            setIsHoveringDoc={setIsHoveringDoc}
                          />
                        )}
                      </>
                    )}
                    {status === StatusType.PUBLISHED && (
                      <ExportDocButton
                        rootChapter={rootChapter}
                        doc={data}
                        setIsHoveringDoc={setIsHoveringDoc}
                      />
                    )}
                    {data.status === StatusType.PUBLISHED &&
                      status === StatusType.PUBLISHED &&
                      isTenantAdmin && (
                        <ClearCacheAndExportDocButton
                          rootChapter={rootChapter}
                          doc={data}
                          setIsHoveringDoc={setIsHoveringDoc}
                        />
                      )}
                  </MenuList>
                </MenuPopover>
              </Menu>
            )}
          </>
        )}
      </div>
      {finalReturnElement}
    </li>
  );
};

const SubMenu = ({
  dept,
  data,
  toggle,
  handleArrowClick,
  activeMenus,
  subChapterId,
  contentId,
  flattenedDocuments,
  documentsMap,
  showActionButtons,
  productDoc,
  rootChapter,
  allRootChapterVersions = [],
  isParentDeleted,
  isContentReader,
  setMessage,
  hasCreateProductAccess,
}: SubMenuType & { setMessage: any }) => {
  const { documentId } = useParams();
  if (!toggle) {
    return null;
  }

  const newDept = dept + 1;

  const dataArray: { orderFloat: any; comp: JSX.Element }[] = [];
  const contentURL = `/products/documents/${documentId}/content`;
  const subChaptersURL = `/products/documents/${documentId}/subChapter`;
  data.forEach((menu: any) => {
    const menuId = menu.id;
    dataArray.push({
      orderFloat: menu.orderFloat ? menu.orderFloat : `100000`,
      comp: (
        <ListMenu
          type={'docItems' in menu ? 'contentSubMenu' : 'subChapterSubMenu'}
          toUrl={`${'docItems' in menu ? contentURL : subChaptersURL}/${
            menu.id
          }`}
          dept={newDept}
          data={menu}
          hasSubMenu={menu.subChapters?.items}
          menuId={menuId}
          key={menuId}
          handleArrowClick={handleArrowClick}
          activeMenus={activeMenus}
          subChapterId={subChapterId}
          contentId={contentId}
          flattenedDocuments={flattenedDocuments}
          documentsMap={documentsMap}
          showActionButtons={showActionButtons}
          productDoc={productDoc}
          rootChapter={rootChapter}
          allRootChapterVersions={allRootChapterVersions}
          isParentDeleted={isParentDeleted}
          isContentReader={isContentReader}
          setMessage={setMessage}
          hasCreateProductAccess={hasCreateProductAccess}
        />
      ),
    });
  });

  return dataArray.length > 0 ? dataArray : [];
};

const MultiLevelSideBar = ({
  rootChapter,
  productDoc,
  allRootChapterVersions = [],
  menus,
  docs,
  flattenedDocuments,
  documentsMap,
  hasCreateProductAccess,
  setMessage,
}: MultiLevelSideBarType & {
  setMessage: SetToastMessageType;
}) => {
  const [activeMenus, setActiveMenus] = useState<string[]>([]);

  const isTenantAdmin = useAtomValue(isTenantAdminAtom);
  const [breadCrumbString, setBreadCrumbString] = useAtom(nestedBreadCrumbAtom);

  const { documentId } = useParams();
  const { pathname } = useLocation();

  const { subChapterId, contentId } = getSubChapterOrContentId({ pathname });

  const { isContentReader } = useIsContentReader();
  const { hasSomeDocumentPortalAccess } = useGetCurrentUserAccess();

  const showActionButtons =
    isTenantAdmin || hasSomeDocumentPortalAccess
      ? rootChapter?.status === StatusType.PENDING ||
        rootChapter?.status === StatusType.PUBLISHED
      : false;

  // Custom hook to generate a breadCrumbString
  useGenerateBreadCrumbString({
    subChapterId,
    contentId,
    menus,
    docs,
    rootChapter,
    setBreadCrumbString,
  });

  // Custom hook to automatically un-collapse the nested sidebar on full page reload (or) when subChapterId / contentId changes.
  useAutoUnCollapseNestedSideBar({
    breadCrumbString,
    setActiveMenus,
    contentId,
    subChapterId,
  });

  const handleArrowClick = (menuId: string) => {
    const newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuId)) {
      const index = newActiveMenus.indexOf(menuId);
      if (index > -1) {
        newActiveMenus.splice(index, 1);
      }
    } else {
      newActiveMenus.push(menuId);
    }

    setActiveMenus(newActiveMenus);
  };

  const finalDocument = sortDocuments({
    documents: [...menus, ...docs],
    documentsMap,
  });

  const finalArray =
    finalDocument.length > 0
      ? finalDocument.map((doc: any) => {
          const dept = 1;
          return 'docItems' in doc
            ? {
                order: Number.parseFloat(
                  doc.orderFloat ? doc.orderFloat : '100000',
                ),
                comp: (
                  <ListMenu
                    type="contentSubMenu"
                    dept={dept}
                    toUrl={`/products/documents/${documentId}/content/${doc.id}`}
                    data={doc}
                    menuId={doc.id}
                    key={doc.id}
                    handleArrowClick={handleArrowClick}
                    activeMenus={activeMenus}
                    subChapterId={subChapterId}
                    contentId={contentId}
                    flattenedDocuments={flattenedDocuments}
                    documentsMap={documentsMap}
                    showActionButtons={showActionButtons}
                    rootChapter={rootChapter}
                    productDoc={productDoc}
                    allRootChapterVersions={allRootChapterVersions}
                    isParentDeleted={rootChapter?.shouldBeDeleted === true}
                    isContentReader={isContentReader}
                    setMessage={setMessage}
                    hasCreateProductAccess={hasCreateProductAccess}
                  />
                ),
              }
            : {
                order: Number.parseFloat(
                  doc.orderFloat ? doc.orderFloat : '100000',
                ),
                comp: (
                  <ListMenu
                    type="subChapterSubMenu"
                    dept={dept}
                    toUrl={`/products/documents/${documentId}/subChapter/${doc.id}`}
                    data={doc}
                    hasSubMenu={doc.subChapters?.items}
                    menuId={doc.id}
                    key={doc.id}
                    handleArrowClick={handleArrowClick}
                    activeMenus={activeMenus}
                    subChapterId={subChapterId}
                    contentId={contentId}
                    flattenedDocuments={flattenedDocuments}
                    documentsMap={documentsMap}
                    showActionButtons={showActionButtons}
                    rootChapter={rootChapter}
                    productDoc={productDoc}
                    allRootChapterVersions={allRootChapterVersions}
                    isParentDeleted={rootChapter?.shouldBeDeleted === true}
                    isContentReader={isContentReader}
                    setMessage={setMessage}
                    hasCreateProductAccess={hasCreateProductAccess}
                  />
                ),
              };
        })
      : [];

  const newArray = finalArray.sort((a: any, b: any) => a.order - b.order);
  const nestedElement = newArray.map((a: any) => a.comp);

  return <>{nestedElement}</>;
};

export default MultiLevelSideBar;
