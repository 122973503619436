import Flex from 'components/Flex';
import Text from 'components/Text';

import { Lead } from 'API';

import { useLeadsRevenue } from 'hooks/useLeadManagementDashboard';

export const ChartFour = ({ leads }: { leads: Partial<Lead>[] }) => {
  const {
    leadsRevenue: {
      qualifiedAndApprovedLeadsBudget,
      qualifiedAndApprovedAndDisquallifiedLeadsBudget,
    },
  } = useLeadsRevenue({ leads });

  return (
    <Flex direction="col" className=" my-2">
      <Flex direction="row" height="h-8">
        <Text className="text-base flex-1 flex items-center pr-2" weight={600}>
          Value
        </Text>
      </Flex>
      <div className="border-2 flex flex-col items-start justify-center w-full h-[90vw] sm:h-full mt-5">
        <div className="flex flex-wrap w-full px-6 mb-8 items-center">
          <Text weight={500} className="text-base mr-2">
            Total leads:
          </Text>
          <Text weight={700} className="text-4xl break-all">
            {/* {overallLeadsBudget}  */}
            {qualifiedAndApprovedAndDisquallifiedLeadsBudget}
          </Text>
        </div>
        <div className="flex flex-wrap w-full px-6 items-center">
          <Text weight={500} className="text-base text-[#0E613F] mr-2">
            Qualified/Approved leads:
          </Text>
          <Text weight={700} className="text-4xl break-all text-[#0E613F]">
            {qualifiedAndApprovedLeadsBudget}
          </Text>
        </div>
      </div>
    </Flex>
  );
};

export default ChartFour;
