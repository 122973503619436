const getSubChapterOrContentId = ({ pathname }: { pathname: string }) => {
  const pathNamesArr = pathname.split('/');
  const isSubChapterOrContentPath = pathNamesArr[pathNamesArr.length - 2];
  let subChapterId = '';
  let contentId = '';

  if (isSubChapterOrContentPath) {
    if (isSubChapterOrContentPath === 'subChapter') {
      subChapterId = pathNamesArr[pathNamesArr.length - 1];
    }

    if (isSubChapterOrContentPath === 'content') {
      contentId = pathNamesArr[pathNamesArr.length - 1];
    }
  }

  return {
    subChapterId,
    contentId,
  };
};

export default getSubChapterOrContentId;
