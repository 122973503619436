import { DetailsList, CheckboxVisibility } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

import Text from 'components/Text';

import { Lead, LeadOverallStatusType } from 'API';

import { useGetLeadsBasedOnStatus } from 'hooks/useLeadManagementDashboard';

import getColumns from 'helpers/utils/leadManagementDashboard/getColumns';

export const DetailsListTwo = ({ leads }: { leads: Partial<Lead>[] }) => {
  const navigate = useNavigate();

  const columns = getColumns();

  const { filteredLeads: qualifiedLeads } = useGetLeadsBasedOnStatus({
    leads,
    status: LeadOverallStatusType.QUALIFIED,
  });

  return (
    <div className="sm:ml-2 w-full md:w-45 mt-8 md:mt-0 flex-auto md:flex-1">
      <Text
        className="text-base cursor-pointer"
        weight={600}
        onClick={() => {
          navigate(`/leads?status=${LeadOverallStatusType.QUALIFIED}`);
        }}
      >
        Qualified Leads
      </Text>
      <DetailsList
        className="border-2 mt-4 bg-black h-96 overflow-hidden hide-scroll"
        items={qualifiedLeads}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
        onActiveItemChanged={(item) => {
          navigate(`/leads?leadId=${item?.id}`);
        }}
        onShouldVirtualize={() => false}
      />
    </div>
  );
};

export default DetailsListTwo;
