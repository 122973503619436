import { Icon, TooltipHost, Link, type INavLink } from '@fluentui/react';
import { useAtomValue } from 'jotai';
import { memo } from 'react';

import Flex from 'components/Flex';

import { currentUserDetailsAtom } from 'atoms/modals';

const CollapsedSideBar = memo(
  ({
    handleSidebarOpen,
    flattenedNavItems,
  }: {
    handleSidebarOpen: () => void;
    flattenedNavItems: INavLink[] | undefined;
  }) => {
    const currentUserDetails = useAtomValue(currentUserDetailsAtom);

    return (
      <Flex
        height="h-auto"
        width="w-16"
        direction="col"
        alignItems="center"
        className="hidden lg:flex fixed h-screen fix"
        space="sapce-y-5"
        backgroundColor="bg-gray-f1-23"
        style={{ zIndex: 1000 }}
      >
        {currentUserDetails && (
          <>
            <Icon
              iconName="GlobalNavButton"
              className="w-16 text-base text-center text-gray-f1-25 my-5 cursor-pointer"
              onClick={handleSidebarOpen}
            />
            {flattenedNavItems?.map((navItem) => {
              return navItem.links === undefined ? (
                <TooltipHost
                  placeholder="right"
                  directionalHint={12}
                  content={navItem.name}
                >
                  <Link
                    href={navItem.url}
                    className="relative mt-0 h-10 w-full text-center show flex justify-center items-center py-1"
                    style={{ textDecoration: 'none', color: '#323130' }}
                  >
                    <Icon
                      iconName={navItem.icon}
                      className="w-16 text-base"
                      style={{ color: '#323130' }}
                    />
                  </Link>
                </TooltipHost>
              ) : (
                <Link
                  href={navItem.url}
                  className="relative mt-0 h-10 w-full text-center show flex justify-center items-center py-1"
                  style={{ textDecoration: 'none', color: '323130' }}
                >
                  <Icon
                    iconName={navItem.icon}
                    className="w-6 text-base"
                    style={{ color: '#323130' }}
                  />
                  <div className="absolute top-0 left-16 hide bg-gray-f1-23 w-60 text-sm text-gray-f1-25">
                    <Link
                      style={{
                        textDecoration: 'none',
                        width: '100%',
                        color: '323130',
                      }}
                      href={navItem.url}
                    >
                      <div className="h-10 pl-2 w-full text-left bg-gray-fl-32 flex items-center text-gray-f1-25">
                        {navItem.name}
                      </div>
                    </Link>
                    {navItem.links.map((link) => {
                      return (
                        <Link
                          style={{
                            textDecoration: 'none',
                            color: '323130',
                          }}
                          href={link.url}
                        >
                          <div className="h-10 pl-2 text-left hover:bg-gray-fl-32 flex items-center text-gray-f1-25">
                            {link.name}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </Link>
              );
            })}
          </>
        )}
      </Flex>
    );
  },
);

export default CollapsedSideBar;
