import { DetailsList, CheckboxVisibility } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

import Text from 'components/Text';

import { Lead } from 'API';

import getColumns from 'helpers/utils/leadManagementDashboard/getColumns';

export const DetailsListOne = ({ leads }: { leads: Partial<Lead>[] }) => {
  const navigate = useNavigate();

  const columns = getColumns();

  return (
    <div className="mr-2 w-full md:w-45 flex-auto	md:flex-1">
      <div className="flex justify-between flex-col md:flex-row">
        <Text
          className="text-base cursor-pointer"
          weight={600}
          onClick={() => {
            navigate(`/leads`);
          }}
        >
          New Leads
        </Text>
      </div>
      <DetailsList
        className="border-2 mt-4 bg-black h-96 overflow-hidden hide-scroll"
        items={leads}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
        onActiveItemChanged={(item) => {
          navigate(`/leads?leadId=${item?.id}`);
        }}
        onShouldVirtualize={() => false}
      />
    </div>
  );
};

export default DetailsListOne;
