import type { GraphQLQuery } from '@aws-amplify/api';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';

import type { Doc, ListDocsQuery, ModelDocFilterInput } from 'API';

import { tenantAtom } from 'atoms/modals';
import getNonNullableList from 'helpers/utils/getNonNullableList';

import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseDocsType =
  | ({
      filter?: ModelDocFilterInput | null;
    } & UseInfiniteQueryOptions)
  | null
  | void;

const customListDocs = /* GraphQL */ `
  query CustomListDocs(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelDocFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocs(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        status
        title
        description
        orderFloat
        createdByUserId
        referenceDocId
        lastModifiedByUserId
        approvedByUserId
        rootChapterId
        chapterId
        docItems {
          items {
            id
            version
            status
            title
            description
            orderFloat
            itemType
            file {
              bucket
              region
              key
            }
            createdByUserId
            lastModifiedByUserId
            approvedByUserId
            rootChapterId
            docId
            shouldBeDeleted
            createdAt
            updatedAt
          }
          nextToken
        }
        shouldBeDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const useDocsSource = (props: IUseDocsType = {}) => {
  const filter = props?.filter ?? null;

  const queryKey = props?.queryKey ?? [];

  const enabled = props?.enabled ?? true;

  const staleTime =
    props?.staleTime ?? toMilliseconds({ hours: 0, minutes: 0, seconds: 20 }); // 20secs

  return useInfiniteQuery({
    queryKey: [`docs`, filter, ...queryKey],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<GraphQLQuery<ListDocsQuery>>({
        query: customListDocs,
        variables: filter
          ? { filter, limit: 1_000_000, nextToken: pageParam }
          : { limit: 1_000_000, nextToken: pageParam },
      });
    },
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.listDocs?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
    enabled,
    staleTime,
  });
};

const useDocs = (props: IUseDocsType = {}) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const filter = props?.filter ?? null;

  const enabled = (props?.enabled ?? true) && !!tenantId;

  let sourceFilter: ModelDocFilterInput | null = null;

  if (tenantId) {
    const defaultFilter: ModelDocFilterInput | null = {
      tenantId: {
        eq: tenantId,
      },
    };

    sourceFilter = filter
      ? {
          ...filter,
          ...defaultFilter,
        }
      : { ...defaultFilter };
  }

  const result = useDocsSource({
    ...props,
    filter: sourceFilter,
    enabled,
  });

  const docsSource = useMemo(() => {
    const pages = result.data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.listDocs?.items),
    );

    return tempItems as Partial<Doc>[];
  }, [result.data?.pages]);

  return {
    docsSource,
    ...result,
  };
};

export const useInfiniteDocs = (props: IUseDocsType = {}) => {
  const result = useDocs(props);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = result;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      try {
        void fetchNextPage();
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return result;
};

export default useDocs;
