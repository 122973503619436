import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import {
  allSalesForceOrganizationsAtom,
  allSalesForceProductsAtom,
  userSalesForceOrganizationsAtom,
} from 'atoms/modals';

import { useConstructUserSalesForceOrganizations } from 'hooks/useSalesForceData';
import { useInfiniteSFAccounts } from 'hooks/useSFAccounts';
import { useInfiniteSFProducts } from 'hooks/useSFProducts';

import { QueryEnabledType } from 'types';

export const useSetAllSalesForceOrganizations = ({
  isQueryEnabled = true,
  customQueryKeys = [],
}: QueryEnabledType) => {
  const setAllSalesForceOrganizations = useSetAtom(
    allSalesForceOrganizationsAtom,
  );

  const { sfAccounts } = useInfiniteSFAccounts({
    enabled: isQueryEnabled,
    queryKey: customQueryKeys,
  });

  useEffect(() => {
    setAllSalesForceOrganizations(sfAccounts);
  }, [sfAccounts, setAllSalesForceOrganizations]);
};

export const useSetUserSalesForceOrganizations = ({
  organizationId,
  isQueryEnabled = !!organizationId,
  customQueryKeys = [],
}: {
  organizationId: string | null | undefined;
} & QueryEnabledType) => {
  const setUserSalesForceOrganizations = useSetAtom(
    userSalesForceOrganizationsAtom,
  );

  const [userSalesForceOrganizations] = useConstructUserSalesForceOrganizations(
    { organizationId, isQueryEnabled, customQueryKeys },
  );

  useEffect(() => {
    setUserSalesForceOrganizations(userSalesForceOrganizations);
  }, [userSalesForceOrganizations, setUserSalesForceOrganizations]);
};

export const useSetAllSalesForceProducts = ({
  isQueryEnabled = true,
  customQueryKeys = [],
}: QueryEnabledType) => {
  const setAllSalesForceProducts = useSetAtom(allSalesForceProductsAtom);

  const { sfProducts } = useInfiniteSFProducts({
    enabled: isQueryEnabled,
    queryKey: customQueryKeys,
  });

  useEffect(() => {
    setAllSalesForceProducts(sfProducts);
  }, [sfProducts, setAllSalesForceProducts]);
};
