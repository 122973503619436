export const MANAGEMENT = 'Management';
export const SUPPORT_PORTAL = 'Support Portal';
export const DOCUMENTATION_PORTAL = 'Documentation Portal';
export const READER = 'Content Reader';
export const CREATOR = 'Content Creator';
export const APPROVER = 'Content Approver';
export const SUPPORT_ENGINEER = 'Support Engineer';
export const SUPPORT_LEAD = 'Support lead';
export const CUSTOMER = 'Customer';
export const PARTNER = 'Partner';
export const PRESALES = 'Presales';
export const DISTRIBUTOR = 'Distributor';
export const EMPLOYEE = 'Employee';
export const QA_TEAM = 'QA Team';
export const ORGANIZATION_TYPES: Record<string, string> = {
  D: DISTRIBUTOR,
  P: PARTNER,
  C: CUSTOMER,
  E: EMPLOYEE,
};

export const SALES_TEAM = 'Sales Team';
export const SALES_DIRECTOR = 'Sales Director';
export const SALES_MANAGEMENT = 'Management';
export const LEAD_MANAGEMENT = 'Lead Management';
export const LEAD_MANAGEMENT_PORTAL = 'LEAD_MANAGEMENT_PORTAL';
