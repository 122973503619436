import { useState } from 'react';
import ReactPlayer from 'react-player/file';

import Flex from 'components/Flex';

import { StatusType } from 'API';

import { DocItemPageType } from 'types/documents';

import { DIFF_BG_CLASSNAME } from 'consts/diffConstants';

import useIsContentReader from 'hooks/useIsContentReader';
import useS3FileUrl from 'hooks/useS3FileUrl';

import DocItemModifier from './DocItemModifier';

import './videoBlock.css';

const VideoBlock = ({
  doc,
  docItem,
  documentsMap,
  rootChapter,
  allRootChapterVersions = [],
  isAnyAncestorDeleted,
  setMessage,
}: DocItemPageType) => {
  const [isHoveringDocItem, setIsHoveringDocItem] = useState(false);

  const { isContentReader } = useIsContentReader();

  const fileUrl = useS3FileUrl(docItem);

  let bgColor = '';

  if (
    !isContentReader &&
    (docItem.status === StatusType.PENDING || isAnyAncestorDeleted)
  ) {
    bgColor = DIFF_BG_CLASSNAME;
  }

  return (
    <div
      id={docItem.id}
      className="flex flex-row justify-between w-full overflow-hidden "
      onMouseEnter={() => {
        setIsHoveringDocItem(true);
      }}
      onMouseLeave={() => {
        setIsHoveringDocItem(false);
      }}
    >
      <Flex rounded="rounded-md" className={bgColor}>
        <Flex>
          <Flex margin="mt-5" width="lg:w-10/12 md:w-10/12 sm: w-full">
            {fileUrl && <ReactPlayer url={fileUrl} controls />}
          </Flex>
        </Flex>
        {isHoveringDocItem && (
          <DocItemModifier
            doc={doc}
            docItem={docItem}
            documentsMap={documentsMap}
            rootChapter={rootChapter}
            allRootChapterVersions={allRootChapterVersions}
            isAnyAncestorDeleted={isAnyAncestorDeleted}
            setMessage={setMessage}
          />
        )}
      </Flex>
    </div>
  );
};

export default VideoBlock;
