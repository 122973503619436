import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { ActiveStatusType } from 'API';

import { currentUserDetailsAtom, groupsByOrganizationAtom } from 'atoms/modals';

import {
  CUSTOMER,
  DISTRIBUTOR,
  MANAGEMENT,
  PARTNER,
  PRESALES,
  SUPPORT_ENGINEER,
  SUPPORT_LEAD,
  QA_TEAM,
} from 'consts/roles';

type IUseGetCurrentUserAccessProps = {
  productDocId?: string | null;
} | null | void;

const useGetCurrentUserAccess = (props: IUseGetCurrentUserAccessProps) => {
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);
  const groupsByOrganization = useAtomValue(groupsByOrganizationAtom);

  const productDocId = useMemo(
    () => props?.productDocId ?? null,
    [props?.productDocId],
  );

  const userGroups = useMemo(
    () =>
      currentUserDetails?.groups?.items.filter(
        (group) => group?.groups.status === ActiveStatusType.ACTIVE,
      ) ?? [],
    [currentUserDetails?.groups?.items],
  );

  const allApplicationRoles = useMemo(
    () => [
      ...userGroups.flatMap(
        (userGroup) => userGroup?.groups.applicationRoles?.items ?? [],
      ),
      ...groupsByOrganization.flatMap(
        (userGroup) => userGroup.applicationRoles?.items ?? [],
      ),
    ],
    [groupsByOrganization, userGroups],
  );

  const productDocsAccessMap = useMemo(() => {
    const tempProductDocsAccessMap: Record<
      string,
      string[] | null | undefined
    > = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const applicationRole of allApplicationRoles) {
      const productDocs = applicationRole?.productDocs ?? [];

      const roleId = applicationRole?.roleId;

      if (roleId) {
        // eslint-disable-next-line no-restricted-syntax
        for (const productDoc of productDocs) {
          const access = productDoc
            ? tempProductDocsAccessMap[productDoc] ?? []
            : [];

          if (productDoc) {
            tempProductDocsAccessMap[productDoc] = [...access, roleId];
          }
        }
      }
    }

    return tempProductDocsAccessMap;
  }, [allApplicationRoles]);

  const hasSomeDocumentPortalAccess = useMemo(
    () =>
      allApplicationRoles.some(
        (x) => x?.applicationId === 'DOCUMENTATION_PORTAL',
      ),
    [allApplicationRoles],
  );

  const hasSomeSupportPortalAccess = useMemo(
    () =>
      allApplicationRoles.some((x) => x?.applicationId === 'SUPPORT_PORTAL'),
    [allApplicationRoles],
  );

  const {
    managementProducts,
    supportLeadProducts,
    isSupportEngineer,
    qaProducts,
  } = useMemo(() => {
    const tempManagementProducts: string[] = [];
    const tempSupportLeadProducts: string[] = [];
    let tempIsSupportEngineer: boolean = false;
    const tempQAProducts: string[] = [];

    if (hasSomeSupportPortalAccess) {
      Object.keys(productDocsAccessMap).forEach((key) => {
        const access = productDocsAccessMap[key] ?? [];

        if (access.includes(MANAGEMENT)) {
          tempManagementProducts.push(key);
        } else if (access.includes(SUPPORT_LEAD)) {
          tempSupportLeadProducts.push(key);
        } else if (access.includes(SUPPORT_ENGINEER)) {
          tempIsSupportEngineer = true;
        } else if (access.includes(QA_TEAM)) {
          tempQAProducts.push(key);
        }
      });
    }

    return {
      managementProducts: tempManagementProducts,
      supportLeadProducts: tempSupportLeadProducts,
      isSupportEngineer: tempIsSupportEngineer,
      qaProducts: tempQAProducts,
    };
  }, [hasSomeSupportPortalAccess, productDocsAccessMap]);

  const productDocsPermissions = useMemo(
    () =>
      new Set(
        Object.keys(productDocsAccessMap).flatMap(
          (k) => productDocsAccessMap[k],
        ),
      ),
    [productDocsAccessMap],
  );

  const hasSomeApproverAccess = useMemo(
    () => productDocsPermissions.has('approver'),
    [productDocsPermissions],
  );

  const hasSomeCreatorAccess = useMemo(
    () => productDocsPermissions.has('creator'),
    [productDocsPermissions],
  );

  const hasSomeReaderAccess = useMemo(
    () => productDocsPermissions.has('reader'),
    [productDocsPermissions],
  );

  const hasSomeDistributorAccess = useMemo(
    () => productDocsPermissions.has(DISTRIBUTOR),
    [productDocsPermissions],
  );

  const hasSomePartnerAccess = useMemo(
    () => productDocsPermissions.has(PARTNER),
    [productDocsPermissions],
  );

  const hasSomeCustomerAccess = useMemo(
    () => productDocsPermissions.has(CUSTOMER),
    [productDocsPermissions],
  );

  const hasSomePreSalesAccess = useMemo(
    () => productDocsPermissions.has(PRESALES),
    [productDocsPermissions],
  );

  const hasSomeManagementAccess = useMemo(
    () => productDocsPermissions.has(MANAGEMENT),
    [productDocsPermissions],
  );

  const hasSomeSupportLeadAccess = useMemo(
    () => productDocsPermissions.has(SUPPORT_LEAD),
    [productDocsPermissions],
  );

  const hasSomeSupportEngineerAccess = useMemo(
    () => productDocsPermissions.has(SUPPORT_ENGINEER),
    [productDocsPermissions],
  );

  const hasSomeQAAccess = useMemo(
    () => productDocsPermissions.has(QA_TEAM),
    [productDocsPermissions],
  );

  const hasOnlyDistributorAccess = useMemo(
    () =>
      !hasSomePartnerAccess &&
      !hasSomeCustomerAccess &&
      !hasSomePreSalesAccess &&
      !hasSomeManagementAccess &&
      !hasSomeSupportEngineerAccess &&
      !hasSomeSupportLeadAccess &&
      !hasSomeQAAccess &&
      hasSomeDistributorAccess,
    [
      hasSomeCustomerAccess,
      hasSomeDistributorAccess,
      hasSomeManagementAccess,
      hasSomePartnerAccess,
      hasSomePreSalesAccess,
      hasSomeQAAccess,
      hasSomeSupportEngineerAccess,
      hasSomeSupportLeadAccess,
    ],
  );

  const hasOnlyPartnerAccess = useMemo(
    () =>
      !hasSomeDistributorAccess &&
      !hasSomeCustomerAccess &&
      !hasSomePreSalesAccess &&
      !hasSomeManagementAccess &&
      !hasSomeSupportEngineerAccess &&
      !hasSomeSupportLeadAccess &&
      !hasSomeQAAccess &&
      hasSomePartnerAccess,
    [
      hasSomeCustomerAccess,
      hasSomeDistributorAccess,
      hasSomeManagementAccess,
      hasSomePartnerAccess,
      hasSomePreSalesAccess,
      hasSomeQAAccess,
      hasSomeSupportEngineerAccess,
      hasSomeSupportLeadAccess,
    ],
  );

  const hasOnlyCustomerAccess = useMemo(
    () =>
      !hasSomeDistributorAccess &&
      !hasSomePartnerAccess &&
      !hasSomePreSalesAccess &&
      !hasSomeManagementAccess &&
      !hasSomeSupportEngineerAccess &&
      !hasSomeSupportLeadAccess &&
      !hasSomeQAAccess &&
      hasSomeCustomerAccess,
    [
      hasSomeCustomerAccess,
      hasSomeDistributorAccess,
      hasSomeManagementAccess,
      hasSomePartnerAccess,
      hasSomePreSalesAccess,
      hasSomeQAAccess,
      hasSomeSupportEngineerAccess,
      hasSomeSupportLeadAccess,
    ],
  );

  const access = useMemo(
    () => (productDocId ? productDocsAccessMap[productDocId] ?? [] : []),
    [productDocId, productDocsAccessMap],
  );

  const hasCreateProductAccess = useMemo(
    () => access.includes('creator'),
    [access],
  );

  const hasReadProductAccess = useMemo(
    () => access.includes('reader'),
    [access],
  );

  const hasApproveProductAccess = useMemo(
    () => access.includes('approver'),
    [access],
  );

  return {
    productDocsAccessMap,
    currentUserDetails,

    hasSomeDocumentPortalAccess,
    hasSomeSupportPortalAccess,
    hasSomeQAAccess,

    hasSomeReaderAccess,
    hasSomeCreatorAccess,
    hasSomeApproverAccess,

    hasSomeManagementAccess,
    hasSomeSupportLeadAccess,
    hasSomeSupportEngineerAccess,
    hasSomeDistributorAccess,
    hasSomePartnerAccess,
    hasSomeCustomerAccess,
    hasSomePreSalesAccess,

    hasOnlyDistributorAccess,
    hasOnlyPartnerAccess,
    hasOnlyCustomerAccess,

    managementProducts,
    supportLeadProducts,
    isSupportEngineer,
    qaProducts,

    hasReadProductAccess,
    hasCreateProductAccess,
    hasApproveProductAccess,
  };
};

export default useGetCurrentUserAccess;
