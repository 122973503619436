import type { GraphQLQuery } from '@aws-amplify/api';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import type { CustomGetUserQuery, User } from 'API';

import { tenantAtom } from 'atoms/modals';

import { customGetUser } from 'graphql/custom-queries';

import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseUserType = {
  id: string;
} & UseQueryOptions;

export const useUserSource = (props: IUseUserType) => {
  const id = props.id;

  const queryKey = props.queryKey ?? [];

  const enabled = props.enabled ?? true;

  const staleTime =
    props.staleTime ?? toMilliseconds({ hours: 1, minutes: 0, seconds: 20 }); // 20secs

  return useQuery({
    queryKey: [`user/${id}`, ...queryKey],
    queryFn: () => {
      return API.graphql<GraphQLQuery<CustomGetUserQuery>>({
        query: customGetUser,
        variables: { id },
      });
    },
    enabled,
    staleTime,
  });
};

const useUser = (props: IUseUserType) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const enabled = (props.enabled ?? true) && !!tenantId;

  const result = useUserSource({
    ...props,
    enabled,
  });

  const userSource = useMemo(() => {
    return (result.data?.data?.getUser ?? null) as Partial<User> | null;
  }, [result.data?.data?.getUser]);

  return { userSource, ...result };
};

export default useUser;
