import { Text as MsText } from '@fluentui/react';

import Flex from 'components/Flex';
import Text from 'components/Text';

import useRootChapterPage from 'hooks/useRootChapterPage';

import './document.css';
import { useDocumentOutlet } from '.';

const RootChapter = () => {
  const { rootChapter } = useDocumentOutlet();

  const { diffClassNameForRootChapter, finalReturnElements } =
    useRootChapterPage({ rootChapter });

  return (
    <div className="w-full px-4  pt-16">
      <Flex direction="col" className="gap-3">
        <MsText
          className={diffClassNameForRootChapter}
          style={{
            fontSize: 'clamp(1.875rem,22.1052631579px + 1.6447368421vw,2.5rem)',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            lineHeight: '1',
          }}
        >
          {rootChapter?.name}
        </MsText>
        <Text
          variant="mediumPlus"
          margin="mt-2"
          weight={600}
          className={`${diffClassNameForRootChapter} leading-6.25 whitespace-pre-wrap`}
          as="p"
        >
          {rootChapter?.description}
        </Text>
        <Text
          variant="mediumPlus"
          weight={600}
          className="font-microsoft-docs text-gray-microsoft-docs font-semibold leading-5 antialiased mt-4"
          as="h3"
        >
          In this article
        </Text>
      </Flex>
      <div className="mt-2 mb-4">{finalReturnElements}</div>
    </div>
  );
};

export default RootChapter;
