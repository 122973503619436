import gql from 'graphql-tag';
import { useMutation } from '@tanstack/react-query';
import { WithRequiredProperty } from 'types';

import { CreateDocItemInput, UpdateDocItemInput } from 'API';

import {
  customCreateDocItem,
  customUpdateDocItem,
  customDeleteDocItem,
} from 'graphql/custom-mutations';

import client from 'helpers/utils/client';

type CustomUpdateDocItemInput = Partial<Omit<UpdateDocItemInput, 'id'>> &
  Pick<UpdateDocItemInput, 'id'>;

type CustomCreateDocItemInput = WithRequiredProperty<
  CreateDocItemInput,
  'tenantId'
>;

const createDocItemRequest = (payload: CustomCreateDocItemInput) => {
  const input: CustomCreateDocItemInput = {
    version: payload.version,
    status: payload.status,
    createdByUserId: payload.createdByUserId,
    lastModifiedByUserId: payload.lastModifiedByUserId,
    title: payload.title,
    description: payload.description,
    orderFloat: payload.orderFloat,
    itemType: payload.itemType,
    docId: payload.docId,
    rootChapterId: payload.rootChapterId,
    file: payload.file,
    tenantId: payload.tenantId,
  };

  if (payload.id) {
    input.id = payload.id;
  }

  if (payload.shouldBeDeleted) {
    input.shouldBeDeleted = payload.shouldBeDeleted;
  }

  return client.mutate({
    mutation: gql`
      ${customCreateDocItem}
    `,
    variables: {
      input,
    },
  });
};

const editDocItemRequest = (payload: CustomUpdateDocItemInput) => {
  return client.mutate({
    mutation: gql`
      ${customUpdateDocItem}
    `,
    variables: {
      input: {
        id: payload.id,
        version: payload.version,
        status: payload.status,
        lastModifiedByUserId: payload.lastModifiedByUserId,
        title: payload.title,
        description: payload.description,
        orderFloat: payload.orderFloat,
        itemType: payload.itemType,
        docId: payload.docId,
        file: payload.file,
      },
    },
  });
};

const deleteDocItemRequest = ({
  id,
  version,
}: {
  id: string;
  version: string;
}) =>
  client.mutate({
    mutation: gql`
      ${customDeleteDocItem}
    `,
    variables: {
      input: { id, version },
    },
  });

export const useCreateDocItem = () => useMutation(createDocItemRequest);
export const useUpdateDocItem = () => useMutation(editDocItemRequest);
export const useDeleteDocItem = () => useMutation(deleteDocItemRequest);
