import { useParams } from 'react-router-dom';

import useConstructStatus from 'hooks/useConstructStatus';
import useHistoryLocation from 'hooks/useHistoryLocation';
import useIsContentReader from 'hooks/useIsContentReader';
import useTransformDocuments from 'hooks/useTransformDocuments';

import { Chapter, Doc, StatusType } from 'API';
import flatMapDeep from 'lodash/flatMapDeep';
import constructFinalSubChapterElement from 'helpers/utils/chapterPage';
import { DocumentsMapType } from 'types/Pages/Products';
import { CustomStatusType } from 'types/documents';
import { useMemo } from 'react';

type UseChapterPageType = {
  rootChapter: Partial<Chapter | null>;
  mainDocumentsMap: DocumentsMapType;
};

const getChapter = ({
  rootChapter,
  subChapterId,
  status,
}: {
  rootChapter: Partial<Chapter | null>;
  subChapterId: string;
  status: CustomStatusType;
}) => {
  // Flatten nested array of subchapters underneath rootchapter
  const flatten = (
    item: Partial<Chapter | null>,
  ): Partial<Chapter | null>[] => {
    return [item, ...flatMapDeep(item?.subChapters?.items || [], flatten)];
  };

  // Remove any duplicates based on both id and version of subchapters
  const unique = (items: Partial<Chapter | null>[]) => {
    return [
      ...items
        .reduce((m, r) => {
          const key = `${r?.id}-${r?.version}`; // create the key by combining both props
          return m.has(key) ? m : m.set(key, r); // if key exists skip, if not add to map
        }, new Map())
        .values(),
    ] as typeof items; // get the map values and convert back to array
  };

  const allChaptersUnderRootChapter = unique(flatten(rootChapter));

  const allSubChapterVersions = allChaptersUnderRootChapter.filter(
    (c) => c?.id === subChapterId,
  );

  const publishedSubChapterVersion =
    allSubChapterVersions.find((c) => c?.status === StatusType.PUBLISHED) ||
    null;
  const pendingSubChapterVersion =
    allSubChapterVersions.find((c) => c?.status === StatusType.PENDING) || null;

  const chapter =
    status === StatusType.PUBLISHED
      ? publishedSubChapterVersion
      : status === StatusType.PENDING || status === StatusType.PENDING_APPROVAL
        ? pendingSubChapterVersion || publishedSubChapterVersion
        : null;
  const subChapters: Partial<Chapter | null>[] =
    chapter?.subChapters?.items || [];
  const docs: Partial<Doc | null>[] = chapter?.docs?.items || [];

  return {
    chapter,
    subChapters,
    docs,
  };
};

const useChapterPage = ({
  rootChapter,
  mainDocumentsMap,
}: UseChapterPageType) => {
  const [, location] = useHistoryLocation();

  const search = location.search;

  const { status } = useConstructStatus({ search });

  const { documentId, subChapterId } = useParams();

  const { isContentReader } = useIsContentReader();

  const { chapter, subChapters, docs } = useMemo(() => {
    return getChapter({
      rootChapter,
      subChapterId: subChapterId as string,
      status,
    });
  }, [rootChapter, subChapterId, status]);

  const { filteredSubChapters, filteredDocs, documentsMap } =
    useTransformDocuments({ subChapters, docs });

  const { diffClassNameForChapter, finalReturnElement } =
    constructFinalSubChapterElement({
      mainDocumentsMap,
      subChapterId: subChapterId as string,
      isContentReader,
      rootChapter,
      filteredSubChapters,
      filteredDocs,
      documentsMap,
      status,
      search,
      documentId: documentId as string,
      chapter,
    });

  return {
    chapter,

    diffClassNameForChapter,

    finalReturnElement,
  };
};

export default useChapterPage;
