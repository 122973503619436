import { Link } from 'react-router-dom';

import Flex from 'components/Flex';
import Text from 'components/Text';

import { Chapter, StatusType } from 'API';

import {
  CustomChapter,
  CustomChapters,
  CustomDocs,
  CustomStatusType,
} from 'types/documents';
import { DocumentsMapType } from 'types/Pages/Products';

import getDiffClassName from 'helpers/utils/getDiffClassName';
import sortDocuments from 'helpers/utils/sortDocuments';

const constructFinalSubChapterElement = ({
  mainDocumentsMap,
  subChapterId,
  isContentReader,
  rootChapter,
  filteredSubChapters,
  filteredDocs,
  documentsMap,
  status,
  search,
  documentId,
  chapter,
}: {
  mainDocumentsMap: DocumentsMapType;
  subChapterId: string;
  isContentReader: boolean;
  rootChapter: Partial<Chapter | null>;
  filteredSubChapters: CustomChapters;
  filteredDocs: CustomDocs;
  documentsMap: DocumentsMapType;
  status: CustomStatusType;
  search: string;
  documentId: string;
  chapter: Partial<Chapter> | null;
}) => {
  const allDocumentVersions = mainDocumentsMap[subChapterId] || [];
  const currentDocumentVersion = (allDocumentVersions.find(
    (x) => x.version === chapter?.version,
  ) || null) as CustomChapter;

  let isAnyAncestorDeleted = false;

  if (!isContentReader) {
    const isRootChapterOrCurrentDocumentDeleted =
      rootChapter?.shouldBeDeleted === true ||
      currentDocumentVersion?.shouldBeDeleted === true;

    if (isRootChapterOrCurrentDocumentDeleted) {
      isAnyAncestorDeleted = true;
    } else {
      let ancestorId = currentDocumentVersion?.id || null;

      while (ancestorId) {
        const parentDocumentVersions = mainDocumentsMap[ancestorId];

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (parentDocumentVersions?.length) {
          const pendingParentDocumentVersion: any = parentDocumentVersions.find(
            (x) => x.status === StatusType.PENDING,
          );

          if (!pendingParentDocumentVersion) {
            const publishedParentDocumentVersion: any =
              parentDocumentVersions.find(
                (x) => x.status === StatusType.PUBLISHED,
              );

            ancestorId =
              publishedParentDocumentVersion?.parentChapterId || null;
          } else if (pendingParentDocumentVersion.shouldBeDeleted === true) {
            ancestorId = null;
            isAnyAncestorDeleted = true;
          } else {
            ancestorId = pendingParentDocumentVersion?.parentChapterId || null;
          }
        } else {
          ancestorId = null;
        }
      }
    }
  }

  const newDocument = sortDocuments({
    documents: [...filteredSubChapters, ...filteredDocs],
    documentsMap,
  });

  const allDocument =
    newDocument.length > 0
      ? newDocument.map((doc: any) => {
          if ('docItems' in doc) {
            const diffClassNameForDoc = getDiffClassName({
              isContentReader,
              defaultClassName: 'text-blue-db-16',
              status,
              diffFor: 'DOC',
              isDeleted: isAnyAncestorDeleted || doc?.shouldBeDeleted === true,
              doc,
            });

            return {
              orderFloat: doc.orderFloat ? doc.orderFloat : '100000',
              comp: (
                <Flex
                  padding="p-0.5"
                  key={doc.id && doc.version && doc.id + doc.version}
                >
                  <Link
                    to={`/products/documents/${documentId}/content/${
                      doc.id
                    }?status=${status}${
                      search.split('view=')[1] ? '&view=read' : ''
                    }`}
                  >
                    <Text className={diffClassNameForDoc} variant="medium">
                      {doc.title}
                    </Text>
                  </Link>
                </Flex>
              ),
            };
          }

          const diffClassNameForSubChapter = getDiffClassName({
            isContentReader,
            defaultClassName: 'text-blue-db-16',
            status,
            diffFor: 'CHAPTER',
            isDeleted: isAnyAncestorDeleted || doc?.shouldBeDeleted === true,
            chapter: doc,
          });
          return {
            orderFloat: doc.orderFloat ? doc.orderFloat : '100000',
            comp: (
              <Flex
                padding="p-0.5"
                key={doc.id && doc.version && doc.id + doc.version}
              >
                <Link
                  to={`/products/documents/${documentId}/subChapter/${
                    doc.id
                  }?status=${status}${
                    search.split('view=')[1] ? '&view=read' : ''
                  }`}
                >
                  <Text className={diffClassNameForSubChapter} variant="medium">
                    {doc.name}
                  </Text>
                </Link>
              </Flex>
            ),
          };
        })
      : [];

  const diffClassNameForChapter = getDiffClassName({
    isContentReader,
    defaultClassName:
      'font-microsoft-docs text-gray-microsoft-docs font-semibold',
    status,
    diffFor: 'CHAPTER',
    isDeleted: isAnyAncestorDeleted,
    chapter,
  });

  const returnElements = allDocument.sort(
    (a: any, b: any) =>
      Number.parseFloat(a?.orderFloat ? a?.orderFloat : '') -
      Number.parseFloat(b?.orderFloat ? b?.orderFloat : ''),
  );

  const finalReturnElement = returnElements.map(
    (element: any) => element?.comp,
  );

  return { diffClassNameForChapter, finalReturnElement };
};

export default constructFinalSubChapterElement;
